import React from "react";
import { useTable, useSortBy } from "react-table";
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Flex,
  IconButton,
  ButtonGroup,
} from "@chakra-ui/react";

import { ChevronLeftIcon } from "assets/icons/ChevronLeftIcon";
import { ChevronRightIcon } from "assets/icons/ChevronRightIcon";
import { ArrowUpIcon } from "assets/icons/ArrowUpIcon";
import { ArrowDownIcon } from "assets/icons/ArrowDownIcon";

import OutsideClickHandler from "react-outside-click-handler";

const SimpleTable = (props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: props.columns, data: props.data }, useSortBy);

  return (
    <Box
      backgroundColor="white"
      borderRadius="1rem"
      py="3rem"
      px="2rem"
      overflow="auto"
    >
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  key={column.id}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Box display="flex" alignItems="center">
                    {column.render("Header")}

                    <Box as="span" ml="1rem">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownIcon />
                        ) : (
                          <ArrowUpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <Tr
                  {...row.getRowProps()}
                  cursor={
                    props.onClick != null || props.onRowClick != null
                      ? "pointer"
                      : "initial"
                  }
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  onClick={() =>
                    props.onRowClick ? props.onRowClick(row.original) : null
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        key={cell.column.id}
                        onClick={() =>
                          cell.column.id === "image_url" &&
                          props.onClick?.(row.original)
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>

                {row.original.id && row.original.id === props.selectedRow ? (
                  <Tr>
                    <Td colSpan={row.cells.length}>
                      <OutsideClickHandler
                        onOutsideClick={props?.handleSelection}
                      >
                        {props?.child}
                      </OutsideClickHandler>
                    </Td>
                  </Tr>
                ) : null}
              </React.Fragment>
            );
          })}

          {rows?.length == 0 && (
            <Tr>
              <Td colSpan={props.columns?.length} textAlign={"center"}>
                <Text>No Results</Text>
              </Td>
            </Tr>
          )}

          {!props.hidePagination && (
            <Tr>
              {[...new Array(props.columns.length - 1)].map((_, index) => (
                <Td key={`row-${index}`} borderColor="transparent" />
              ))}

              <Td borderColor="transparent">
                <ButtonGroup spacing="3">
                  <IconButton
                    colorScheme="gray"
                    aria-label="Previous"
                    icon={<ChevronLeftIcon />}
                    onClick={props.onBack}
                  />

                  <IconButton
                    colorScheme="gray"
                    aria-label="Next"
                    icon={<ChevronRightIcon />}
                    onClick={props.onNext}
                  />
                </ButtonGroup>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export { SimpleTable };
