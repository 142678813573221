import { SimpleGrid, Box, Flex, Select, Text } from "@chakra-ui/react";
import LotsGoogleMaps from "./LotsGoogleMaps";

export default function Lots({ lot, zones }) {
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb="2rem">
        <Text fontSize="3xl" color="gray.900" fontWeight="600">
          {lot?.lot_name}
        </Text>
      </Flex>

      <LotsGoogleMaps lot={lot} zones={zones} />
    </Box>
  );
}
