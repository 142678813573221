import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

import { CompletedVehiclesFilterForm } from "../../Forms/CompletedVehiclesFilterForm";

const CompletedVehiclesFilter = (props) => {
  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      // size={"md"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Filter</DrawerHeader>

        <DrawerBody height="100%">
          <CompletedVehiclesFilterForm
            defaultValues={props.defaultValues}
            onSubmit={props.onSubmit}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export { CompletedVehiclesFilter };
