const RANGE_COEFFICIENT = {
  "02": {
    label: "alaska",
    coefficient_summer: 0.1,
    coefficient_winter: 0.9,
  },
  15: {
    label: "hawaii",
    coefficient_summer: 0.05,
    coefficient_winter: 0,
  },
  "06": {
    label: "ca",
    coefficient_summer: 0,
    coefficient_winter: 0.15,
  },
  "08": {
    label: "co",
    coefficient_summer: 0,
    coefficient_winter: 0.775,
  },
  53: {
    label: "wa",
    coefficient_summer: 0,
    coefficient_winter: 0.5,
  },
  48: {
    label: "tx",
    coefficient_summer: 0.1,
    coefficient_winter: 0.4,
  },
  12: {
    label: "fl",
    coefficient_summer: 0.075,
    coefficient_winter: 0.1,
  },
  17: {
    label: "il",
    coefficient_summer: 0,
    coefficient_winter: 1,
  },
  51: {
    label: "va",
    coefficient_summer: 0.02,
    coefficient_winter: 0.725,
  },
  36: {
    label: "ny",
    coefficient_summer: 0,
    coefficient_winter: 0.8,
  },
  23: {
    label: "mi",
    coefficient_summer: 0,
    coefficient_winter: 1.2,
  },
  //   alaska_coefficient_summer: 0.1,
  //   alaska_coefficient_winter: 0.9,
  //   hawaii_coefficient_summer: 0.05,
  //   hawaii_coefficient_winter: 0,
  //   ca_coefficient_summer: 0,
  //   ca_coefficient_winter: 0.15,
  //   co_coefficient_summer: 0,
  //   co_coefficient_winter: 0.775,
  //   wa_coefficient_summer: 0,
  //   wa_coefficient_winter: 0.5,
  //   tx_coefficient_summer: 0.1,
  //   tx_coefficient_winter: 0.4,
  //   fl_coefficient_summer: 0.075,
  //   fl_coefficient_winter: 0.1,
  //   il_coefficient_summer: 0,
  //   il_coefficient_winter: 1,
  //   va_coefficient_summer: 0.02,
  //   va_coefficient_winter: 0.725,
  //   ny_coefficient_summer: 0,
  //   ny_coefficient_winter: 0.8,
  //   mi_coefficient_summer: 0,
  //   mi_coefficient_winter: 1.2,
};

module.exports = RANGE_COEFFICIENT;
