import React from "react";
import Heatmap from "./mygraphs/heatMap";
import teslaImg from "assets/new_report/TESLA_model3.svg";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";

function CarHeatMap({ vehicleData, tooltipDescription }) {
  return (
    <div className="car_heatmap_mobile">
      <div className="heatmap_heading">
        <h2 style={{ display: "flex", justifyContent: "center" }}>
          CELL VOLTAGE TEST{" "}
          <Tooltip label={tooltipDescription}>
            <Icon
              icon={infoFill}
              style={{ color: "black", width: 16, height: 16 }}
            />
          </Tooltip>
        </h2>
      </div>
      <div className="heatmap_container">
        <div className="car_image">
          <embed
            src={
              vehicleData?.picture
                ? process.env.REACT_APP_EPA_S3_BUCKET + vehicleData?.picture
                : teslaImg
            }
            alt=""
          />
        </div>
        <div className="map_image">
          <Heatmap vehicleData={vehicleData} />
        </div>
        <div className="pickup_colors">
          <div className="green_first">
            <div className="green_color"></div>
            <div className="good">
              <h3>Balanced</h3>
            </div>
          </div>

          <div className="green_first">
            <div className="dark_green_color"></div>
            <div className="good">
              <h3>Replaced</h3>
            </div>
          </div>

          <div className="fair_scnd">
            <div className="yellow_color"></div>
            <div className="fair">
              <h3>Fair</h3>
            </div>
          </div>

          <div className="bad_last">
            <div className="red_color"></div>
            <div className="bad">
              <h3>Failing</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarHeatMap;
