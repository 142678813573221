import React from "react";
import Chart from "react-apexcharts";
import graphImage from "assets/new_report/imageGraph.png";
import borderMain from "assets/new_report/border.png";
import shadow from "assets/new_report/shadow.png";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
// import "./graph.scss";

function StateOfHealth({
  width,
  value,
  rangeText,
  heading,
  tooltipDescription,
}) {
  let options = {
    chart: {
      type: "radialBar",
    },
    colors: ["#52a986"],
    strokeWidth: 40,
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "80%",
          background: "#fff",
        },
        track: {
          background: "#fdca02",
          startAngle: -135,
          endAngle: 135,
        },
        startAngle: -135,
        endAngle: 135,

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: "30px",
            show: false,
            fontWeight: "700",
            top: 20,
          },
        },
      },
    },

    fill: {
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "butt",
    },
    labels: ["Progress"],
  };

  const options2 = {
    chart: {
      type: "radialBar",
    },
    colors: ["#e6e7e8"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "75%",
          background: "#fff",
        },
        track: {
          background: "#e6e7e8",
          strokeWidth: 40,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        gradientToColors: ["#ffffff"],
        stops: [0, 90],
      },
    },
    stroke: {
      lineCap: "butt",
    },
    labels: ["Progress"],
  };

  return (
    <div className="rangeGraph" style={{ width: width }}>
      <div style={{ display: "flex" }}>
        <h3>{heading} </h3>
        <Tooltip label={tooltipDescription}>
          <Icon
            icon={infoFill}
            style={{ color: "black", width: 16, height: 16 }}
          />
        </Tooltip>
      </div>
      <img className="borderImage" src={borderMain} alt="border" />

      <div className="gauge" style={{ width: width }}>
        <div className="chartAbsolute">
          <img
            className="graph_img"
            style={{ width: width / 2.1, height: width / 2.5 }}
            src={shadow}
          />
        </div>
        <div
          className="chartMain"
          style={{
            width: width,
          }}
        >
          <Chart
            options={options}
            series={[value]}
            type="radialBar"
            width={width}
          />
        </div>
        <div
          className="textOut"
          style={{
            top: width / 3 - 10,
            width: width / 2 - 50,
          }}
        >
          <h2>
            {value}
            {rangeText}
          </h2>
          <img alt="graph" src={graphImage} />
        </div>
      </div>
    </div>
  );
}

export default StateOfHealth;
