import React, { useMemo } from "react";
import { Box, Skeleton, Text } from "@chakra-ui/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

import { getYellowToGreenColor } from "utils";

import { VehicleRatingTooltip } from "components/Tooltips/VehicleRatingTooltip";

const VehicleRatigsChart = (props) => {
  const data = useMemo(() => {
    if (props.data == null) {
      return null;
    }

    return (props.data ?? [])
      .sort((a, b) => b.score - a.score)
      .map((value) => ({
        x: Number(value.score).toFixed(2),
        y: Number(value.count),
      }));
  }, [props.data]);

  const totalVehicles = useMemo(() => {
    return (props.data ?? []).reduce((acc, cv) => {
      return acc + Number(cv.count);
    }, 0);
  }, [props.data]);

  const getVehiclesPercentage = (value) => {
    const percentage = Math.floor((Number(value) * 100) / totalVehicles);
    return `${percentage}%`;
  };

  const xTickFormatter = (value) => {
    if (value == "auto") {
      return `0%`;
    }

    return `${value * 100}%`;
  };

  return (
    <Box
      backgroundColor="white"
      py="3rem"
      px="2rem"
      textAlign="center"
      borderRadius="1rem"
    >
      <Text color="gray.900" fontWeight="bold" fontSize="1.4rem">
        All Vehicle Ratings
      </Text>
      <Box mt="2rem" height="30rem" {...props.containerProps}>
        {data == null ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={400}
              height={400}
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: -10,
                bottom: -10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="x" tickFormatter={xTickFormatter} interval={0} />
              <YAxis tickFormatter={getVehiclesPercentage} />
              <Tooltip content={<VehicleRatingTooltip />} />
              <Bar dataKey="y">
                {data.map((data) => (
                  <Cell
                    key={data.x}
                    fill={getYellowToGreenColor(Number(data.x) * 100)}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
};

VehicleRatigsChart.defaultProps = {
  containerProps: {},
};

export { VehicleRatigsChart };
