/**
 * Returns a color from yellow to green based on the value
 * @param {Number} percent
 * @returns
 */
export const getYellowToGreenColor = (percent) => {
  if (percent >= 90) {
    return "#00af4f";
  }
  if (percent < 90 && percent >= 80) {
    return "#91d04e";
  }
  if (percent < 80 && percent >= 70) {
    return "#c8e6a6";
  }
  if (percent < 70 && percent >= 60) {
    return "#fedf7c";
  }
  if (percent < 60 && percent >= 50) {
    return "#fe7e7d";
  }

  return "#f5453f";
};
