import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const ForgotPasswordLink = () => {
  return (
    <Link
      as={RouterLink}
      to="/forgot-password"
      color="red.600"
      size="sm"
      fontSize="1.2rem"
    >
      Forgot your password?
    </Link>
  );
};

export { ForgotPasswordLink };
