import React, { useState } from "react";
import {
  Box,
  Text,
  Select,
  Switch,
  FormControl,
  FormLabel,
  Skeleton,
} from "@chakra-ui/react";

import mapIcon from "../../assets/icons/map.png";
import engineIcon from "../../assets/icons/engine.png";

import { SimpleTable } from "../../components/Tables/SimpleTable";

import { useUserContext } from "context";
import _ from "lodash";
import moment from "moment-timezone";

const InventoryTableContainer = (props) => {
  const { user } = useUserContext();
  let [zone, setZone] = useState();
  let handleZoneSelection = (row) => {
    // console.log(row);
    if (zone?.id === row.id || !row) {
      setZone();
    } else {
      setZone(row);
    }
  };
  // const columns = useMemo(() => {
  const cells = [
    {
      Header: "IEEE",
      accessor: "ieee",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value?.slice(-6)}</Text>;
      },
    },
    {
      Header: "VIN",
      accessor: "vin",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "Year",
      accessor: "year",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "Make",
      accessor: "make",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "State of Charge (EV)",
      accessor: "soc",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value ? `${Math.trunc(value)}%` : ""}</Text>;
      },
    },
    {
      Header: "Charging Status (EV)",
      accessor: "charging_status",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value == 1 ? "Charging" : ""}</Text>;
      },
    },
    {
      Header: "Battery",
      accessor: "battery",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value?.toFixed(2)}V</Text>;
      },
    },
    {
      Header: "MIL",
      accessor: "mil",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return (
          <Text>
            {value >= 1 && (
              <img src={engineIcon} width={32} height={32} alt="Engine" />
            )}
          </Text>
        );
      },
    },
    {
      Header: "DTC",
      accessor: "dtc",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        console.log("dtc: ", value);
        return (
          <Text>
            {value?.map((item, index) => (
              <React.Fragment key={index}>
                <span>
                  {item}
                  {index < value.length - 1 && ","}
                </span>{" "}
                <br />
              </React.Fragment>
            ))}
          </Text>
        );
      },
    },
    {
      Header: "Zone",
      accessor: "zone_name",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "Bay",
      accessor: "bay_name",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "Duration",
      accessor: "duration",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        let duration = "";
        let { bay_start_time, zone_start_time } = row.original;

        function secondsToDhms(seconds) {
          seconds = Number(seconds);
          var d = Math.floor(seconds / (3600 * 24));
          var h = Math.floor((seconds % (3600 * 24)) / 3600);
          var m = Math.floor((seconds % 3600) / 60);
          var s = Math.floor(seconds % 60);

          var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
          var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
          var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
          // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
          return (
            <>
              {dDisplay && (
                <>
                  {dDisplay}
                  <br />
                </>
              )}{" "}
              {hDisplay && (
                <>
                  {hDisplay}
                  <br />
                </>
              )}{" "}
              {mDisplay}
            </>
          ); // + sDisplay;
        }

        if (bay_start_time) {
          let sec = moment().diff(moment(bay_start_time), "seconds");
          duration = secondsToDhms(sec);
        } else {
          let sec = moment().diff(moment(zone_start_time), "seconds");
          duration = secondsToDhms(sec);
        }

        return <Text>{duration}</Text>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value}</Text>;
      },
    },
    {
      Header: "Last Time in",
      accessor: "last_time_in",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return (
          <Box>
            <Text>
              {moment
                .unix(value)
                .utc()
                .add(parseInt(row?.original?.tz), "hours")
                .format("MM-DD-YYYY")}
            </Text>
            <Text>
              {moment
                .unix(value)
                .utc()
                .add(parseInt(row?.original?.tz), "hours")
                .format("h:mm A")}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: "Location",
      accessor: "gps",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        let { coordinates } = value ?? {};

        return (
          <>
            {coordinates && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.google.com/maps/dir/?api=1&destination=${coordinates?.[1]},${coordinates?.[0]}&travelmode=walking`}
              >
                <img src={mapIcon} width={16} height={16} />
              </a>
            )}
          </>
        );
      },
    },
  ];

  let columns = [...cells];

  return (
    <Box>
      {props.inventoryList == null ? (
        <Skeleton width="100%" height="30rem" borderRadius="1rem" />
      ) : (
        <SimpleTable
          columns={columns}
          data={props?.inventoryList?.data ?? []}
          // onNext={props.onNext}
          // onBack={props.onBack}
          // onRowClick={handleRowClick}
          hidePagination
          selectedRow={zone?.id}
          // handleSelection={handleZoneSelection}
        />
      )}
    </Box>
  );
};

export { InventoryTableContainer };
