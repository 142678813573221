import { extendTheme } from "@chakra-ui/react";

import components from "./themeComponents";
import sizes from "./themeSizes";
import fontSizes from "./themeFontSizes";

const theme = extendTheme({
  styles: {
    global: {
      html: {
        fontSize: "62.5%",
        // fontSize: "100%",
      },
      body: {
        fontSize: "1.6rem",
        backgroundColor: "#f2f6fd",
      },
      "html, body, #root": {
        backgroundColor: "#f2f6fd",
        height: "100vh",
        width: "100vw",
      },
    },
  },
  components,
  sizes,
  fontSizes,
});

export { theme };
