import React from "react";
import { SimpleGrid, Flex, Box, Hide } from "@chakra-ui/react";
import Lottie from "lottie-react";

const AuthenticationLayout = (props) => {
  return (
    <Box height="100vh" width="100vw" backgroundColor="white">
      <SimpleGrid
        height="100%"
        width="100%"
        maxWidth="6xl"
        margin="0 auto"
        columns={[1, 1, 2]}
      >
        <Hide below="750px">
          <Flex
            flex="1"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* <Lottie animationData={props.animationData} /> */}
            <img src={props.gif} alt="annimation" />
          </Flex>
        </Hide>

        <Flex
          flex="1"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="2rem"
        >
          <Box width="100%" maxWidth={["90%", "90%", "45rem"]}>
            {props.children}
          </Box>
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

AuthenticationLayout.defaultProps = {
  title: "",
};

export { AuthenticationLayout };
