import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

const SelectController = ({
  control,
  onChange,
  name,
  label,
  options,
  placeholder,
  ...props
}) => {
  return (
    <FormControl isInvalid={Boolean(props.error)} {...props.FormControl}>
      {label != null && (
        <FormLabel fontSize="1.4rem" color="gray.600">
          {label}
        </FormLabel>
      )}

      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            // px="1.4rem"
            // py="1.7rem"
            placeholder={placeholder}
            size="lg"
            fontSize="1.4rem"
            borderRadius="0.4rem"
            backgroundColor="gray.50"
            borderColor="gray.50"
            {...props}
            {...field}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            style={{ padding: "0px 14px" }}
            height={"38px"}
          >
            {options}
          </Select>
        )}
      />
      <FormErrorMessage>{props.error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export { SelectController };
