import { Box, Paper, Typography } from "@mui/material";
import NoDataFoundImage from "assets/illustrations/no-data-found.png";

export default function NotDataFound({ ...other }) {
  return (
    <Paper {...other}>
      <Box justifyContent="center" m={5}>
        <img
          src={NoDataFoundImage}
          alt="No Data"
          width={300}
          height={200}
          style={{ display: "block", margin: "auto" }}
        />
      </Box>
    </Paper>
  );
}
