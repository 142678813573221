import { useState, useRef, useEffect } from "react";
import useSWR, { useSWRConfig } from "swr";
import pickBy from "lodash/pickBy";
import { useToast } from "@chakra-ui/react";

import { ApiService } from "services";

const LIMIT = 10;

const getUserLotsQueryBuilder = (args) => {
  const { endpoints } = args;

  return `${endpoints.getUserLots}`;
};

const getZonesQueryBuilder = (args) => {
  const { endpoints, lot_id } = args;
  let queryString = `?lot_id=${lot_id}`;

  if (!lot_id) return null;

  return `${endpoints.getZones}${queryString}`;
};

const getZonesStatsQueryBuilder = (args) => {
  const { endpoints, lot_id } = args;

  if (!lot_id) return null;
  if (!endpoints.getZonesStats) return null;

  let queryString = `?lot_id=${lot_id}`;

  return `${endpoints.getZonesStats}${queryString}`;
};

const getInventoryListQueryBuilder = (filters, page, args) => {
  const { endpoints } = args;
  let queryString = `?page=${page}&limit=${LIMIT}`;

  if (!endpoints.getInventoryList) return null;

  let searchFilters = filters;
  if (searchFilters != null) {
    Object.keys(searchFilters).forEach((key) => {
      if (searchFilters[key] === "") {
        delete searchFilters[key];
      }
    });
    const searchParams = new URLSearchParams(searchFilters);
    const queryStringParams = new URLSearchParams(searchParams).toString();
    queryString += queryStringParams.length > 0 ? `&${queryStringParams}` : "";
  }

  return `${endpoints.getInventoryList}${queryString}`;
};

const getDevicesQueryBuilder = (args) => {
  const { endpoints, lot_id } = args;
  let queryString = `?lot_id=${lot_id}`;

  if (!lot_id) return null;

  return `${endpoints.getDevices}${queryString}`;
};

const useInventory = (endpoints = {}) => {
  // const { mutate } = useSWRConfig();
  const [isLoading, setLoading] = useState(false);
  // const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);

  const [lot_id, setLotId] = useState("");
  const { data: lots, mutate: mutateLots } = useSWR(() =>
    getUserLotsQueryBuilder({ endpoints })
  );
  const { data: zones, mutate: mutateZones } = useSWR(() =>
    getZonesQueryBuilder({ endpoints, lot_id })
  );
  const { data: zonesStats, mutate: mutateZonesStats } = useSWR(() =>
    getZonesStatsQueryBuilder({ endpoints, lot_id })
  );

  // const { data: inventoryList, mutate: mutateInventoryList } = useSWR();
  const [inventoryList, setInventoryList] = useState();

  const { data: devices, mutate: mutateDevices } = useSWR(
    () => getDevicesQueryBuilder({ endpoints, lot_id }),
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    }
  );

  const toast = useToast();

  const lotsRef = useRef(lots);
  const zonesRef = useRef(zones);

  useEffect(() => {
    lotsRef.current = lots;
  }, [lots]);
  useEffect(() => {
    zonesRef.current = zones;
  }, [zones]);
  useEffect(() => {
    if (lot_id) {
      mutateZones(getZonesQueryBuilder({ endpoints, lot_id }));
      mutateDevices(getDevicesQueryBuilder({ endpoints, lot_id }));
    }
  }, [lot_id]);

  const handleCreateZone = async (payload) => {
    setLoading(true);
    ApiService.post(endpoints.createZone, {
      ...payload,
    })
      .then((response) => {
        toast({
          title: "You have successfuly created the zone",
          status: "success",
        });

        let zonesData = zones?.data ?? [];
        zonesData.push(response?.data?.data?.zone);

        mutateZones(zonesData);
        setLoading(false);
      })
      .catch(() => {
        toast({
          title: "Something went wrong while updating the zone",
          status: "error",
        });
        setLoading(false);
      });
  };

  const handleUpdateZone = async (id, payload) => {
    if (id == null) {
      return;
    }
    setLoading(true);
    ApiService.put(endpoints.updateZone, {
      id,
      ...payload,
    })
      .then((response) => {
        console.log("zones: ", zones);
        const updatedZone = [];
        zones?.zones?.map((x) => {
          if (x.id == id) {
            updatedZone.push({
              ...(response?.data?.zone ?? {}),
            });
          } else {
            updatedZone.push(x);
          }
        });
        // console.log("zone: ", response?.data);
        console.log("updatedZone: ", { zones: updatedZone });

        mutateZones({ zones: updatedZone }, false);
        setLoading(false);
        toast({
          title: "You have successfuly updated the zone",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        const updateZone = zones?.data?.map((x) => x);
        mutateZones(updateZone);
        toast({
          title:
            error.response.data === "Validation error"
              ? "Validation error. Unique value required."
              : "Something went wrong while updating the device",
          status: "error",
        });
      });
  };

  const handleUpdateZoneOrder = async (id1, payload1, id2, payload2) => {
    if (id1 == null || id2 === null) {
      return;
    }
    setLoading(true);

    const promise1 = new Promise((resolve, reject) => {
      ApiService.put(endpoints.updateZone, {
        id: id1,
        ...payload1,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    const promise2 = new Promise((resolve, reject) => {
      ApiService.put(endpoints.updateZone, {
        id: id2,
        ...payload2,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject();
        });
    });
    const promises = [promise1, promise2];
    Promise.allSettled(promises).then((results) => {
      console.log("Promise Results: ", results);
      let sum = 0;
      results.forEach((result) => {
        if (result.status === "fulfilled") {
          sum++;
        }
      });
      if (sum === results.length) {
        toast({
          title: "You have successfuly updated the zone",
          status: "success",
        });
        mutateZones();
      } else {
        toast({
          title: "Something went wrong while updating the device",
          status: "error",
        });
      }
      setLoading(false);
    });
  };

  const handleDeleteZone = async (id) => {
    if (id == null) {
      return;
    }

    ApiService.post(endpoints.deleteZone, {
      id,
    })
      .then((response) => {
        toast({
          title: "You have successfuly deleted the zone",
          status: "success",
        });

        const updatedZone = zones?.data?.filter((x) => x.id !== id);

        mutateZones(updatedZone);
      })
      .catch(() => {
        toast({
          title: "Something went wrong while updating the zone",
          status: "error",
        });
      });
  };

  const handleUpdateDevice = async (ieee, payload) => {
    if (ieee == null) {
      return;
    }

    console.log("devices: ", devices);
    ApiService.put(endpoints.updateDevice, {
      ieee,
      ...payload,
    })
      .then((response) => {
        toast({
          title: "You have successfuly updated the device",
          status: "success",
        });

        let updateDevice = [...devices?.devices];
        // updateDevice?.forEach((x) => {
        //   if (x.ieee === ieee) {
        //     console.log("Found Device", {

        //       ...(response?.data?.device ?? {}),
        //     });
        //     return {
        //       ...(response?.data?.device ?? {}),
        //     };
        //   }

        //   return x;
        // });
        updateDevice.forEach(function (part, index) {
          if (this[index].ieee === ieee) {
            this[index] = {
              ...(response?.data?.device ?? {}),
            };
          }
        }, updateDevice);

        console.log("response?.data?.device: ", response?.data?.device);

        console.log("updateDevice: ", updateDevice);

        mutateDevices({ devices: updateDevice }, false);
      })
      .catch((error) => {
        toast({
          title:
            error.response.data === "Validation error"
              ? "Validation error. Unique value required."
              : "Something went wrong while updating the device",
          status: "error",
        });
      });
  };

  const handleSearchInventory = async (lot_id, filters) => {
    if (lot_id == null) {
      return;
    }
    setLoading(true);
    ApiService.get(
      getInventoryListQueryBuilder(
        {
          lot_id,
          ...filters,
        },
        1,
        { endpoints }
      )
    )
      .then((response) => {
        // console.log("response?.data ===> ", response);
        // mutateInventoryList(response, false);
        setInventoryList(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: "Something went wrong",
          status: "error",
        });
      });
  };

  return {
    lots,
    mutateLots,
    lotsRef,
    zones,
    mutateZones,
    zonesRef,
    handleCreateZone,
    handleUpdateZone,
    handleDeleteZone,
    handleUpdateZoneOrder,
    isLoading,
    // filters,
    lot_id,
    setLotId,
    devices,
    handleUpdateDevice,
    // inventory
    zonesStats,
    inventoryList,
    handleSearchInventory,
  };
};

export { useInventory };
