import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";

import { useUserContext } from "../../context";

import { LoadingLayout } from "../../components/Layouts/LoadingLayout";

const Callback = () => {
  const { verifyAccount } = useUserContext();
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    const { code, username } = getQueryParams();

    if (code == null || username == null) {
      history.push("/login");
      toast({ title: "The url is invalid", status: "error" });
      return;
    }

    verifyAccount({ code, username });
  }, [location]);

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const code = query.get("code");
    const username = query.get("username");

    return { code, username };
  };

  return <LoadingLayout />;
};

export { Callback };
