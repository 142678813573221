import React from "react";
import { Flex, Container } from "@chakra-ui/react";
// import { Helmet } from "react-helmet";

import { Navbar } from "components/Navbar";
import { Footer } from "components/Footer";

const PageLayout = (props) => {
  return (
    <>
      {/* <Helmet>
        <title>vsNew - {props.title}</title>
      </Helmet> */}

      <Flex flexDirection="column" width="100vw" height="100vh">
        <Navbar />

        <Container maxW="150rem" paddingTop="5rem" paddingBottom="5rem">
          {props.children}
        </Container>

        <Container maxW="150rem">
          <Footer />
        </Container>
      </Flex>
    </>
  );
};

export { PageLayout };
