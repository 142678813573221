import React, { memo, useEffect, useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import vsNewImg from "assets/new_report/vsNewImg.png";
import Consumer_EV_header from "assets/new_report_screenshot/Consumer_EV_header.png";
import Consumer_hybrid_header from "assets/new_report_screenshot/Consumer_hybrid_header.png";
import navRedSvg from "assets/new_report/navbar-right-red.svg";
import navGreenSvg from "assets/new_report/navbar-right-green.svg";
import "components/new_report_screenshot/mygraphs/graph.scss";

function EvheaderMemorized({
  vehicleTypeTitle,
  vehicleData,
  tooltipDescription,
}) {
  const [bgImage, setBgImage] = useState(navGreenSvg);
  const [badBattery, setBadBattery] = useState(false);
  let { brick_status } = vehicleData;
  useEffect(() => {
    if (brick_status) {
      brick_status?.forEach((item) => {
        if (item >= 8 && item <= 100000) {
          setBgImage(navRedSvg);
          setBadBattery(true);
        }
      });
    }
  }, [brick_status]);

  return (
    <>
      <Grid>
        <a href="https://www.vsnew.com/" style={{ display: "flex" }}>
          <img
            className="nav_logo"
            src={
              vehicleData?.vehicle_type == "hybrid"
                ? Consumer_hybrid_header
                : Consumer_EV_header
            }
            alt="logo"
            style={{ alignSelf: "center" }}
          />
        </a>
      </Grid>
    </>
  );
}

const Evheader = memo(EvheaderMemorized);
export default Evheader;
