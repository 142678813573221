import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

import { useUserContext } from "../context";
import { LoadingLayout } from "../components/Layouts/LoadingLayout";
import { useEffect } from "react";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isLoading } = useUserContext();
  const location = useLocation();
  const history = useHistory();

  const isSubscriptionFreeClient = (partner_code) => {
    if (
      partner_code?.includes("EVU") ||
      partner_code?.includes("CASTROL") ||
      partner_code?.includes("EVCHECKUP") ||
      partner_code?.includes("LECTRIUM")
    )
      return true;
    else false;
  };

  useEffect(() => {
    if (user == null || isLoading) {
      return;
    }

    const {
      ice_access,
      ev_access,
      inventory_access,
      admin_access,
      billing_access,
    } = user;
    const { pathname } = location;

    // if agreement not signed
    if (!user?.signature && pathname !== "/sign-agreement") {
      history.push("/sign-agreement");
      return;
    }
    // if agreement already signed
    if (user?.signature && pathname == "/sign-agreement") {
      history.push("/");
      return;
    }

    // if user not a partner and not subscribed
    if (
      pathname !== "/payment" &&
      user?.signature &&
      ((isSubscriptionFreeClient(user?.partner_code) &&
        !user?.stripe_payment_method) ||
        (!isSubscriptionFreeClient(user?.partner_code) &&
          !user?.subscription_id))
    ) {
      history.push("/payment");
      return;
    }

    // if already has subscription
    if (
      ((isSubscriptionFreeClient(user?.partner_code) &&
        user?.stripe_payment_method) ||
        (!isSubscriptionFreeClient(user?.partner_code) &&
          user?.subscription_id)) &&
      pathname == "/payment"
    ) {
      history.push("/");
      return;
    }

    if (pathname === "/" && ice_access === 0) {
      history.push("/battery");
    }

    if (pathname === "/battery" && ev_access === 0) {
      history.push("/inventory");
    }

    if (pathname === "/inventory" && inventory_access === 0) {
      history.push("/admin");
    }

    if (pathname === "/admin" && admin_access === 0) {
      history.push("/billing");
    }

    if (pathname === "/billing" && billing_access === 0) {
      history.push("/settings");
    }
  }, [user, isLoading, location]);

  if (isLoading) {
    return <LoadingLayout />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user != null && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: `?redirection=${encodeURI(window.location.pathname)}`,
            }}
          />
        )
      }
    />
  );
};

export { PrivateRoute };
