import React, { useMemo, useState } from "react";
import {
  Image,
  ButtonGroup,
  IconButton,
  Button,
  Box,
  Flex,
  Text,
  Select,
  Switch,
  FormControl,
  FormLabel,
  Skeleton,
  Editable,
  EditableInput,
  EditablePreview,
} from "@chakra-ui/react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
  AddIcon,
} from "@chakra-ui/icons";
import mapIcon from "../assets/icons/map.png";

import GoogleMapComponent from "./ZoneGoogleMaps";
import LotsGoogleMaps from "pages/Admin/Lots/LotsGoogleMaps";
import { SimpleTable } from "../components/Tables/SimpleTable";

import { PrinterIcon } from "../assets/icons/PrinterIcon";
import { RefreshIcon } from "../assets/icons/RefreshIcon";
import { useUserContext } from "context";
import _ from "lodash";

const ZonesTableContainer = (props) => {
  const { user } = useUserContext();
  let [zone, setZone] = useState();
  let handleZoneSelection = (row) => {
    // console.log(row);
    if (zone?.id === row.id || !row) {
      setZone();
    } else {
      setZone(row);
    }
  };
  // const columns = useMemo(() => {
  const cells = [
    {
      Header: "Name",
      accessor: "zone_name",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return (
          // <Text
          //   contentEditable="true"
          //   onBlur={(e) =>
          //     props.onUpdateZone(row.original?.id, {
          //       zone_name: e.target.outerText,
          //     })
          //   }
          //   defaultValue={value}
          // />
          //   {value}
          // </Text>
          <Editable
            submitOnBlur
            onSubmit={(e) =>
              props.onUpdateZone(row.original?.id, {
                zone_name: e,
              })
            }
            defaultValue={value}
            // value={value}
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        );
      },
    },
    {
      Header: "Type",
      accessor: "zone_type",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        let handleChange = (e) => {
          let path = null;
          if (parseInt(e.target.value) === 1 && props?.lot?.gps) {
            let { coordinates } = props?.lot?.gps;
            path = {
              type: "Polygon",
              coordinates: [
                [
                  [coordinates[0], coordinates[1]],
                  [coordinates[0], coordinates[1]],
                  [coordinates[0], coordinates[1]],
                ],
              ],
            };
          } else if (parseInt(e.target.value) === 2 && props?.lot?.gps) {
            let { coordinates } = props?.lot?.gps;
            path = {
              type: "Point",
              coordinates: [coordinates[0], coordinates[1]],
            };
          }

          props.onUpdateZone(row.original?.id, {
            zone_type: parseInt(e.target.value),
            outdoor_polygon: parseInt(e.target.value) === 1 ? path : null,
            gps: parseInt(e.target.value) === 2 ? path : null,
          });
        };

        return (
          <Select defaultValue={value.toString()} onChange={handleChange}>
            <option value="1">Outdoor</option>
            <option value="2">Indoor</option>
          </Select>
        );
      },
    },
    {
      Header: "Stock Out Zone",
      accessor: "stock_out_zone",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        let defaultValue = value === 1 ? true : false;

        let handleSwitch = () => {
          defaultValue = !defaultValue;
          props.onUpdateZone(row.original?.id, {
            stock_out_zone: value === 1 ? 2 : 1,
          });
        };
        return (
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              No
            </FormLabel>
            <Switch
              id="email-alerts"
              isChecked={defaultValue}
              onChange={handleSwitch}
            />
            <FormLabel htmlFor="email-alerts" mb="0">
              Yes
            </FormLabel>
          </FormControl>
        );
      },
    },
    {
      Header: "Order",
      accessor: "zone_order",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        // console.log("row: ", row);

        let handleMoveUp = () => {
          let currentZone = row.original;

          let zoneAboveIndex =
            _.findIndex(props?.zones, function (o) {
              return o.id == row.original.id;
            }) - 1;
          let zoneAbove = props?.zones?.[zoneAboveIndex];

          props.onUpdateZoneOrder(
            currentZone?.id,
            {
              zone_order: zoneAbove?.zone_order,
            },
            zoneAbove?.id,
            {
              zone_order: currentZone?.zone_order,
            }
          );
          // props.onUpdateZone(currentZone?.id, {
          //   zone_order: zoneAbove?.zone_order,
          // });
          // props.onUpdateZone(zoneAbove?.id, {
          //   zone_order: currentZone?.zone_order,
          // });
        };

        let handleMoveDown = () => {
          let currentZone = row.original;

          let zoneDownIndex =
            _.findIndex(props?.zones, function (o) {
              return o.id == row.original.id;
            }) + 1;
          let zoneDown = props?.zones?.[zoneDownIndex];

          props.onUpdateZoneOrder(
            currentZone?.id,
            {
              zone_order: zoneDown?.zone_order,
            },
            zoneDown?.id,
            {
              zone_order: currentZone?.zone_order,
            }
          );
          // props.onUpdateZone(currentZone?.id, {
          //   zone_order: zoneDown?.zone_order,
          // });
          // props.onUpdateZone(zoneDown?.id, {
          //   zone_order: currentZone?.zone_order,
          // });
        };

        return (
          <ButtonGroup spacing="1rem" display="flex">
            <IconButton
              variant="ghost"
              aria-label="Print"
              icon={<ArrowUpIcon />}
              disabled={row?.index === 0 || props?.inventoryIsLoading}
              onClick={handleMoveUp}
            />
            <IconButton
              variant="ghost"
              aria-label="Print"
              icon={<ArrowDownIcon />}
              disabled={
                row?.index === props?.zones?.length - 1 ||
                props?.inventoryIsLoading
              }
              onClick={handleMoveDown}
            />
          </ButtonGroup>
        );
      },
    },
    {
      Header: "Duration",
      accessor: "duration",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        let d = Math.floor(value / (3600 * 24));
        let h = Math.floor((value % (3600 * 24)) / 3600);
        let m = Math.floor((value % 3600) / 60);

        // console.log("value: ", value);
        // console.log("d: ", d);
        // console.log("h: ", h);
        // console.log("m: ", m);

        const handleChange = (e, type) => {
          let val = 0;

          if (type === "d") {
            d = e.target.value;
            val = h * 3600 + m * 60 + e.target.value * 86400;
          } else if (type === "h") {
            h = e.target.value;
            val = e.target.value * 3600 + m * 60 + d * 86400;
          } else if (type === "m") {
            m = e.target.value;
            val = h * 3600 + e.target.value * 60 + d * 86400;
          }
          // console.log("duration val: ", val);
          props.onUpdateZone(row.original?.id, {
            duration: parseInt(val),
          });
        };

        return (
          <Box display={"flex"}>
            <Select
              defaultValue={d?.toString()}
              onChange={(e) => handleChange(e, "d")}
              maxWidth="105px"
            >
              {[...Array(11)].map((item, index) => (
                <option value={index} key={index}>
                  {index} Day{index != 1 ? "s" : ""}
                </option>
              ))}
            </Select>
            <Select
              defaultValue={h?.toString()}
              onChange={(e) => handleChange(e, "h")}
              maxWidth="105px"
            >
              {[...Array(24)].map((item, index) => (
                <option value={index} key={index}>
                  {index} Hour{index != 1 ? "s" : ""}
                </option>
              ))}
            </Select>
            <Select
              defaultValue={m?.toString()}
              onChange={(e) => handleChange(e, "m")}
              maxWidth="105px"
            >
              {[...Array(60)].map((item, index) => (
                <option value={index} key={index}>
                  {index} Minute{index != 1 ? "s" : ""}
                </option>
              ))}
            </Select>
          </Box>
        );
      },
    },
  ];

  const actionsCell = {
    Header: "Actions",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ cell: { value, row } }) => {
      return (
        <ButtonGroup spacing="1rem" display="flex">
          <IconButton
            variant="ghost"
            icon={<img src={mapIcon} width={16} height={16} />}
            onClick={() => handleZoneSelection(row.original)}
          />
          <IconButton
            variant="ghost"
            icon={<DeleteIcon />}
            onClick={() => props.onDeleteZone(value)}
          />
        </ButtonGroup>
      );
    },
  };

  let columns = [...cells, actionsCell];
  // let [columns, setColumns] = useState([...cells, actionsCell]);
  // return [...cells, actionsCell];
  // }, [user]);

  const zoneCount = props.zones?.length ?? 0;

  return (
    // <TableContainerInventory
    //   title={`Zones - ${zoneCount}`}
    //   columns={columns}
    //   data={{ data: props.zones }}
    //   // onRowClick={props?.onRowClick}
    //   // defaultFilterValues={props.defaultFilterValues}
    //   // onFilter={props.onFilter}
    //   // onNext={props.onNext}
    //   // onBack={props.onBack}
    // />
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb="2rem">
        <Text fontSize="3xl" color="gray.900" fontWeight="600">
          {`Zones - ${zoneCount}`}
        </Text>
        <Button
          colorScheme="blue"
          aria-label="Print"
          leftIcon={<AddIcon />}
          isLoading={props?.inventoryIsLoading}
          onClick={() => {
            let { coordinates } = props?.lot?.gps;
            let path = {
              type: "Polygon",
              coordinates: [
                [
                  [coordinates[0], coordinates[1]],
                  [coordinates[0], coordinates[1]],
                  [coordinates[0], coordinates[1]],
                ],
              ],
            };
            let payload = {
              zone_name: `New Zone ${
                props.zones?.[props?.zones?.length - 1]?.zone_order ?? 0 + 1
              }`,
              zone_type: 1,
              outdoor_polygon: path ?? null,
              gps: null,
              stock_out_zone: 2,
              zone_order:
                props.zones?.[props?.zones?.length - 1]?.zone_order + 1,
              lot_id: props?.lot?.lot_id,
            };
            props.onCreateZone(payload);
          }}
        >
          Add Zone
        </Button>
      </Flex>

      {props.zones == null ? (
        <Skeleton width="100%" height="30rem" borderRadius="1rem" />
      ) : (
        <SimpleTable
          columns={columns}
          data={props.zones}
          // onNext={props.onNext}
          // onBack={props.onBack}
          // onRowClick={handleRowClick}
          hidePagination
          selectedRow={zone?.id}
          handleSelection={handleZoneSelection}
          child={
            <LotsGoogleMaps
              lot={props.lot}
              zones={props.zones}
              selectedZone={zone}
              onUpdateZone={props.onUpdateZone}
            />
            // <GoogleMapComponent
            //   zone={zone}
            //   onUpdateZone={props.onUpdateZone}
            //   lot={props.lot}
            // />
          }
        />
      )}
    </Box>
  );
};

export { ZonesTableContainer };
