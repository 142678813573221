import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";

import { Box, Text, Select, Button, Tooltip } from "@chakra-ui/react";
import {
  LoadScript,
  useLoadScript,
  GoogleMap,
  Polygon,
  Marker,
  InfoBox,
} from "@react-google-maps/api";

import "./styles.css";

// This example presents a way to handle editing a Polygon
// The objective is to get the new path on every editing event :
// - on dragging the whole Polygon
// - on moving one of the existing points (vertex)
// - on adding a new point by dragging an edge point (midway between two vertices)

// We achieve it by defining refs for the google maps API Polygon instances and listeners with `useRef`
// Then we bind those refs to the currents instances with the help of `onLoad`
// Then we get the new path value with the `onEdit` `useCallback` and pass it to `setPath`
// Finally we clean up the refs with `onUnmount`

const polygonOptions = {
  fillColor: "red",
  fillOpacity: 0.1,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
  zIndex: 1,
};

export default function LotsGoogleMaps({
  zones,
  lot,
  selectedZone,
  onUpdateZone,
}) {
  const memoizedValue = useMemo(() => lot, [lot]);
  let getCoordinates = (value) => {
    let { type, coordinates } = value ?? {};
    if (type === "Point") {
      return { lat: coordinates?.[1], lng: coordinates?.[0] };
    } else if (type === "Polygon") {
      let coords = [];
      coordinates?.[0].map((item) => {
        coords.push({ lat: item[1], lng: item[0] });
      });
      return coords;
    }
  };

  let getBoundCoordinates = (value) => {
    let { type, coordinates } = value ?? {};
    let bounds = new window.google.maps.LatLngBounds();
    let i;

    let polygonCoords = [];

    coordinates?.[0]?.map((item) => {
      polygonCoords.push(new window.google.maps.LatLng(item[1], item[0]));
    });

    for (i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }

    return { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() };
  };
  let getPathBoundCoordinates = (coordinates) => {
    let bounds = new window.google.maps.LatLngBounds();
    let i;

    let polygonCoords = [];

    coordinates?.map((item) => {
      polygonCoords.push(new window.google.maps.LatLng(item.lat, item.lng));
    });

    for (i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }

    return { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() };
  };

  let [gps, setGPS] = useState();
  const [path, setPath] = useState([]);
  const [pathMarker, setMarkerPath] = useState({
    lat: null,
    lng: null,
  });
  let [outdoor_polygon, setOutdoorPolygon] = useState([]);
  let onSubmit = () => {
    let data = {
      gps,
      outdoor_polygon,
    };
    if (selectedZone.zone_type === 1) {
      // onEdit();
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });

        let coords = [];
        nextPath.map((item) => {
          coords.push([item.lng, item.lat]);
        });
        let value = {
          type: "Polygon",
          coordinates: [coords],
        };

        setGPS(null);
        setOutdoorPolygon(value);
        data.gps = null;
        data.outdoor_polygon = value;
      }
    }
    // else {
    //   onPositionChanged();
    // }

    onUpdateZone(selectedZone.id, data);
  };

  useEffect(() => {
    if (selectedZone?.zone_type === 1) {
      if (selectedZone?.outdoor_polygon) {
        let { coordinates } = selectedZone?.outdoor_polygon;
        let coords = [];
        coordinates[0].map((item) => {
          coords.push({ lat: item[1], lng: item[0] });
        });

        setPath(coords);
      } else {
        if (lot?.gps) {
          let { coordinates } = lot?.gps;
          setPath([
            { lat: coordinates[1], lng: coordinates[0] },
            { lat: coordinates[1], lng: coordinates[0] },
            { lat: coordinates[1], lng: coordinates[0] },
          ]);
        }
      }
    } else if (selectedZone?.zone_type === 2) {
      if (selectedZone?.gps) {
        let { type, coordinates } = selectedZone?.gps;
        setMarkerPath({ lat: coordinates[1], lng: coordinates[0] });
      } else {
        if (lot?.gps) {
          let { coordinates } = lot?.gps;
          setMarkerPath({ lat: coordinates[1], lng: coordinates[0] });
        }
      }
    }
  }, [selectedZone]);

  const onPositionChanged = (e) => {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    let value = {
      type: "Point",
      coordinates: [lng, lat],
    };
    setMarkerPath({ lat, lng });
    setGPS(value);
    setOutdoorPolygon(null);
  };

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      // setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onLoadInfoBox = (infoBox) => {
    console.log("infoBox: ", infoBox);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCr92YMktZNi9HoYmZuFHRsBBx-b-roB4E",
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <Box
      borderRadius="1rem"
      backgroundColor="white"
      mt={"-0.5rem"}
      py="3rem"
      px="2rem"
      overflow="auto"
    >
      {isLoaded ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
          mt="1rem"
        >
          <div className="map-div">
            <GoogleMap
              mapContainerClassName="App-map"
              center={getCoordinates(memoizedValue?.gps)}
              zoom={17}
              mapTypeId={"satellite"}
            >
              {zones?.map((zone, index) => (
                <React.Fragment key={index}>
                  {zone?.zone_type === 1 && (
                    <>
                      <Polygon
                        path={
                          selectedZone?.id === zone?.id
                            ? path
                            : getCoordinates(zone?.outdoor_polygon)
                        }
                        editable={selectedZone?.id === zone?.id}
                        // draggable={selectedZone?.id === zone?.id}
                        // Event used when manipulating and adding points

                        // onMouseUp={
                        //   selectedZone?.id === zone?.id ? onEdit : () => {}
                        // }
                        // Event used when dragging the whole Polygon

                        // onDragEnd={
                        //   selectedZone?.id === zone?.id ? onEdit : () => {}
                        // }
                        onLoad={
                          selectedZone?.id === zone?.id ? onLoad : () => {}
                        }
                        onUnmount={onUnmount}
                        options={
                          selectedZone?.id === zone?.id ? polygonOptions : null
                        }
                      />
                      <InfoBox
                        // onLoad={onLoadInfoBox}
                        options={{
                          closeBoxURL: "",
                          enableEventPropagation: true,
                        }}
                        position={
                          selectedZone?.id === zone?.id
                            ? getPathBoundCoordinates(path)
                            : getBoundCoordinates(zone?.outdoor_polygon)
                        }
                        // anchor={new window.google.maps.Point(1.5, 3)}
                      >
                        <div
                          style={{
                            backgroundColor:
                              selectedZone?.id === zone?.id ? "red" : "black",
                            opacity: 0.75,
                            padding: 5,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontColor: `#fff`,
                              color: "#fff",
                            }}
                          >
                            {zone.zone_name}
                          </div>
                        </div>
                      </InfoBox>
                    </>
                  )}

                  {zone?.zone_type === 2 && (
                    <Marker
                      position={
                        selectedZone?.id === zone?.id
                          ? pathMarker
                          : getCoordinates(zone?.gps)
                      }
                      title={zone?.zone_name}
                      icon={{
                        path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                        fillColor:
                          selectedZone?.id === zone?.id ? "red" : "black",
                        fillOpacity: 0.9,
                        scale: 2,
                        strokeColor: "white",
                        strokeWeight: 2,
                        anchor: new window.google.maps.Point(12.5, 23),
                      }}
                      draggable={selectedZone?.id === zone?.id}
                      // position={pathMarker}
                      // position={{ lat: 52.52047739093263, lng: 13.36653284549709 }}
                      onDragEnd={onPositionChanged}
                    />
                  )}
                </React.Fragment>
              ))}
            </GoogleMap>
          </div>
          {selectedZone?.id && (
            <Box>
              <Button
                type="submit"
                colorScheme="blue"
                mt="1rem"
                onClick={onSubmit}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <h6>Loading...</h6>
      )}
    </Box>
  );
}
