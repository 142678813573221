import * as React from "react";
import { Button, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { InputController } from "../../Controllers/InputController";

const schema = yup.object().shape({
  delete: yup
    .string()
    .matches("delete", "You should type delete")
    .required("This field is required"),
});

const DeleteAccountForm = (props) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      delete: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <InputController
        name="delete"
        type="text"
        placeholder="To confirm deletion, type delete."
        error={errors?.delete}
        control={control}
      />

      <Button
        type="submit"
        isFullWidth
        colorScheme="red"
        borderRadius="0.4rem"
        mt="3.2rem"
        fontSize="1.4rem"
        py="1.6rem"
        isLoading={isSubmitting}
      >
        Delete Account
      </Button>
    </form>
  );
};

export { DeleteAccountForm };
