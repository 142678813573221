export default {
  "3xs": "22.4rem",
  "2xs": "25.6rem",
  xs: "32rem",
  sm: "38.4rem",
  md: "44.8rem",
  lg: "51.2rem",
  xl: "57.6rem",
  "2xl": "67.2rem",
  "3xl": "76.8rem",
  "4xl": "89.6rem",
  "5xl": "102.4rem",
  "6xl": "115.2rem",
};
