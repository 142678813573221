import React, { useState, useEffect, useCallback, useRef } from "react";
import { IconButton, Box, Text, Button } from "@chakra-ui/react";
import { Redirect, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode";

import {
  downloadBase64,
  formatDate,
  getScore,
  getScreenshotOfDiv,
} from "utils";
import { S3 } from "config";

import { PlayIcon } from "assets/icons/PlayIcon";
import { PauseIcon } from "assets/icons/PauseIcon";
import { DownloadIcon } from "assets/icons/DownloadIcon";
import { PrinterIcon } from "assets/icons/PrinterIcon";
import { LoadingLayout } from "components/Layouts/LoadingLayout";
import { ReportCard } from "components/Cards/ReportCard";
import { ReportPrint } from "components/Prints/ReportPrint";

import * as Styles from "./Report.styles";

export default function Report() {
  const [qrCode, setQrCode] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [coldIgnitionStartSound, setColdIgnitionStartSound] = useState(null);
  const [accelerationSound, setAccelerationSound] = useState(null);
  const params = useParams();

  const componentRef = useRef();
  const containerRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getReport = useCallback((vin) => {
    return fetch(
      `${process.env.REACT_APP_AWS_PUBLIC_API_ENDPOINT}/getVehicle?vin=${vin}`
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });
  }, []);

  const getAccelerationSound = useCallback((vin) => {
    return fetch(
      `${process.env.REACT_APP_AWS_PUBLIC_API_ENDPOINT}/getVehicleSound?vin=${vin}&test_number=20`
    )
      .then((res) => res.json())
      .then((res) => {
        setData((prev) => ({
          ...prev,
          acceleration_sound_url: res?.data?.sound_url,
        }));
      });
  }, []);

  const getColdIgnitionStartSound = useCallback((vin) => {
    return fetch(
      `${process.env.REACT_APP_AWS_PUBLIC_API_ENDPOINT}/getVehicleSound?vin=${vin}&test_number=10`
    )
      .then((res) => res.json())
      .then((res) => {
        setData((prev) => ({
          ...prev,
          cold_ignition_sound_url: res?.data?.sound_url,
        }));
      });
  }, []);

  useEffect(async () => {
    const vin = params.reportId?.split("-")?.[0]?.toUpperCase();

    if (vin == null) {
      setLoading(false);
      return;
    }

    try {
      await getReport(vin);
      await getAccelerationSound(vin);
      await getColdIgnitionStartSound(vin);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    QRCode.toDataURL(window.location.href).then((qrCodeDataUrl) => {
      setQrCode(qrCodeDataUrl);
    });
  }, []);

  const getRoundedNumber = (value, precision) => {
    if (value == null) {
      return 0;
    }

    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const vehicleData = data?.data;

  if (loading) {
    return <LoadingLayout />;
  }

  if (vehicleData == null) {
    return <Redirect to="/" />;
  }

  const handleDownload = async () => {
    const pageHeight = containerRef.current.clientHeight;

    getScreenshotOfDiv(componentRef.current, {
      height: pageHeight,
      windowHeight: pageHeight,
      allowTaint: true,
      useCORS: true,
      logging: true,
      onclone: (doc) => {
        doc.getElementById("report-print-container").style.display = "block";
      },
    })
      .then((res) => {
        downloadBase64(res, `${vehicleData.vin}.png`);
      })
      .catch((err) => console.log({ err }));
  };

  const toggleIngnitionSound = () => {
    if (data.cold_ignition_sound_url == null) {
      return;
    }

    if (coldIgnitionStartSound != null) {
      coldIgnitionStartSound.pause();
      coldIgnitionStartSound.currentTime = 0;

      setColdIgnitionStartSound(null);
      return;
    }

    const sound = new Audio(data.cold_ignition_sound_url);

    sound.play();
    setColdIgnitionStartSound(sound);

    sound.addEventListener("ended", () => {
      setColdIgnitionStartSound(null);
    });
  };

  const toggleAccelerationSound = () => {
    if (data.acceleration_sound_url == null) {
      return;
    }

    if (accelerationSound != null) {
      accelerationSound.pause();
      accelerationSound.currentTime = 0;

      setAccelerationSound(null);
      return;
    }

    const sound = new Audio(data.acceleration_sound_url);

    sound.play();
    setAccelerationSound(sound);

    sound.addEventListener("ended", () => {
      setAccelerationSound(null);
    });
  };

  const temperature = vehicleData.test_temp_f;

  const batteryTested = getRoundedNumber(vehicleData.battery_tested, 1);
  const batteryPercentage = Math.max(
    Math.round((1 - (11 - vehicleData.battery_tested) / 4) * 100),
    0
  );

  const mpgTestValue = vehicleData.mpg_tested ?? vehicleData.mpg_est;
  const mpgTested = Number(mpgTestValue).toFixed(1);
  const mpgNew = Number(vehicleData.mpg_new).toFixed(1);
  const mpgPercentage = Math.min(
    Math.round((mpgTestValue * 100) / vehicleData.mpg_new),
    100
  );

  const brakes_new = vehicleData.brakes_new;
  const brakesTested = getRoundedNumber(vehicleData.braking_distance_tested_ft);
  const brakesPercentage = Math.round(
    (1 - (vehicleData.braking_distance_tested_ft - brakes_new) / brakes_new) *
      100
  );

  const accTested = Number(vehicleData.accel_tested).toFixed(1);
  const accNew = Number(vehicleData.accel_new).toFixed(1);
  const accelPercentage = Math.round(
    (1 -
      (vehicleData.accel_tested - vehicleData.accel_new) /
        vehicleData.accel_new) *
      100
  );

  const engineCleared = Math.round(
    Number(vehicleData.dtc_distance_km) * 0.621371
  ).toLocaleString("en");

  return (
    <Styles.Container maxWidth="100rem" ref={containerRef}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        my="2rem"
      >
        {/* <Button
          leftIcon={<DownloadIcon />}
          colorScheme="red"
          variant="solid"
          mr="1rem"
          onClick={handleDownload}
        >
          Download
        </Button> */}

        <Button
          leftIcon={<PrinterIcon />}
          colorScheme="red"
          variant="solid"
          onClick={handlePrint}
        >
          Print
        </Button>
      </Box>

      <Box backgroundColor="white" padding="2rem">
        <Styles.LogoContainer>
          <img src="/images/logo.jpg" alt="vsNew" />
          <Text as="h1" fontSize="4xl">
            Test Drive Report
          </Text>
        </Styles.LogoContainer>

        <Styles.Divider width="90%" />

        <Styles.DetailsContainer>
          <img src={vehicleData.image_url} />

          <Styles.DetailsContentContainer>
            <img src={S3.getCompanyImage(vehicleData.make)} alt="model logo" />

            <Box>
              <Text as="p" fontWeight="bold">
                {`${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`}
              </Text>
              <Text as="p">VIN {vehicleData.vin}</Text>
            </Box>

            <div />

            <div>
              <Text fontWeight="bold">
                Tested {formatDate(vehicleData.test_timestamp)}
              </Text>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text>{`${vehicleData.city}, ${vehicleData.state}`}</Text>
                {temperature != null && (
                  <Text>{Math.round(temperature)}°F</Text>
                )}
              </Box>
            </div>

            <Box display="flex" justifyContent="center" alignItems="center">
              {vehicleData.dtc_distance_km < 1600 ? (
                <Styles.EngineIcon
                  src="/images/question-box.png"
                  alt="question box icon"
                  width="50px"
                  height="50px"
                />
              ) : (
                <Styles.EngineIcon
                  src="/images/check-box.jpg"
                  alt="check box icon"
                  width="50px"
                  height="50px"
                />
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text marginTop="2rem">
                Check Engine Light - Cleared {engineCleared} miles ago
              </Text>

              {coldIgnitionStartSound != null && (
                <IconButton
                  height="auto"
                  aria-label="Play Cold Ignition Start Sound"
                  icon={
                    coldIgnitionStartSound != null ? (
                      <PauseIcon />
                    ) : (
                      <PlayIcon />
                    )
                  }
                  size="lg"
                  variant="unstyled"
                  mt="2rem"
                  onClick={toggleIngnitionSound}
                />
              )}
            </Box>
          </Styles.DetailsContentContainer>

          <Styles.QrContainer>
            <Styles.BadgeContainer>
              {vehicleData.score != null && (
                <img
                  src={S3.getBadgeImage(getScore(vehicleData.score))}
                  alt={`score ${vehicleData.score}`}
                />
              )}
            </Styles.BadgeContainer>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <img
                src={qrCode}
                alt="Barcode Scan"
                width="130px"
                height="auto"
              />

              <Text fontWeight="bold">See the full report</Text>
            </Box>
          </Styles.QrContainer>
        </Styles.DetailsContainer>

        <Box
          margin="2rem 1rem 1rem 1rem"
          borderWidth="0.4rem"
          borderStyle="solid"
          borderColor="red.500"
          padding="1rem"
        >
          <Text>
            <Text as="span" color="red.500">
              vs
            </Text>
            New measures a vehicle’s mechanical performance for used car buyers.
            We measured thousands of datapoints during a live test drive of this{" "}
            {`${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`} and
            compared it to a new version of the same vehicle.
          </Text>
        </Box>

        <Styles.ReportsContainer>
          <ReportCard
            iconSrc="/images/fuel.png"
            title="Highway MPG"
            unit="MPG"
            value={mpgTested}
            comparedValue={mpgNew}
            percentage={mpgPercentage}
          />
          <ReportCard
            iconSrc="/images/speedometer.png"
            title="Acceleration 5-60 mph"
            unit="SEC"
            value={accTested}
            comparedValue={accNew}
            percentage={accelPercentage}
          >
            {data.acceleration_sound_url != null && (
              <IconButton
                height="auto"
                aria-label="Play Cold Ignition Start Sound"
                icon={accelerationSound != null ? <PauseIcon /> : <PlayIcon />}
                size="lg"
                variant="unstyled"
                mt="1rem"
                onClick={toggleAccelerationSound}
              />
            )}
          </ReportCard>
          <ReportCard
            iconSrc="/images/battery.png"
            title="Battery"
            unit="VOLTS"
            hideCompared
            value={batteryTested}
            percentage={batteryPercentage}
          />
          <ReportCard
            iconSrc="/images/brakes.png"
            title="Braking 30-0 mph"
            unit="FEET"
            hideCompared
            value={brakesTested}
            percentage={brakesPercentage}
          />
        </Styles.ReportsContainer>

        <Styles.Divider margin="2rem 0rem" />

        <Box textAlign="center" padding="0.5rem">
          <Text>
            All tests are conducted by auction or dealer personnel. All data is
            measured and calculated by vsNew. Highway MPG may be measured during
            an open highway test or estimated during a stationary test.
          </Text>

          <Text>
            © 2022{" "}
            <Text as="span" color="red.500">
              vs
            </Text>
            New, Inc. Visit{" "}
            <Text
              as="a"
              href="https://vsnew.com/"
              target="_blank"
              rel="noreferrer"
              textDecoration="underline"
            >
              vsnew.com
            </Text>{" "}
            for Terms and Conditions
          </Text>
        </Box>
      </Box>

      <Box id="report-print-container" display="none">
        <ReportPrint
          ref={componentRef}
          vehicleData={vehicleData}
          temperature={temperature}
          engineCleared={engineCleared}
          mpgTested={mpgTested}
          mpgNew={mpgNew}
          mpgPercentage={mpgPercentage}
          accTested={accTested}
          accNew={accNew}
          accelPercentage={accelPercentage}
          batteryTested={batteryTested}
          batteryPercentage={batteryPercentage}
          brakesTested={brakesTested}
          brakesPercentage={brakesPercentage}
          qrCode={qrCode}
        />
      </Box>
    </Styles.Container>
  );
}
