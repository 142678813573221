import {
  Tooltip,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.orderBy === false ? (
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{ color: "#4a5568" }}
              >
                {headCell.label?.toUpperCase()}
                {headCell?.description && (
                  <Tooltip title={headCell?.description} placement="top" arrow>
                    <InfoOutlinedIcon sx={{ fontSize: 12, marginTop: -2 }} />
                  </Tooltip>
                )}
              </Typography>
            ) : (
              <TableSortLabel
                sx={{ color: "#4a5568", fontSize: "1.2rem" }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label?.toUpperCase()}
                {headCell?.description && (
                  <Tooltip title={headCell?.description} placement="top" arrow>
                    <InfoOutlinedIcon sx={{ fontSize: 12, marginTop: -2 }} />
                  </Tooltip>
                )}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
