import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Input,
  Select,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { InputController } from "components/Controllers/InputController";
import { useUserContext } from "context/UserContext";

import { Controller } from "react-hook-form";
import { CAStates, UKStates, USStates } from "constants/Countries";
import "./styles.scss";

export default function TSAgreement({
  control,
  errors,
  handleSubmit,
  setValue,
  watch,
  signAgreement,
  isSubmitting,
}) {
  const { user } = useUserContext();

  const history = useHistory();

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "3.2rem",
        borderRadius: "1.6rem",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <p
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "32px" }}
        >
          <strong>vsNEW LICENSE AGREEMENT</strong>
        </p>
      </div>
      <div>
        <div>
          <br />
          <span>
            This agreement is entered into this {moment().format("MM/DD/YYYY")}{" "}
            by and between <strong>vsNew</strong>, Inc., 19 Morris Avenue,
            Building 128, Brooklyn, NY 11205 (“Licensor”), and{" "}
            <Controller
              control={control}
              name={"dealerName"}
              render={({ field }) => (
                <Input
                  px="1.4rem"
                  py="1.7rem"
                  fontSize="1.4rem"
                  borderRadius="0.4rem"
                  backgroundColor="gray.50"
                  borderColor="gray.50"
                  placeholder="Business Name"
                  style={{ maxWidth: "150px" }}
                  {...field}
                />
              )}
            />{" "}
            {errors?.dealerName && (
              <FormControl isInvalid={Boolean(errors?.dealerName)}>
                <FormErrorMessage>
                  {errors?.dealerName?.message}
                </FormErrorMessage>
              </FormControl>
            )}
            (“Licensee”) with shipping address at{" "}
            <Controller
              control={control}
              name={"address"}
              render={({ field }) => (
                <Input
                  px="1.4rem"
                  py="1.7rem"
                  fontSize="1.4rem"
                  borderRadius="0.4rem"
                  backgroundColor="gray.50"
                  borderColor="gray.50"
                  placeholder="Address 1"
                  style={{ maxWidth: "180px" }}
                  {...field}
                />
              )}
            />
            {errors?.address && (
              <FormControl isInvalid={Boolean(errors?.address)}>
                <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
              </FormControl>
            )}
            ,{" "}
            <Controller
              control={control}
              name={"address2"}
              render={({ field }) => (
                <Input
                  px="1.4rem"
                  py="1.7rem"
                  fontSize="1.4rem"
                  borderRadius="0.4rem"
                  backgroundColor="gray.50"
                  borderColor="gray.50"
                  placeholder="Address 2 (Optional)"
                  style={{ maxWidth: "180px" }}
                  {...field}
                />
              )}
            />
            {errors?.address2 && (
              <FormControl isInvalid={Boolean(errors?.address2)}>
                <FormErrorMessage>{errors?.address2?.message}</FormErrorMessage>
              </FormControl>
            )}
            ,{" "}
            <Controller
              control={control}
              name={"city"}
              render={({ field }) => (
                <Input
                  px="1.4rem"
                  py="1.7rem"
                  fontSize="1.4rem"
                  borderRadius="0.4rem"
                  backgroundColor="gray.50"
                  borderColor="gray.50"
                  placeholder="City"
                  style={{ maxWidth: "180px" }}
                  {...field}
                />
              )}
            />
            {errors?.city && (
              <FormControl isInvalid={Boolean(errors?.city)}>
                <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
              </FormControl>
            )}
            ,{" "}
            <Controller
              control={control}
              name={"state"}
              render={({ field }) => (
                <Select
                  // px="1.4rem"
                  // py="1.7rem"
                  placeholder={
                    user?.country == "USA"
                      ? "State"
                      : user?.country == "CA"
                      ? "Province"
                      : "Locality"
                  }
                  size="lg"
                  fontSize="1.4rem"
                  borderRadius="0.4rem"
                  backgroundColor="gray.50"
                  borderColor="gray.50"
                  onChange={(value) => {
                    console.log(value);
                    setValue("state", value);
                  }}
                  rootProps={{
                    style: { display: "contents", maxWidth: "180px" },
                  }}
                  style={{ padding: "0px 14px", maxWidth: "180px" }}
                  height={"38px"}
                  {...field}
                >
                  {(user?.country == "USA" ||
                    user?.country == "" ||
                    user?.country == null) && (
                    <>
                      {USStates?.map((item, index) => (
                        <option key={index} value={item?.abbreviation}>
                          {item?.abbreviation} - {item.name}
                        </option>
                      ))}
                    </>
                  )}
                  {user?.country == "CA" && (
                    <>
                      {CAStates?.map((item, index) => (
                        <option key={index} value={item?.abbreviation}>
                          {item?.abbreviation} - {item.name}
                        </option>
                      ))}
                    </>
                  )}
                  {user?.country == "UK" && (
                    <>
                      {UKStates?.map((item, index) => (
                        <option key={index} value={item?.abbreviation}>
                          {item?.abbreviation} - {item.name}
                        </option>
                      ))}
                    </>
                  )}
                </Select>
              )}
            />
            {errors?.state && (
              <FormControl isInvalid={Boolean(errors?.state)}>
                <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
              </FormControl>
            )}
            ,
            <Input
              px="1.4rem"
              py="1.7rem"
              fontSize="1.4rem"
              borderRadius="0.4rem"
              backgroundColor="gray.50"
              borderColor="gray.50"
              placeholder="Country"
              disabled
              value={user?.country}
              style={{ maxWidth: "180px" }}
            />
            ,{" "}
            <Controller
              control={control}
              name={"zip"}
              render={({ field }) => (
                <Input
                  px="1.4rem"
                  py="1.7rem"
                  fontSize="1.4rem"
                  borderRadius="0.4rem"
                  backgroundColor="gray.50"
                  borderColor="gray.50"
                  placeholder={
                    user?.country == "USA"
                      ? "Zipcode"
                      : user?.country == "CA"
                      ? "Postal Code"
                      : "Post Code"
                  }
                  style={{ maxWidth: "180px" }}
                  {...field}
                />
              )}
            />
            .
            {errors?.zip && (
              <FormControl isInvalid={Boolean(errors?.zip)}>
                <FormErrorMessage>{errors?.zip?.message}</FormErrorMessage>
              </FormControl>
            )}
          </span>
          <br />
          <p>
            Licensor and Licensee agree to the following:
            <br />
            <br />
            1. LICENSE GRANT - Licensor hereby grants to Licensee for the term
            of this Agreement, a nonexclusive, non- assignable, right and
            license to use its hardware with the vsNEW.com website and
            smartphone applications (“Technology”).
            <br />
            <br />
            2. TERM and TERMINATION – This Agreement shall be effective as of
            the date of execution and will continue unless terminated by either
            party. Both parties agree to an initial term of one month after
            delivery of the equipment with additional one-month term(s)
            automatically renewing. Either party shall have the right to
            terminate this Agreement for any or no reason by sending a notice of
            termination no later than 30 days prior to the end of the respective
            term.
            <br />
            <br />
            3. COMPENSATION – In consideration for the hardware provided and
            licenses granted hereunder, Licensee agrees to pay $125 upfront and
            $100 per month per diagnostic device upon delivery of equipment.
            Licensor will provide 1 vsNEW Device, 1 Adapter cable, 1 Extension
            Cable, and unlimited battery reports. Licensee agrees that this
            equipment will be considered delivered and in good working
            condition, unless a notice of discrepancy is written by Licensee
            within 10 business days of the date of execution of this agreement.
            Licensee can purchase replacement equipment at the following costs:
            vsNEW Device at $100 per device, Adapter cable at $25 per cable, and
            Extension Cables at $10 per cable, not including shipping costs.
            <br />
            <br />
            4. CHANGES TO COMPENSATION – Licensor will provide 60 days notice to
            Licensee before modifying the compensation.
            <br />
            <br />
            5. POST-TERMINATION - Upon the expiration or termination of this
            Agreement, all rights granted to Licensee under this Agreement shall
            forthwith terminate and Licensee shall discontinue all use of the
            vsNEW services. The rights and obligations of the parties set out in
            Sections 9, 10, 12, 13, and 14 will survive any termination of this
            Agreement. Upon termination, Licensee agrees to return all equipment
            in working condition to Licensor within ten (10) business days and
            agrees to pay for all missing or damaged equipment.
            <br />
            <br />
            6. OBLIGATIONS/CONFIDENTIALITY
            <br />
            A. Licensee recognizes that all Technology is the proprietary and
            confidential property of Licensor. Licensee agrees not to disclose
            any information about this Agreement or Technology to any third
            party. Licensee may not recompile, decompile, disassemble, reverse
            engineer, or make or distribute any other form of, or any derivative
            work from, the Technology.
            <br />
            B. Any and all information collected via vsNEW system shall remain
            the Property of Licensor.
            <br />
            <br />
            7. TECHNICAL INFORMATION - Licensor represents that the technical
            information and assistance relating to the Technology conveyed under
            this Agreement shall be provided with reasonable care and will,
            where applicable, be of the same types as currently practiced by
            Licensor.
            <br />
            <br />
            8. LICENSEE’S OBLIGATIONS - Licensee represents that it has the
            financial resources and business operations that will enable it to
            use the Technology during the Term of this Agreement. Licensee
            agrees to allow Licensor to use Licensee name, logo, domain name,
            photos of Licensee premises and other brand designations in Licensor
            marketing, promotional, and informational materials. In the event of
            a legal dispute, Licensee may recover only amounts paid to Licensor
            fulfilling this agreement.
            <br />
            <br />
            9. INDEMNITY – Notwithstanding anything to the contrary, each party
            agrees to defend, indemnify, and hold the other party, and its
            officers, directors, agents, and employees (successors, assigns),
            harmless against all costs, expenses, and losses (including
            reasonable attorney fees and costs) arising from claims of third
            parties against the other party as a result of any negligent act or
            omission or breach of this Agreement by the indemnifying party.
            <br />
            <br />
            10. FORCE MAJEURE - Neither party will be liable for, or will be
            considered to be in breach of or default under this Agreement on
            account of, any delay or failure to perform as required by this
            Agreement as a result of any causes or conditions that are beyond
            such Party’s reasonable control and that such Party is unable to
            overcome through the exercise of commercially reasonable diligence.
            Written notice required at addresses above.
            <br />
            <br />
            11. NOTICE AND PAYMENT - Any notice required to be given under this
            Agreement shall be in writing and delivered personally to the other
            designated party at the above stated address, mailed by certified,
            registered or Express mail, by overnight carrier, or by email.
            Either party may change the address to which notice or payment is to
            be sent by written notice to the other under any provision of this
            paragraph.
            <br />
            <br />
            12. JURISDICTION/DISPUTES (ARBITRATION REQUIRED) – for any legal
            matter. Clear, efficient Arbitration will be arranged between
            parties for the purpose of effectuating a resolution under AAA or
            similar rules, New York location. This Agreement shall be governed
            in accordance with the laws of the State of NEW YORK.
            <br />
            <br />
            13. ASSIGNABILITY - Licensee may not assign this Agreement or the
            rights and obligations thereunder to any third party without the
            prior express written approval of the Licensor.
            <br />
            <br />
            14. WAIVER/SEVERABILITY - No waiver by either party of any default
            shall be deemed as a waiver of prior or subsequent default of the
            same of other provisions of this Agreement. If any term, clause or
            provision hereof is held invalid by a court of competent
            jurisdiction, such invalidity shall not affect the validity or
            operation of any other term, clause or provision and such invalid
            term, clause or provision shall be deemed to be severed from the
            Agreement.
            <br />
            <br />
            15. INTEGRATION / AMENDMENTS - This Agreement constitutes the entire
            understanding of the Parties and this Agreement shall take
            precedence over any other documents that may conflict with this
            Agreement. No amendment to, or modification of this Agreement is
            effective unless it is in writing and signed by an authorized
            representative of each party.
            <br />
            <br />
            <br />
            IN WITNESS WHEREOF, the Parties hereto, intending to be legally
            bound hereby, have each caused to be affixed hereto its or his/her
            hand and seal the day indicated:
            <br />
            <br />
            <br />
          </p>
          <div>
            <div
              className="signagreementdiv"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
              }}
            >
              <div style={{ padding: "20px" }}>
                <div>
                  <p style={{ fontWeight: "bold" }}>Licensor</p>
                  <p>vsNew, Inc.</p>
                  <table style={{ textAlign: "left" }}>
                    <tbody>
                      <tr>
                        <th style={{ minWidth: "120px" }}>By</th>
                        <td style={{ fontStyle: "italic" }}>Sachin Chaudhry</td>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "120px" }}>Name</th>
                        <td>Sachin Chaudhry</td>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "120px" }}>Email</th>
                        <td>support@vsnew.com</td>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "120px" }}>Date</th>
                        <td>{moment().format("MM/DD/YYYY")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ padding: "20px" }}>
                <div>
                  <p style={{ fontWeight: "bold" }}>Licensee</p>
                  <p>{watch("dealerName")}</p>
                  <table style={{ textAlign: "left" }}>
                    <tbody>
                      <tr>
                        <th style={{ minWidth: "120px" }}>By</th>
                        <td>
                          <InputController
                            name="signature"
                            placeholder="Signature"
                            error={errors?.signature}
                            control={control}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "120px" }}>Name</th>
                        <td>
                          <InputController
                            name="name"
                            placeholder="Name"
                            error={errors?.name}
                            control={control}
                            // defaultValue={user?.name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "120px" }}>Email</th>
                        <td>{user?.email}</td>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "120px" }}>Date</th>
                        <td>{moment().format("MM/DD/YYYY")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <Button
              type="submit"
              //   isFullWidth
              colorScheme="red"
              borderRadius="0.4rem"
              mt="3.2rem"
              fontSize="1.4rem"
              py="1.6rem"
              style={{ minWidth: "120px" }}
              isLoading={isSubmitting}
              // onClick={() => {
              //   history.push("/payment");
              // }}
              onClick={handleSubmit(signAgreement)}
            >
              Agree and Submit
            </Button>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
