import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import { SWRConfig } from "swr";

import { ApiService } from "./services";
import { UserContextProvider } from "./context";
import { amplifyConfig } from "./config";
import { theme } from "./theme";

import { Navigation } from "./routing/Navigation";
import "./App.css";

Amplify.configure(amplifyConfig);

const App = () => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Router>
        <SWRConfig
          value={{
            shouldRetryOnError: false,
            fetcher: ApiService.get,
          }}
        >
          <UserContextProvider>
            <Navigation />
          </UserContextProvider>
        </SWRConfig>
      </Router>
    </ChakraProvider>
  );
};

export default App;
