import React from "react";
import { Route, Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ForgotPasswordSubmit } from "../pages/ForgotPasswordSubmit";
import { Home } from "../pages/Home";
import { EVHome } from "../pages/EVHome";
import { Settings } from "../pages/Settings";
import {
  Report,
  ReportEV,
  ReportHybrid,
  Report_Screenshot,
} from "../pages/Report";
import { Callback } from "../pages/Callback";
import { Admin } from "../pages/Admin";
import { Inventory } from "../pages/Inventory";
import SignAgreement from "../pages/SignAgreement";
import Payment from "../pages/Payment";

const Navigation = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/battery" component={EVHome} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/inventory" component={Inventory} />
      <PrivateRoute exact path="/billing" component={null} />
      <PrivateRoute exact path="/admin" component={Admin} />
      <PrivateRoute exact path="/sign-agreement" component={SignAgreement} />
      <PrivateRoute exact path="/payment" component={Payment} />
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/register" component={Register} />
      <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
      <PublicRoute
        exact
        path="/forgot-password-submit"
        component={ForgotPasswordSubmit}
      />
      <Route exact path="/callback/confirm-account" component={Callback} />
      <Route path="/report/:reportId" component={Report} />
      <Route path="/ev/:reportId" component={ReportEV} />
      <Route
        path="/screenshot/:vehicle_type/:reportId"
        component={Report_Screenshot}
      />
      <Route path="/hybrid/:reportId" component={ReportHybrid} />
    </Switch>
  );
};

export { Navigation };
