import React from "react";
import { Box, Text } from "@chakra-ui/react";

import { formatDate, getScore } from "utils";
import { S3 } from "config";

import { ReportCard } from "components/Cards/ReportCard";

import * as Styles from "./ReportPrint.styles";

const ReportPrint = React.forwardRef((props, ref) => {
  const {
    vehicleData,
    temperature,
    engineCleared,
    mpgTested,
    mpgNew,
    mpgPercentage,
    accTested,
    accNew,
    accelPercentage,
    batteryTested,
    batteryPercentage,
    brakesTested,
    brakesPercentage,
    qrCode,
  } = props;

  return (
    <Styles.Container
      ref={ref}
      maxWidth="100vw"
      maxHeight="100vh"
      width="100%"
      height="100%"
      padding="2rem"
    >
      <Styles.LogoContainer>
        <img src="/images/logo.jpg" alt="vsNew" />
        <Text as="h1" fontSize="4xl">
          Test Drive Report
        </Text>
      </Styles.LogoContainer>

      <Styles.Divider width="90%" />

      <Styles.DetailsContainer>
        <img src={vehicleData.image_url} />

        <Styles.DetailsContentContainer>
          <img src={S3.getCompanyImage(vehicleData.make)} alt="model logo" />

          <Box>
            <Text as="p" fontWeight="bold">
              {`${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`}
            </Text>
            <Text as="p">VIN {vehicleData.vin}</Text>
          </Box>

          <div />

          <div>
            <Text fontWeight="bold">
              Tested {formatDate(vehicleData.test_timestamp)}
            </Text>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text>{`${vehicleData.city}, ${vehicleData.state}`}</Text>
              {temperature != null && <Text>{Math.round(temperature)}°F</Text>}
            </Box>
          </div>

          <Box display="flex" justifyContent="center" alignItems="center">
            {vehicleData.dtc_distance_km < 1600 ? (
              <Styles.EngineIcon
                src="/images/question-box.png"
                alt="question box icon"
                width="50px"
                height="50px"
              />
            ) : (
              <Styles.EngineIcon
                src="/images/check-box.jpg"
                alt="check box icon"
                width="50px"
                height="50px"
              />
            )}
          </Box>

          <Text marginTop="2rem">
            Check Engine Light - Cleared {engineCleared} miles ago
          </Text>
        </Styles.DetailsContentContainer>

        <Styles.QrContainer>
          <Styles.BadgeContainer>
            {vehicleData.score != null && (
              <img
                src={S3.getBadgeImage(getScore(vehicleData.score))}
                alt={`score ${vehicleData.score}`}
              />
            )}
          </Styles.BadgeContainer>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <img src={qrCode} alt="Barcode Scan" width="130px" height="auto" />

            <Text fontWeight="bold">See the full report</Text>
          </Box>
        </Styles.QrContainer>
      </Styles.DetailsContainer>

      <Box
        margin="2rem 1rem 1rem 1rem"
        borderWidth="0.4rem"
        borderStyle="solid"
        borderColor="red.500"
        padding="1rem"
      >
        <Text>
          <Text as="span" color="red.500">
            vs
          </Text>
          New measures a vehicle’s mechanical performance for used car buyers.
          We measured thousands of datapoints during a live test drive of this{" "}
          {`${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`} and
          compared it to a new version of the same vehicle.
        </Text>
      </Box>

      <Styles.ReportsContainer>
        <ReportCard
          iconSrc="/images/fuel.png"
          title="Highway MPG"
          unit="MPG"
          value={mpgTested}
          comparedValue={mpgNew}
          percentage={mpgPercentage}
        />
        <ReportCard
          iconSrc="/images/speedometer.png"
          title="Acceleration 5-60 mph"
          unit="SEC"
          value={accTested}
          comparedValue={accNew}
          percentage={accelPercentage}
        />
        <ReportCard
          iconSrc="/images/battery.png"
          title="Battery"
          unit="VOLTS"
          hideCompared
          value={batteryTested}
          percentage={batteryPercentage}
        />
        <ReportCard
          iconSrc="/images/brakes.png"
          title="Braking 30-0 mph"
          unit="FEET"
          hideCompared
          value={brakesTested}
          percentage={brakesPercentage}
        />
      </Styles.ReportsContainer>

      <Styles.Divider margin="2rem 0rem" />

      <Box textAlign="center" padding="0.5rem">
        <Text>
          All tests are conducted by auction or dealer personnel. All data is
          measured and calculated by vsNew. Highway MPG may be measured during
          an open highway test or estimated during a stationary test.
        </Text>

        <Text>
          © 2021{" "}
          <Text as="span" color="red.500">
            vs
          </Text>
          New, Inc. Visit vsnew.com for Terms and Conditions
        </Text>
      </Box>
    </Styles.Container>
  );
});

export default ReportPrint;
