import React, { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { useToast } from "@chakra-ui/toast";

const SetupForm = (props) => {
  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    // const { error } = await

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        // billing_details: {
        //   name: "Jenny Rosen",
        // },
      })
      .then(async (result) => {
        if (result?.error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          setIsLoading(false);
          setErrorMessage(result?.error?.message);
          toast({ title: result?.error?.message, status: "error" });
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          await props.attachPaymentMethod(result.paymentMethod?.id);
          toast({
            title: "Your payment method was successfuly updated",
            status: "success",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          setErrorMessage(error?.message);
          toast({ title: error?.message, status: "error" });
        }
      });

    // stripe
    //   .confirmSetup({
    //     //`Elements` instance that was used to create the Payment Element
    //     elements,
    //     confirmParams: {
    //       return_url: window.location.href,
    //     },
    //   })
    //   .then((result) => {
    //     setIsLoading(false);
    //     if (result?.error) {
    //       // This point will only be reached if there is an immediate error when
    //       // confirming the payment. Show error to your customer (for example, payment
    //       // details incomplete)
    //       setErrorMessage(result?.error?.message);
    //       toast({ title: result?.error?.message, status: "error" });
    //     } else {
    //       // Your customer will be redirected to your `return_url`. For some payment
    //       // methods like iDEAL, your customer will be redirected to an intermediate
    //       // site first to authorize the payment, then redirected to the `return_url`.
    //       toast({
    //         title: "Payment Method has been updated.",
    //         status: "success",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //     if (error) {
    //       // This point will only be reached if there is an immediate error when
    //       // confirming the payment. Show error to your customer (for example, payment
    //       // details incomplete)
    //       setErrorMessage(error?.message);
    //       toast({ title: error?.message, status: "error" });
    //     }
    //   });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {/* <button disabled={!stripe}>Submit</button> */}
      <Button
        type="submit"
        disabled={!stripe}
        isFullWidth
        colorScheme="red"
        borderRadius="0.4rem"
        mt="3.2rem"
        fontSize="1.4rem"
        py="1.6rem"
        isLoading={isLoading}
      >
        Update Payment Method
      </Button>
      {/* Show error message to your customers */}
      {errorMessage && (
        <div style={{ textAlign: "center", color: "red" }}>{errorMessage}</div>
      )}
    </form>
  );
};

export default SetupForm;
