import React, { useEffect, useState } from "react";
import borderMain from "assets/new_report/border.png";
import Pie from "assets/new_report/fast-charging-history-pie.png";
import needle from "assets/new_report/needle.png";
import { Tooltip } from "@chakra-ui/react";
import InfoIcon from "@mui/icons-material/Info";
import "./graph.scss";

function PieChart({ risk, heading, width, tooltipDescription }) {
  const [rotation, setRotation] = useState(-80);

  useEffect(() => {
    let timeout = setTimeout(() => {
      let risk1 = risk;

      // if (risk1 <= 10) {
      //   setRotation(-50);
      // } else if (risk1 <= 20) {
      //   setRotation(0);
      // } else if (risk1 <= 100) {
      //   setRotation(60);
      // }
      if (risk1 <= 10) {
        setRotation(-80);
      } else if (risk1 <= 20) {
        setRotation(-50);
      } else if (risk1 <= 30) {
        setRotation(-35);
      } else if (risk1 <= 40) {
        setRotation(-20);
      } else if (risk1 <= 50) {
        setRotation(0);
      } else if (risk1 <= 60) {
        setRotation(30);
      } else if (risk1 <= 70) {
        setRotation(40);
      } else if (risk1 <= 80) {
        setRotation(55);
      } else if (risk1 <= 90) {
        setRotation(70);
      } else {
        setRotation(90);
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [risk]);

  const getAnimationText = (risk) => {
    let animation = risk + "%";
    // if (risk > 0.66 && risk <= 1) {
    //   animation = risk + "%";
    // } else if (risk >= 0.33 && risk <= 0.66) {
    //   animation = risk + "%";
    // }
    return animation;
  };
  return (
    <div className="pie_wrap">
      <div className="heading" style={{ paddingBottom: 0 }}>
        <div>
          <h3 style={{ display: "inherit", fontSize: 18 }}>
            {heading}
            <Tooltip label={tooltipDescription}>
              <InfoIcon
                sx={{
                  color: "black",
                  fontSize: 16,
                  position: "relative",
                  alignSelf: "flex-end",
                }}
              />
            </Tooltip>
          </h3>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "20px",
        }}
      >
        <img src={borderMain} className="borderImage" alt="" />
      </div>
      <div className="pie_holder">
        <span className="range_text">LOW</span>
        <div className="pie_container">
          <img className="pie_chart" style={{ width }} src={Pie} alt="" />
          <span className="upper_niddle_text">{getAnimationText(risk)}</span>
          <div className="needle_container">
            <img
              style={{ transform: `rotate(${rotation}deg)` }}
              className="pie_needle"
              src={needle}
              alt=""
            />
            <div className="needle_base"> </div>
          </div>
        </div>
        <span className="range_text">HIGH</span>
      </div>
    </div>
  );
}

export default PieChart;
