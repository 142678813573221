import * as React from "react";
import {
  Button,
  Box,
  Select,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { InputController } from "../../Controllers/InputController";
import { SelectController } from "components/Controllers/SelectController";
import { Countries } from "constants/Countries";

const schema = yup.object().shape({
  email: yup.string().email().required("This field is required"),
  name: yup.string().required("This field is required"),
  password: yup.string().min(8).required("This field is required"),
  dealerWebsite: yup.string().required("This field is required"),
  partner_code: yup
    .string()
    .notRequired()
    .test(
      "startsWithEVUCastrol",
      "Please enter a valid partner code. If you do not have one, please leave partner code blank.",
      (value) => {
        if (!value) return true; // Allow empty values
        return /^(EVU|CASTROL|TRIAL|TS|EVCHECKUP|LECTRIUM|149SP|12MONTH100|TEST1)/i.test(
          value
        );
      }
    ),
  // .nullable()
  // .matches(/^(EVU|CASTROL)/i, "Invalid Partner Code"),
  country: yup.string().required("This field is required"),
});

const RegisterForm = (props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      name: "",
      password: "",
      dealerWebsite: "",
      partner_code: "",
      country: "",
    },
  });

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const partner_code = query.get("Partner_code");

    return { partner_code };
  };
  const { partner_code = "" } = getQueryParams();
  React.useEffect(() => {
    if (partner_code) {
      setValue("partner_code", partner_code);
    }
  }, [partner_code]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <InputController
        name="name"
        placeholder="Name"
        fontSize="1.8rem"
        error={errors?.name}
        control={control}
      />

      <Box mt="1.6rem">
        <InputController
          name="email"
          type="email"
          fontSize="1.8rem"
          placeholder="Business Email Address"
          error={errors?.email}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <InputController
          name="password"
          type="password"
          fontSize="1.8rem"
          placeholder="Your Password"
          error={errors?.password}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <SelectController
          control={control}
          name={"country"}
          fontSize="1.8rem"
          options={
            <>
              <option disabled value={""} placeholder="Select Option">
                Select Country
              </option>
              {Countries?.map((item, index) => (
                <option key={index} value={item?.abbreviation}>
                  {item?.abbreviation} - {item.name}
                </option>
              ))}
            </>
          }
          onChange={(value) => setValue("country", value)}
          error={errors?.state}
        />
      </Box>

      <Box mt="1.6rem">
        <InputController
          name="dealerWebsite"
          placeholder="Business Website"
          fontSize="1.8rem"
          error={errors?.dealerWebsite}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <InputController
          name="partner_code"
          placeholder="Partner Code (optional)"
          fontSize="1.8rem"
          error={errors?.partner_code}
          control={control}
          onInput={(e) => {
            e.target.value = ("" + e.target.value).toUpperCase();
          }}
        />
      </Box>

      <Button
        type="submit"
        isFullWidth
        colorScheme="red"
        borderRadius="0.4rem"
        mt="3.2rem"
        fontSize="1.8rem"
        py="1.6rem"
        isLoading={isSubmitting}
      >
        Sign Up
      </Button>
    </form>
  );
};

export { RegisterForm };
