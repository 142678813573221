import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Flex,
  Button,
  SimpleGrid,
  Image,
  Container,
  useMediaQuery,
} from "@chakra-ui/react";

import Logo from "../../assets/logo-navbar.png";

import { NavbarMobile } from "./NavbarMobile";
import { useUserContext } from "context";

const Navbar = () => {
  const [isSmallScreen] = useMediaQuery("(max-width: 769px)");
  const { pathname } = useLocation();
  const { user } = useUserContext();

  const LINKS = useMemo(() => {
    const {
      ice_access,
      ev_access,
      inventory_access,
      admin_access,
      billing_access,
    } = user;

    const navLinks = [];

    if (ice_access > 0) {
      navLinks.push({
        url: "/",
        name: "ICE",
      });
    }

    if (ev_access > 0) {
      navLinks.push({
        url: "/battery",
        name: "Battery",
      });
    }

    if (inventory_access > 0) {
      navLinks.push({
        url: "/inventory",
        name: "Inventory",
        isDisabled: false,
      });
    }

    if (admin_access > 0) {
      navLinks.push({
        url: "/admin",
        name: "Admin",
        isDisabled: false,
      });
    }

    if (billing_access > 0) {
      navLinks.push({
        url: "/billing",
        name: "Billing",
        isDisabled: true,
      });
    }

    navLinks.push({
      url: "/settings",
      name: "Settings",
    });

    return navLinks;
  }, [user]);

  return (
    <Flex padding="2rem" backgroundColor="white" alignItems="center">
      <Container
        maxW="container.lg"
        display="flex"
        justifyContent="space-between"
      >
        <Link to="/">
          <Image src={Logo} alt="vsNew Logo" cursor="pointer" height={30} />
        </Link>

        {isSmallScreen ? (
          <NavbarMobile />
        ) : (
          <SimpleGrid columns={5} __css=" > * { text-align: center}">
            {LINKS.map((link) => (
              <Link key={link.url} to={link.url}>
                <Button
                  colorScheme="black"
                  variant="button"
                  isActive={link.url === pathname}
                  isDisabled={link.isDisabled}
                  _active={{
                    fontWeight: "bold",
                  }}
                >
                  {link.name}
                </Button>
              </Link>
            ))}
          </SimpleGrid>
        )}
      </Container>
    </Flex>
  );
};

export { Navbar };
