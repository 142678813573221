import { Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const HaveAnAccountLink = (props) => {
  return (
    <Text fontSize="1.3rem" color="gray.400" as="span">
      {props.text}

      <Link as={RouterLink} to={props.href} color="red.600" ml="0.4rem">
        {props.action}
      </Link>
    </Text>
  );
};

HaveAnAccountLink.defaultProps = {
  text: "",
  action: "",
  href: "",
};

export { HaveAnAccountLink };
