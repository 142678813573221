import React from "react";
import { Box, Text } from "@chakra-ui/react";

import { useUserContext } from "../../context";
import SignupLottie from "assets/lotties/signup.lottie.json";
import vsNEW_4_SignUp from "assets/gifs/vsNEW_4_SignUp.gif";

import { AuthenticationLayout } from "../../components/Layouts/AuthenticationLayout";
import { RegisterForm } from "../../components/Forms/RegisterForm";
import { HaveAnAccountLink } from "../../components/Links/HaveAnAccountLink";

const Register = () => {
  const { register } = useUserContext();

  return (
    <AuthenticationLayout
      title="Sign Up"
      animationData={SignupLottie}
      gif={vsNEW_4_SignUp}
    >
      <Box textAlign="center" mt="1.8rem">
        <Text color="gray.900" fontWeight="bold" fontSize="2.2rem">
          Sign Up
        </Text>

        <Text color="gray.400" fontSize="1.4rem">
          Enter your info below
        </Text>
      </Box>

      <Box mt="4.2rem">
        <RegisterForm onSubmit={register} />
      </Box>

      <Box mt="1.65rem" textAlign="center">
        <HaveAnAccountLink
          text="Already have an account?"
          action="Log In"
          href="/login"
        />
      </Box>
    </AuthenticationLayout>
  );
};

export { Register };
