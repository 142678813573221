import React, { createContext, useContext } from "react";
import { useToast } from "@chakra-ui/react";

import { useVehicles, useVehicleRatings } from "hooks";
import { ApiService } from "services";
import { STATUSES } from "config";
import { toSeoUrl } from "utils";
import { useInventory } from "hooks/useInventory";

const DashboardContextProvider = (props) => {
  const toast = useToast();
  const {
    isLoading: inventoryIsLoading,
    lots,
    zones,
    lot_id,
    setLotId,
    handleCreateZone,
    handleUpdateZone,
    handleDeleteZone,
    handleUpdateZoneOrder,
    devices,
    handleUpdateDevice,
    zonesStats,
    inventoryList,
    handleSearchInventory,
  } = useInventory(props.endpoints);
  const completedVehicles = useVehicles(
    STATUSES.COMPLETED.status,
    props.endpoints
  );
  const pendingVehicles = useVehicles(STATUSES.PENDING.status, props.endpoints);
  const dismissedVehicles = useVehicles(
    STATUSES.DISMISSED.status,
    props.endpoints
  );
  const {
    vehicleRatingsStatistics,
    ratingsTrendStatistics,
    mutateVehicleRatingsStatistics,
    mutateRatingsTrendStatistics,
  } = useVehicleRatings({ endpoints: props.endpoints });

  const handleOpenReport = (id, reportType = "report") => {
    const vehicles = completedVehicles?.dataRef?.current?.data ?? [];
    const data = vehicles.find((x) => x.id === id);

    if (data == null) {
      return;
    }

    let path = "";
    if (reportType == "ev") {
      path = `/${data?.vehicle_type ?? reportType}/${data.id}-${data.vin}-${
        data.year
      }-${data.make}-${data.model}`;
    } else {
      path = `/${reportType}/-${data.vin}-${data.year}-${data.make}-${data.model}`;
    }

    window.open(toSeoUrl(path), "_blank");
  };

  const handleMoveToPending = async (id) => {
    const vehicles = dismissedVehicles?.dataRef?.current?.data ?? [];
    const data = vehicles.find((x) => x.id === id);

    if (data == null) {
      return;
    }

    await handleUpdateStatus(
      {
        ...data,
        status: STATUSES.PENDING.status,
      },
      dismissedVehicles,
      pendingVehicles
    );
  };

  const handleMoveToCompleted = async (id) => {
    const vehicles = pendingVehicles?.dataRef?.current?.data ?? [];
    const data = vehicles.find((x) => x.id === id);

    if (data == null) {
      return;
    }

    await handleUpdateStatus(
      {
        ...data,
        status: STATUSES.COMPLETED.status,
      },
      pendingVehicles,
      completedVehicles
    );

    mutateVehicleRatingsStatistics();
    mutateRatingsTrendStatistics();
  };

  const handleMoveToDismissed = async (id) => {
    const vehicles = pendingVehicles?.dataRef?.current?.data ?? [];
    const data = vehicles.find((x) => x.id === id);

    if (data == null) {
      return;
    }

    await handleUpdateStatus(
      {
        ...data,
        status: STATUSES.DISMISSED.status,
      },
      pendingVehicles,
      dismissedVehicles
    );
  };

  const handleUpdateStatus = async (
    data,
    currentVehicleArg,
    nextVehicleArg
  ) => {
    try {
      const currentData = currentVehicleArg?.dataRef?.current ?? {};
      const nextData = nextVehicleArg?.dataRef?.current ?? {};

      currentVehicleArg.mutate(
        {
          ...currentData,
          data: (currentData?.data ?? {}).filter((x) => x.id !== data.id),
        },
        false
      );

      nextVehicleArg.mutate(
        {
          ...nextData,
          data: [data, ...(nextData?.data ?? {})],
        },
        false
      );

      await ApiService.put(props.endpoints.updateVehicle, {
        id: data.id,
        status: data.status,
      });

      currentVehicleArg.mutate();
      nextVehicleArg.mutate();

      toast({
        title: "You have successfuly changed the vehicle status",
        status: "success",
      });
    } catch (error) {
      toast({ title: error.message, status: "error" });
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        completedVehicles,
        pendingVehicles,
        dismissedVehicles,
        vehicleRatingsStatistics,
        ratingsTrendStatistics,
        handleOpenReport,
        handleMoveToCompleted,
        handleMoveToPending,
        handleMoveToDismissed,
        inventoryIsLoading,
        lots,
        zones,
        lot_id,
        setLotId,
        handleCreateZone,
        handleUpdateZone,
        handleDeleteZone,
        handleUpdateZoneOrder,
        devices,
        handleUpdateDevice,
        zonesStats,
        inventoryList,
        handleSearchInventory,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

const DashboardContext = createContext({});
const useDashboardContext = () => useContext(DashboardContext);

export { DashboardContextProvider, DashboardContext, useDashboardContext };
