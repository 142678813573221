import React, { useMemo } from "react";
import { Box, Skeleton, Text } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

import { getYellowToGreenColor, getMonthName } from "utils";

import { VehicleRatingTooltip } from "components/Tooltips/VehicleRatingTooltip";

const AverageScoreVehiclesChart = (props) => {
  const data = useMemo(() => {
    if (props.data == null) {
      return null;
    }

    if (props.data.length === 0) {
      return [];
    }

    const lastMonth = new Date().getMonth() + 1; // getMonth is between 0-11
    const firstMonth = lastMonth > 6 ? lastMonth - 6 : 1;

    const statistics = [];

    for (let i = firstMonth; i <= lastMonth; i++) {
      const value = props.data.find((x) => x.date_part === i);

      statistics.push({
        x: i,
        y: Number(value?.avg) * 100,
      });
    }

    return statistics;
  }, [props.data]);

  return (
    <Box
      backgroundColor="white"
      py="3rem"
      px="2rem"
      textAlign="center"
      borderRadius="1rem"
    >
      <Text color="gray.900" fontWeight="bold" fontSize="1.4rem">
        Ratings Trend
      </Text>

      <Box mt="2rem" height="30rem" {...props.containerProps}>
        {data == null ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={40}
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: -10,
                bottom: -10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="x" tickFormatter={getMonthName} />
              <YAxis tickFormatter={(value) => `${value}%`} />
              <Tooltip content={<VehicleRatingTooltip isMonthly />} />
              <Line connectNulls dataKey="y">
                {data.map((data) => (
                  <Cell
                    key={data.x}
                    fill={getYellowToGreenColor(Number(data.y) * 100)}
                  />
                ))}
              </Line>
            </LineChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
};

AverageScoreVehiclesChart.defaultProps = {
  containerProps: {},
};

export { AverageScoreVehiclesChart };
