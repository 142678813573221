import { API } from "aws-amplify";

import { amplifyConfig } from "../config";

const apiName = amplifyConfig.API.endpoints[0].name;

export const post = async (path, payload) => {
  try {
    const response = await API.post(apiName, path, {
      body: payload,
    });
    // .then(response => response).catch(error => console.log(error.response.data));
    return response;
  } catch (error) {
    // Access the error response body here
    if (error.response && error.response.data) {
      if (error.response.data?.message) {
        throw new Error(error.response.data?.message);
      } else {
        // Handle the response body of the error
        throw new Error(error.response.data);
      }
    } else {
      // Handle other types of errors
      throw error;
    }
  }
};

export const get = (path) => {
  return API.get(apiName, path, {});
};

export const put = (path, payload) => {
  return API.put(apiName, path, {
    body: payload,
  });
};

export const del = (path, payload) => {
  return API.del(apiName, path, {
    body: payload,
  });
};
