import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Grid,
  GridItem,
  VStack,
  Text,
  useMediaQuery,
  Container,
} from "@chakra-ui/react";
import { Redirect, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode";
import { Helmet } from "react-helmet";

import { LoadingLayout } from "components/Layouts/LoadingLayout";

import EvFooter from "components/new_report/EvFooter";
import EvheaderScreenshot from "components/new_report_screenshot/EvheaderScreenshot";
import CarHeatMapDetails from "components/new_report_screenshot/CarHeatMapDetailsScreenshot";
import CarHeatMap from "components/new_report_screenshot/CarHeatMapScreenshot";
import CellVoltage from "components/new_report/mygraphs/CellVoltage";

import StateOfHealth from "components/new_report/mygraphs/StateOfHealth";
import GaugeChartMain from "components/new_report/mygraphs/gaugeChart";
import PieChart from "components/new_report/mygraphs/PieChart";
import BatteryPackDamageRisk from "components/new_report/mygraphs/BatteryPackDamageRisk";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
import navRedSvg from "assets/new_report/navbar-right-red.svg";
import navGreenSvg from "assets/new_report/navbar-right-green.svg";

import * as Styles from "./Report.styles";
import _ from "lodash";

export default function ReportEV() {
  const [isSmallScreen] = useMediaQuery("(max-width: 769px)");
  const [qrCode, setQrCode] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const [printMode, setPrintMode] = useState(false);

  const componentRef = useRef();
  const containerRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrintMode(true);
    },
    onAfterPrint: () => {
      setPrintMode(false);
    },
    onPrintError: (error) => {
      console.log(error);
    },
  });

  const getReport = useCallback((id, vin) => {
    return fetch(
      `${process.env.REACT_APP_AWS_PUBLIC_API_ENDPOINT}/getEV?id=${id}&vin=${vin}`
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });
  }, []);

  useEffect(async () => {
    const id = params.reportId?.split("-")?.[0]?.toUpperCase();
    const vin = params.reportId?.split("-")?.[1]?.toUpperCase();

    if (vin == null || id == null) {
      setLoading(false);
      return;
    }

    try {
      await getReport(id, vin);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (printMode) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(1000);
      handlePrint();
    }
  }, [printMode]);

  useEffect(() => {
    QRCode.toDataURL(window.location.href).then((qrCodeDataUrl) => {
      setQrCode(qrCodeDataUrl);
    });
  }, []);

  const vehicleData = data?.data;

  const [bgImage, setBgImage] = useState(navGreenSvg);
  const [bgColor, setBgColor] = useState("green");
  const [badBattery, setBadBattery] = useState(false);

  useEffect(() => {
    if (vehicleData?.brick_status) {
      vehicleData?.brick_status?.map((item) => {
        if (item >= 8 && item <= 100000) {
          setBgImage(navRedSvg);
          setBgColor("red");
          setBadBattery(true);
        }
      });
    }
  }, [vehicleData?.brick_status]);

  if (loading) {
    return <LoadingLayout />;
  }

  if (vehicleData == null) {
    return <Redirect to="/battery" />;
  }

  return (
    <Container
      id={"screenshot-report-container"}
      maxWidth={"100%"}
      padding={0}
      width={1550}
    >
      <Helmet
        title={`vsNew - ${vehicleData?.year} ${vehicleData?.make} ${vehicleData?.model} Battery Health Report`}
      />

      <React.Fragment>
        <Box
          backgroundColor="white"
          padding="1rem 1rem 0rem 1rem"
          // margin="1.5rem 0rem 1.5rem 0rem"
        >
          <Grid templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={2}>
              <Grid templateColumns="repeat(2, 1fr)">
                <GridItem colSpan={2}>
                  <EvheaderScreenshot
                    vehicleTypeTitle={_.upperCase(vehicleData?.vehicle_type)}
                    vehicleData={vehicleData}
                    tooltipDescription={
                      "The vsNew overall score is based on an analysis of hundreds of metrics, including range, battery capacity, charging history, and replacement risk. An equivalent new battery would score 100%."
                    }
                  />
                </GridItem>

                <GridItem>
                  <CarHeatMapDetails vehicleData={vehicleData} />
                </GridItem>

                <GridItem pl={10}>
                  <Box
                    display={"flex"}
                    mt={20}
                    py={5}
                    bgColor={bgColor}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize="3xl"
                      as="b"
                      color={"#fff"}
                      alignSelf={"center"}
                      fontFamily={"var(--chakra-fonts-body);"}
                    >
                      Overall <br />
                      Battery <br />
                      Score
                    </Text>

                    <Text
                      fontSize="100px"
                      as="b"
                      color={"#fff"}
                      alignSelf={"center"}
                      ml={"40px"}
                    >
                      {!badBattery &&
                      vehicleData?.make === "Tesla" &&
                      parseFloat(vehicleData?.test_soc) * 100 < 50
                        ? "--"
                        : Math.round(vehicleData?.score * 100)}
                      %
                    </Text>
                  </Box>
                </GridItem>

                <GridItem colSpan={2}>
                  {vehicleData?.brick_voltage &&
                    vehicleData?.brick_voltage?.length > 4 && (
                      <CarHeatMap
                        vehicleData={vehicleData}
                        tooltipDescription={
                          "EV batteries are typically made up of 96 modules, each containing a collection of individual cells. We evaluate each module of the EV battery and determine voltage balance and potential degradation. The test determines each module's condition as health, fair, or failing. While some imbalance is normal, especially after multiple shallow charges cycles or extended time without charging or usage, we are focused on individual modules that are statistically significantly lower than others."
                        }
                      />
                    )}

                  {vehicleData?.brick_voltage &&
                    vehicleData?.brick_voltage?.length <= 4 && (
                      <CellVoltage
                        vehicleData={vehicleData}
                        tooltipDescription={
                          "EV batteries are typically made up of 96 modules, each containing a collection of individual cells. We evaluate each module of the EV battery and determine voltage balance and potential degradation. The test determines each module's condition as health, fair, or failing. While some imbalance is normal, especially after multiple shallow charges cycles or extended time without charging or usage, we are focused on individual modules that are statistically significantly lower than others."
                        }
                        brick_status_disable={true}
                      />
                    )}
                </GridItem>
              </Grid>
            </GridItem>

            <GridItem rowSpan={2}>
              <VStack spacing={8} mt="30px">
                {badBattery && (
                  <div
                    style={{
                      display: "flex",
                      padding: 8,
                      border: "5px red solid",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Alert - Serious Battery Issue
                    </p>
                    <Tooltip
                      label={
                        "This battery has a high likelihood of 1 or more failed cells, which may severely limit its charge limit and remaining battery life. It should be further evaluated by the manufacturer or a trained professional."
                      }
                    >
                      <Icon
                        icon={infoFill}
                        style={{ color: "black", width: 16, height: 16 }}
                      />
                    </Tooltip>
                  </div>
                )}
                {!badBattery &&
                  vehicleData?.make === "Tesla" &&
                  parseFloat(vehicleData?.test_soc) * 100 < 50 && (
                    <div
                      style={{
                        display: "flex",
                        padding: 8,
                        border: "5px yellow solid",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Alert - Incomplete Report
                      </p>
                      <Tooltip
                        label={
                          "This test was conducted at a charge level below 50%, which does not allow vsNEW to make a conclusive determination of the overall score of this battery. Please charge the vehicle and re-test."
                        }
                      >
                        <Icon
                          icon={infoFill}
                          style={{ color: "black", width: 16, height: 16 }}
                        />
                      </Tooltip>
                    </div>
                  )}

                {vehicleData?.vehicle_type == "ev" && (
                  <GaugeChartMain
                    width={260}
                    value={(
                      (parseFloat(vehicleData?.range_now) /
                        parseFloat(vehicleData?.range_new)) *
                      100
                    ).toFixed()}
                    rangeNow={parseInt(vehicleData?.range_now)}
                    range={parseInt(vehicleData?.range_new)}
                    rangeText={
                      vehicleData?.distance_unit == "km" ? "km" : "Miles"
                    }
                    heading={"MAX RANGE"}
                    tooltipDescription={
                      "Maximum range is the distance a vehicle can travel from 100% state of charge to full depletion in ideal conditions. This measure is comparable to EPA range, but will differ from the range displayed by the vehicle. Actual range varies with driving style, highway vs city miles, elevation gain, ambient temperature, and more."
                    }
                    range_now_city={vehicleData?.range_now_city ?? 0}
                    range_now_highway={vehicleData?.range_now_highway ?? 0}
                  />
                )}

                {vehicleData?.soh && vehicleData?.soh <= 1 && (
                  <StateOfHealth
                    width={260}
                    value={(parseFloat(vehicleData?.soh) * 100).toFixed()}
                    rangeText={"%"}
                    heading={"STATE OF HEALTH"}
                    tooltipDescription={
                      "The State of Health shown here is reported directly from the manufacturer battery management system (BMS) or calculated based on the BMS report of the battery’s current capacity or similar measure."
                    }
                  />
                )}

                {vehicleData?.vehicle_type == "ev" && (
                  <PieChart
                    vehicleData={vehicleData}
                    risk={parseFloat(vehicleData?.replacement_risk)}
                    width={180}
                    heading={"PREMATURE CELL FAILURE RISK"}
                    tooltipDescription={
                      "vsNew's Premature Failure Risk predicts the likelihood of a premature battery failure in the next 12 months. We focus on 2 key risks - cell voltage imbalances and cell temperature imbalances. In these cases, the vehicle's Battery Management System (BMS) will severely limit the range of the vehicle, regardless of the overall battery condition. Overall battery degradation is not a part of this analysis."
                    }
                    styles={{ marginTop: -30 }}
                  />
                )}

                {vehicleData?.vehicle_type == "ev" && (
                  <BatteryPackDamageRisk
                    risk={parseInt(vehicleData?.damage_risk ?? 0)}
                    width={180}
                    heading={"BATTERY PACK DAMAGE RISK"}
                    tooltipDescription={
                      "OEMs place temperature sensors throughout the battery pack to protect against battery damage. Large variations or imbalance in these measurements can act as an early warning of battery failure."
                    }
                  />
                )}

                {vehicleData?.vehicle_type == "hybrid" && (
                  <PieChart
                    vehicleData={vehicleData}
                    risk={
                      Math.max(
                        -0.349 * parseFloat(vehicleData?.soh) + 0.2875,
                        0
                      ) / 0.2875
                    }
                    width={180}
                    heading={"MPG LOSS"}
                    tooltipDescription={
                      "As hybrid batteries decline, they become less effective at substituting battery power for engine power. According to a study by Department of Mechanical and Aerospace Engineering (DIMEAS) and others, hybrid vehicles with a low SOH (state of health) had a MPG decrease of up to 25%. We estimate the impact to your vehicle's MPG based on your vehicle's SOH."
                    }
                  />
                )}

                {vehicleData?.vehicle_type == "hybrid" && (
                  <PieChart
                    vehicleData={vehicleData}
                    risk={
                      Math.max(
                        -0.515 * parseFloat(vehicleData?.soh) + 0.4435,
                        0
                      ) / 0.4435
                    }
                    width={180}
                    heading={"ACCELERATION LOSS"}
                    tooltipDescription={
                      "As hybrid batteries decline, they become less effective at supporting vehicle acceleration. According to a study by Department of Mechanical and Aerospace Engineering (DIMEAS) and others, hybrid vehicles with a low SOH (state of health) had an acceleration decrease of up to 35.7%. We estimate the impact to your vehicle's acceleration based on your vehicle's SOH."
                    }
                  />
                )}

                {vehicleData?.vehicle_type == "hybrid" && (
                  <PieChart
                    vehicleData={vehicleData}
                    risk={parseFloat(vehicleData?.replacement_risk)}
                    width={180}
                    heading={"PREMATURE FAILURE RISK"}
                    tooltipDescription={
                      "vsNew's Premature Failure Risk predicts the likelihood of a premature battery failure in the next 12 months. We focus on 2 key risks - cell voltage imbalances and cell temperature imbalances. In these cases, the vehicle's Battery Management System (BMS) will severely limit the range of the vehicle, regardless of the overall battery condition. Overall battery degradation is not a part of this analysis."
                    }
                  />
                )}
              </VStack>
            </GridItem>
          </Grid>

          <EvFooter
            vehicleTypeTitle={_.upperCase(vehicleData?.vehicle_type)}
            qrCode={qrCode}
            vehicleData={vehicleData}
            hideGradient={true}
          />
        </Box>
      </React.Fragment>
    </Container>
  );
}
