import * as React from "react";
import {
  Text,
  Box,
  Switch,
  IconButton,
  FormControl,
  FormLabel,
  Checkbox,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import PaymentCard from "react-payment-card-component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "./SetupForm";

import { InputController } from "../../Controllers/InputController";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CHECKOUT_KEY);

const AddPaymentMethodForm = (props) => {
  return (
    <>
      {/* <Text>Update Payment Method</Text> */}
      {/*<FormControl display="flex" alignItems="center" mb="10">
        <Checkbox
          isChecked={props.user?.payment_type == "paid" ? true : false}
          size="lg"
          onChange={props.onChange}
          style={{ borderColor: "black" }}
        >
          I agree
        </Checkbox>
      </FormControl> */}
      {/* {props?.paymentMethods?.map((item, index) => (
        <Box
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            // position: "relative",
            // float: "left",
          }}
        >
          <PaymentCard
            // bank="itau"
            model="personnalite"
            // type="gold"
            brand={item?.card?.brand}
            number={item?.card?.last4}
            // cvv="202"
            holderName=""
            expiration={item?.card?.exp_month + "/" + item?.card?.exp_year}
            flipped={false}
          />
          <IconButton
            style={{
              alignSelf: "flex-start",
              position: "relative",
              left: "-50px",
              top: "15px",
            }}
            // variant="ghost"
            size={"lg"}
            icon={<DeleteIcon />}
            onClick={() => props.removePaymentMethod(item?.id)}
          />
        </Box>
      ))} */}
      {/* {!props?.paymentMethods?.length && props?.user?.stripe_payment_method && ( */}
      <Elements stripe={stripePromise}>
        <SetupForm attachPaymentMethod={props.attachPaymentMethod} />
      </Elements>
      {/* )} */}
    </>
  );
};

export { AddPaymentMethodForm };
