import React from "react";
import Chart from "react-apexcharts";
import "./graph.scss";

function Heatmap({ vehicleData, styles = {} }) {
  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      heatmap: {
        reverseNegativeShade: true,
        colorScale: {
          ranges: [
            { from: -100000, to: -1, name: "Replaced", color: "#08724e" },
            { from: 0, to: 3, name: "Balanced", color: "#51a986" },
            { from: 4, to: 7, name: "Fair", color: "#feca00" },
            { from: 8, to: 100000, name: "Failing", color: "#ff0404" },
          ],
        },
      },
    },
    dataLabels: { enabled: false },
  };

  const splitToChunks = (array, parts) => {
    let newArr = [...array];
    let result = [];
    for (let i = parts; i > 0; i--) {
      result.push({
        name: "i",
        data: newArr.splice(0, Math.ceil(newArr.length / i)),
      });
    }
    return result;
  };

  let chunkSize = vehicleData?.brick_status?.length / 2;
  if (
    vehicleData?.brick_status?.length >= 32 &&
    vehicleData?.brick_status?.length <= 64
  ) {
    chunkSize = vehicleData?.brick_status?.length / 4;
  } else if (vehicleData?.brick_status?.length >= 64) {
    chunkSize = vehicleData?.brick_status?.length / 8;
  }

  const arr = vehicleData?.brick_status
    ? [...vehicleData?.brick_status]?.splice(
        0,
        vehicleData?.brick_status?.length
      )
    : [];

  const getHealth = () => {
    let healthyBrick = arr.filter((val) => val < 4).length;
    return ((healthyBrick / arr.length) * 100).toFixed();
  };

  return vehicleData?.brick_status?.length ? (
    <div className="heatMap">
      <div className="heatMapTransform">
        <Chart
          {...styles}
          options={options}
          series={splitToChunks([...arr], chunkSize)}
          type="heatmap"
        />
      </div>
      <h2>{getHealth()}% BALANCED</h2>
    </div>
  ) : null;
}

export default Heatmap;
