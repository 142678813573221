import React, { memo } from "react";
import "./mygraphs/graph.scss";
import moment from "moment";

function HybridFooterMemorized({ vehicleTypeTitle, qrCode, vehicleData }) {

  return (
    <>
      <div className="gradient_line"></div>
      <div className="footer_container">
        <div className="info-footer">
          <div className="test_details">
            <p>
            *This report, and any reliance upon it, is subject to our terms of service <a href="https://www.vsnew.com/terms" style={{color: "blue"}} rel="noreferrer" target={"_blank"}>(vsnew.com/terms)</a>. Warranty may be extended in select states, including AZ, CA, CT, ME, MD, MA, NJ, NY, OR, RI, VT. Copyright <a href="https://www.vsnew.com/" style={{color: "blue"}} rel="noreferrer" target={"_blank"}>vsNew, Inc.</a> Patents pending.
            {vehicleData?.device && vehicleData?.firmware_version && vehicleData?.app_version &&<><br/><br/>
              {vehicleData?.device} {vehicleData?.firmware_version} {vehicleData?.app_version} {vehicleData?.ev_battery_test_id}</>}
            </p>
          </div>
         
            
          <div style={{ display: "flex", justifyContent: 'space-between' }}>  
            <p  style={{ display: "flex", paddingRight: "10px", overflowWrap: 'break-word'}}>Scan here for more details on your {vehicleTypeTitle} battery health.
              <br></br>
              {process.env.REACT_APP_URL}/{vehicleTypeTitle?.toLowerCase()}/{vehicleData?.id}-{vehicleData?.vin}-{vehicleData?.year}-{vehicleData?.make}-{vehicleData?.model}
            </p>
            <embed width={64} src={qrCode} alt="" />
          </div>

           
        </div>
      </div>
    </>
  );
}

function addZeroes(num) {
  return num.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 1})
}

const HybridFooter = memo(HybridFooterMemorized);
export default HybridFooter;
