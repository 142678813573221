import React, { useMemo } from "react";
import useSWR from "swr";

import { VehicleTestsModal } from "components/Modals/VehicleTestsModal";
import { formatDate } from "utils";
import { orderBy } from "lodash-es";

const getStepUnit = (step) => {
  switch (step) {
    case 5:
      return {
        name: "DTC",
        unit: "Miles",
      };
    case 10:
      return {
        name: "Battery",
        unit: "Volts",
      };
    case 20:
      return {
        name: "Acceleration",
        unit: "Sec",
      };
    case 30:
      return {
        name: "MPG",
        unit: "Mpg",
      };
    case 40:
      return {
        name: "Braking",
        unit: "Feet",
      };
    case 50:
      return {
        name: "Transmission",
        unit: "Sec",
      };
    default:
      return {
        name: null,
        unit: null,
      };
  }
};
const VehicleTestsModalContainer = (props) => {
  const { data } = useSWR(`/getVehicleTests?vin=${props.vin}`);

  const columns = useMemo(() => {
    return [
      {
        Header: "Test",
        accessor: "raw_units",
        Cell: ({ cell: { value }, row: { original: values } }) => {
          const unit = getStepUnit(values.step);
          return unit.name ?? value;
        },
      },
      {
        Header: "Date",
        accessor: "test_timestamp",
        Cell: ({ cell: { value } }) => {
          return formatDate(value);
        },
      },
      {
        Header: "Temperature",
        accessor: "test_temp_f",
        Cell: ({ cell: { value } }) => {
          if (value == null) {
            return null;
          }

          return `${Number(value).toFixed(2)} °F`;
        },
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Result",
        accessor: "raw_result",
        Cell: ({ cell, row: { original: values } }) => {
          let value = cell.value;

          if (value == null) {
            return null;
          }

          if (values.step === 5) {
            // km to miles
            value = Number(value) * 0.621371;
          }

          const unit = getStepUnit(values.step);
          const unitName = unit.unit ?? "";
          const unitValue = parseFloat(Number(value).toFixed(2));

          return `${unitValue} ${unitName}`.trim();
        },
      },
    ];
  }, []);

  const sortedData = useMemo(() => {
    if (data == null) {
      return data;
    }

    return {
      data: orderBy(data.data, ["step", "test_timestamp"], ["asc", "desc"]),
    };
  }, [data]);

  return (
    <VehicleTestsModal
      isOpen={props.isOpen}
      columns={columns}
      data={sortedData}
      onClose={props.onClose}
    />
  );
};

export { VehicleTestsModalContainer };
