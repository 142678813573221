import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
import ReactApexChart from "react-apexcharts";

export default function CellResistance({
  vehicleData,
  tooltipDescription = "",
}) {
  // const cell_internal_resistance = [
  //   0.26, 0.24, 0.41, 0.2, 0.18, 0.18, 0.26, 0.42, 0.26, 0.24, 0.41, 0.2, 0.18,
  //   0.18, 0.26, 0.42,
  // ];
  const { cell_internal_resistance } = vehicleData;
  const colors = ["#267ec3", "#ff0000", "#51a986"];
  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 4, 4],
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "35%",
        distributed: true,
        colors: {
          ranges: [
            { from: 0, to: 0.2, color: "#51a986" },
            { from: 0.2, to: 0.4, color: "#feca00" },
            { from: 0.4, to: 100000, color: "#ff0404" },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      min: 0.0,
      max: 0.04,
      title: {
        text: "Resistance in Ohms",
      },
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      stepSize: 0.005,
      decimalsInFloat: 3,
    },
    xaxis: {
      labels: {
        show: false,
      },
      title: {
        text: "Brick",
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} ohms`,
      },
    },
    responsive: [
      {
        breakpoint: 420,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
  };

  return (
    <div className="car_heatmap">
      <div className="heatmap_heading">
        <h2 style={{ display: "flex", justifyContent: "center" }}>
          CELL RESISTANCE{" "}
          <Tooltip label={tooltipDescription}>
            <Icon
              icon={infoFill}
              style={{ color: "black", width: 16, height: 16 }}
            />
          </Tooltip>
        </h2>
      </div>
      <div className="heatmap_container">
        <ReactApexChart
          type="line"
          series={[
            {
              name: "Cell Resistance",
              type: "bar",
              data: cell_internal_resistance,
            },
            {
              name: "Expected End of Life",
              type: "line",
              data: [...Array(cell_internal_resistance?.length)].map(
                (i, index) => 0.03
              ),
            },
            // {
            //   name: "",
            //   type: "line",
            //   data: [...Array(cell_internal_resistance?.length)].map((i, index) => 0.2),
            // },
          ]}
          options={chartOptions}
          width={600}
          height={450}
        />
      </div>
    </div>
  );
}
