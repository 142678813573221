import React, { memo, useEffect, useState } from "react";
import { Grid, GridItem, VStack } from "@chakra-ui/react";
import GaugeChartMain from "../new_report/mygraphs/gaugeChart";
import PieChart from "../new_report/mygraphs/PieChart";
import FastChargingHistory from "../new_report/mygraphs/FastChargingHistory";
import CarHeatMap from "./CarHeatMap";
import CarHeatMapDetails from "../new_report/CarHeatMapDetails";
import USNormalRangeMap from "../new_report/mygraphs/USNormalRangeMap";
import CellVoltage from "components/new_report/mygraphs/CellVoltage";
import CellTemperatureImbalance from "components/new_report/mygraphs/CellTemperatureImbalance";
import StateOfHealth from "components/new_report/mygraphs/StateOfHealth";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
import "./mygraphs/graph.scss";
import CellVoltageHistogram from "components/new_report/mygraphs/CellVoltageHistogram";

function BatteryDetailsMemorized({ vehicleData }) {
  const { brick_status } = vehicleData;
  const [badBattery, setBadBattery] = useState(false);

  useEffect(() => {
    if (brick_status) {
      brick_status?.map((item) => {
        if (item >= 8 && item <= 100000) {
          setBadBattery(true);
        }
      });
    }
  }, [brick_status]);
  return (
    <Grid gap={6}>
      <CarHeatMapDetails vehicleData={vehicleData} />

      <GridItem colSpan={1} w="100%">
        <VStack spacing={8} mt="30px">
          {badBattery && (
            <div
              style={{ display: "flex", padding: 8, border: "5px red solid" }}
            >
              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                Alert - Serious Battery Issue
              </p>
              <Tooltip
                label={
                  "This battery has a high likelihood of 1 or more failed cells, which may severely limit its charge limit and remaining battery life. It should be further evaluated by the manufacturer or a trained professional."
                }
              >
                <Icon
                  icon={infoFill}
                  style={{ color: "black", width: 16, height: 16 }}
                />
              </Tooltip>
            </div>
          )}
          {!badBattery &&
            vehicleData?.make === "Tesla" &&
            parseFloat(vehicleData?.test_soc) * 100 < 50 && (
              <div
                style={{
                  display: "flex",
                  padding: 8,
                  border: "5px yellow solid",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Alert - Incomplete Report
                </p>
                <Tooltip
                  label={
                    "This test was conducted at a charge level below 50%, which does not allow vsNEW to make a conclusive determination of the overall score of this battery. Please charge the vehicle and re-test."
                  }
                >
                  <Icon
                    icon={infoFill}
                    style={{ color: "black", width: 16, height: 16 }}
                  />
                </Tooltip>
              </div>
            )}
          {vehicleData?.soh && vehicleData?.soh <= 1 && (
            <StateOfHealth
              width={260}
              value={(parseFloat(vehicleData?.soh) * 100).toFixed()}
              rangeText={"%"}
              heading={"STATE OF HEALTH"}
              tooltipDescription={
                "The State of Health shown here is reported directly from the manufacturer battery management system (BMS) or calculated based on the BMS report of the battery’s current capacity or similar measure."
              }
            />
          )}

          <GaugeChartMain
            width={260}
            value={(
              (parseInt(vehicleData?.range_now) /
                parseInt(vehicleData?.range_new)) *
              100
            ).toFixed()}
            rangeNow={parseInt(vehicleData?.range_now)}
            range={parseInt(vehicleData?.range_new)}
            rangeText={vehicleData?.distance_unit == "km" ? "km" : "Miles"}
            heading={"MAX RANGE"}
            tooltipDescription={
              "Maximum range is the distance a vehicle can travel from 100% state of charge to full depletion in ideal conditions. This measure is comparable to EPA range, but will differ from the range displayed by the vehicle. Actual range varies with driving style, highway vs city miles, elevation gain, ambient temperature, and more."
            }
          />

          <GaugeChartMain
            width={260}
            value={(
              (parseFloat(vehicleData?.battery_now) /
                parseFloat(vehicleData?.battery_new_actual)) *
              100
            ).toFixed()}
            rangeNow={parseFloat(vehicleData?.battery_now).toFixed(1)}
            range={parseFloat(vehicleData?.battery_new_actual).toFixed(1)}
            rangeText={"kWh"}
            heading={"TOTAL CAPACITY"}
            tooltipDescription={
              "Total capacity is the battery’s total current maximum charge capacity. Over time, multiple factors impact battery degradation – time, mileage, charge cycles, ambient temperature, vehicle usage, and more."
            }
          />

          <PieChart
            vehicleData={vehicleData}
            risk={parseFloat(vehicleData?.replacement_risk)}
            width={180}
            heading={"PREMATURE FAILURE RISK"}
            tooltipDescription={
              "vsNew's Premature Failure Risk predicts the likelihood of a premature battery failure in the next 12 months. We focus on 2 key risks - cell voltage imbalances and cell temperature imbalances. In these cases, the vehicle's Battery Management System (BMS) will severely limit the range of the vehicle, regardless of the overall battery condition. Overall battery degradation is not a part of this analysis."
            }
          />

          {vehicleData?.dc_charging && vehicleData?.ac_charging && (
            <FastChargingHistory
              risk={parseInt(
                (parseFloat(vehicleData?.dc_charging) /
                  (parseFloat(vehicleData?.ac_charging) +
                    parseFloat(vehicleData?.dc_charging))) *
                  100
              )}
              width={260}
              heading={"FAST CHARGING HISTORY"}
              tooltipDescription={
                "OEMs recommend limiting the amount of DC fast charging to promote battery longevity. We determine the % of Fast Charging by taking the number of fast charging cycles divided by total charging cycles, or cumulative kWh charged with DC fast charging divided by the total kWh charged."
              }
            />
          )}

          <CellTemperatureImbalance
            risk={parseInt(vehicleData?.temp_balance ?? 0)}
            width={260}
            heading={"CELL TEMPERATURE IMBALANCE"}
            tooltipDescription={
              "OEMs place temperature sensors throughout the battery pack to protect against battery damage. Large variations or imbalance in these measurements can act as an early warning of battery failure."
            }
          />
        </VStack>
      </GridItem>

      {vehicleData?.brick_voltage && vehicleData?.brick_voltage?.length > 4 && (
        <CarHeatMap
          vehicleData={vehicleData}
          tooltipDescription={
            "EV batteries are typically made up of 96 modules, each containing a collection of individual cells. We evaluate each module of the EV battery and determine voltage balance and potential degradation. The test determines each module's condition as health, fair, or failing. While some imbalance is normal, especially after multiple shallow charges cycles or extended time without charging or usage, we are focused on individual modules that are statistically significantly lower than others."
          }
        />
      )}
      {vehicleData?.brick_voltage && (
        <CellVoltage
          vehicleData={vehicleData}
          tooltipDescription={
            "EV batteries are typically made up of 96 modules, each containing a collection of individual cells. We evaluate each module of the EV battery and determine voltage balance and potential degradation. The test determines each module's condition as health, fair, or failing. While some imbalance is normal, especially after multiple shallow charges cycles or extended time without charging or usage, we are focused on individual modules that are statistically significantly lower than others."
          }
        />
      )}
      {vehicleData?.brick_voltage && (
        <CellVoltageHistogram
          vehicleData={vehicleData}
          tooltipDescription={
            "EV batteries are typically made up of 96 modules, each containing a collection of individual cells. We evaluate each module of the EV battery and determine voltage balance and potential degradation. The test determines each module's condition as health, fair, or failing. While some imbalance is normal, especially after multiple shallow charges cycles or extended time without charging or usage, we are focused on individual modules that are statistically significantly lower than others."
          }
        />
      )}
      {vehicleData?.distance_unit == "mi" && (
        <USNormalRangeMap
          vehicleData={vehicleData}
          tooltipDescription={
            "To optimize for battery longevity, OEMs recommend that the battery state of charge stay between 80% and 20%. The map below shows your current predicted range during both summer and winter seasons within the 80% to 20% charge levels, using data from vsNew, the Norwegian Automobile Federation, and vsNew estimates.  Actual ranges will vary based on other factors."
          }
        />
      )}
    </Grid>
  );
}

const BatteryDetails = memo(BatteryDetailsMemorized);
export default BatteryDetails;
