import { Redirect, Route } from "react-router-dom";

import { useUserContext } from "../context";
import { LoadingLayout } from "../components/Layouts/LoadingLayout";
import useQuery from "hooks/useQuery";

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user, isLoading } = useUserContext();
  let query = useQuery();

  console.log("query: ", query.get("redirection"));

  if (isLoading) {
    return <LoadingLayout />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user == null && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${decodeURI(query.get("redirection") ?? "/") ?? "/"}`,
            }}
          />
        )
      }
    />
  );
};

export { PublicRoute };
