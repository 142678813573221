import React from "react";
import { Box } from "@chakra-ui/react";

import { useUserContext } from "context/UserContext";

import { PageLayout } from "components/Layouts/PageLayout";
import { ContentLayout } from "components/Layouts/ContentLayout";
import { ChangeAccountInfoForm } from "components/Forms/ChangeAccountInfoForm";
import { ChangePasswordForm } from "components/Forms/ChangePasswordForm";
import { AddPaymentMethodForm } from "components/Forms/AddPaymentMethodForm";
import { DeleteAccountForm } from "components/Forms/DeleteAccountForm";

const Settings = () => {
  const {
    user,
    paymentMethods,
    changePassword,
    changeAccountInformation,
    deleteUser,
    updateUserPaymentType,
    removePaymentMethod,
    attachPaymentMethod,
  } = useUserContext();

  // console.log({ user });

  return (
    <PageLayout title="Settings">
      <ContentLayout title="Settings">
        <Box bg="white" p="3.2rem" borderRadius="1.6rem">
          <Box maxWidth={["100%", "50%", "40%"]}>
            <ChangeAccountInfoForm
              onSubmit={changeAccountInformation}
              user={user}
            />
          </Box>
        </Box>

        <Box bg="white" mt="3rem" p="3.2rem" borderRadius="1.6rem">
          <Box maxWidth={["100%", "50%", "40%"]}>
            <ChangePasswordForm onSubmit={changePassword} />
          </Box>
        </Box>

        <Box bg="white" mt="3rem" p="3.2rem" borderRadius="1.6rem">
          <Box maxWidth={["100%", "50%", "40%"]}>
            <AddPaymentMethodForm
              user={user}
              paymentMethods={paymentMethods}
              removePaymentMethod={removePaymentMethod}
              attachPaymentMethod={attachPaymentMethod}
              onChange={updateUserPaymentType}
            />
          </Box>
        </Box>

        <Box bg="white" mt="3rem" p="3.2rem" borderRadius="1.6rem">
          <Box maxWidth={["100%", "50%", "40%"]}>
            <DeleteAccountForm onSubmit={deleteUser} />
          </Box>
        </Box>
      </ContentLayout>
    </PageLayout>
  );
};

export { Settings };
