import React, { useMemo } from "react";
import {
  IconButton,
  Image,
  ButtonGroup,
  Box,
  Flex,
  Text,
  Select,
  // Switch,
  FormControl,
  FormLabel,
  Skeleton,
} from "@chakra-ui/react";

import { add3Dots, formatDate, getScore } from "utils";

import {
  // Box,
  Chip as MuiChip,
  Card as MuiCard,
  CardHeader,
  CardContent,
  // IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  Switch,
} from "@mui/material";
import {
  EnhancedTableHead,
  TableSkeleton,
  TableToolbar,
} from "../components/Tables/MuiTable";
// import { SimpleTable } from "../components/Tables/SimpleTable";

import createTheme from "MuiTheme";
import { ThemeProvider, styled } from "@mui/material/styles";

import { PrinterIcon } from "../assets/icons/PrinterIcon";
import { RefreshIcon } from "../assets/icons/RefreshIcon";
import SuccessIcon from "../assets/icons/success.png";
import FailureIcon from "../assets/icons/failure.png";
import { useUserContext } from "context";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 24,
  height: 14,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 14,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis?.map((element) => element.el);
}

const SensorsTableContainer = (props) => {
  const { user } = useUserContext();

  const cells = [
    {
      label: "IEEE",
      Header: "IEEE",
      accessor: "ieee",
      id: "ieee",
      Cell: ({ cell: { value, row } }) => {
        return <Text>{value.slice(-4)}</Text>;
      },
    },
    {
      label: "Outage Alerts",
      Header: "Outage Alerts",
      accessor: "check_for_alerts",
      id: "check_for_alerts",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        let defaultValue = value === 1 ? true : false;

        let handleSwitch = () => {
          defaultValue = !defaultValue;
          props.onUpdateDevice(row.original?.ieee, {
            check_for_alerts: value === 1 ? 2 : 1,
          });
        };
        return (
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              No
            </FormLabel>
            <Switch
              id="email-alerts"
              isChecked={defaultValue}
              onChange={handleSwitch}
            />
            <FormLabel htmlFor="email-alerts" mb="0">
              Yes
            </FormLabel>
          </FormControl>
        );
      },
    },
    {
      label: "Outage Status",
      Header: "Outage Status",
      accessor: "outage_status",
      id: "outage_status",
      Cell: ({ cell: { value, row } }) => {
        return (
          <>
            {value === 1 ? (
              <img src={SuccessIcon} width={16} height={16} />
            ) : (
              <img src={FailureIcon} width={16} height={16} />
            )}
          </>
        );
      },
    },
    {
      label: "Outage Notifications",
      Header: "Outage Notifications",
      accessor: "outage_email",
      id: "outage_email",
      Cell: ({ cell: { value, row } }) => {
        return (
          <Text
            contentEditable="true"
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              props.onUpdateDevice(row.original?.ieee, {
                outage_email: e.target.outerText,
              })
            }
          >
            {value}
          </Text>
        );
      },
    },
    {
      label: "Type",
      Header: "Type",
      accessor: "sensor_type",
      id: "sensor_type",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        let handleChange = (e) => {
          let p = {};
          if (e.target.value === "Outdoor") {
            p.indoor_zone = null;
            p.bay_name = null;
          } else if (e.target.value === "Indoor") {
            p.bay_name = null;
          } else if (e.target.value === "Bay") {
            p.indoor_zone = null;
          }

          props.onUpdateDevice(row.original?.ieee, {
            sensor_type: e.target.value,
            ...p,
          });
        };

        return (
          <Select defaultValue={value} onChange={handleChange}>
            <option value="Outdoor">Outdoor</option>
            <option value="Indoor">Indoor</option>
            <option value="Bay">Bay</option>
          </Select>
        );
      },
    },
    {
      label: "Indoor Zone",
      Header: "Indoor Zone",
      accessor: "indoor_zone",
      id: "indoor_zone",
      Cell: ({ cell: { value, row } }) => {
        let handleChange = (e) => {
          props.onUpdateDevice(row.original?.ieee, {
            indoor_zone: parseInt(e.target.value),
          });
        };

        return (
          <>
            {(row?.original?.sensor_type === "Indoor" ||
              row?.original?.sensor_type === "Bay") && (
              <Select defaultValue={value?.toString()} onChange={handleChange}>
                <option value={null}>Select Indoor Zone</option>
                {props.zones?.map((item) => (
                  <React.Fragment key={item?.id}>
                    {item?.zone_type === 2 && (
                      <option value={item?.id}>{item?.zone_name}</option>
                    )}
                  </React.Fragment>
                ))}
              </Select>
            )}
          </>
        );
      },
    },
    {
      label: "Bay",
      Header: "Bay",
      accessor: "bay_name",
      id: "bay_name",
      Cell: ({ cell: { value, row } }) => {
        return (
          <>
            {row.original.sensor_type === "Bay" && (
              <Text
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                  props.onUpdateDevice(row.original?.ieee, {
                    bay_name: e.target.outerText,
                  })
                }
              >
                {value}
              </Text>
            )}
          </>
        );
      },
    },
  ];

  // return [...cells];
  let columns = [...cells];

  const sensorCount = props.data?.length ?? 0;

  // const handleRowClick = (row) => {
  //   props.onRowClick(row);
  // };

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let isLoading,
    page = 1,
    per_page = 20,
    total_records;

  console.log("props.data: ", props.data);

  const theme = createTheme();

  let handleSwitch = (row) => {
    let defaultValue = row?.check_for_alerts === 1 ? true : false;
    defaultValue = !defaultValue;
    props.onUpdateDevice(row?.ieee, {
      check_for_alerts: row?.check_for_alerts === 1 ? 2 : 1,
    });
  };

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb="2rem">
        <Text fontSize="3xl" color="gray.900" fontWeight="600">
          {`Sensors - ${sensorCount}`}
        </Text>
      </Flex>
      <ThemeProvider theme={theme}>
        <Box
          backgroundColor="white"
          borderRadius="1rem"
          py="3rem"
          px="2rem"
          overflow="auto"
        >
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                headCells={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={props.data?.length}
              />
              <TableBody>
                <TableSkeleton
                  rows={props?.data?.length}
                  loading={props?.data ? false : true}
                  cells={columns.length}
                  per_page={per_page}
                >
                  {stableSort(props?.data ?? [], getComparator(order, orderBy))
                    // ?.slice(
                    //   (page - 1) * per_page,
                    //   (page - 1) * per_page + per_page
                    // )
                    ?.map((row, index) => {
                      return (
                        <TableRow tabIndex={-1} key={`${row.ieee}-${index}`}>
                          <TableCell align="left">
                            <Typography sx={{ fontSize: "1.6rem" }}>
                              {row?.ieee?.slice(-4)}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <FormControl display="flex" alignItems="center">
                              <FormLabel htmlFor="email-alerts" mb="0">
                                <Typography sx={{ fontSize: "1.6rem" }}>
                                  No
                                </Typography>
                              </FormLabel>
                              <AntSwitch
                                checked={
                                  row?.check_for_alerts === 1 ? true : false
                                }
                                onChange={() => handleSwitch(row)}
                                sx={{ color: "#3182ce", mx: 1 }}
                              />
                              <FormLabel htmlFor="email-alerts" mb="0">
                                <Typography sx={{ fontSize: "1.6rem" }}>
                                  Yes
                                </Typography>
                              </FormLabel>
                            </FormControl>
                          </TableCell>
                          <TableCell align="left">
                            <>
                              {row?.outage_status === 1 ? (
                                <img src={SuccessIcon} width={16} height={16} />
                              ) : (
                                <img src={FailureIcon} width={16} height={16} />
                              )}
                            </>
                          </TableCell>
                          <TableCell align="left">
                            <Text
                              contentEditable="true"
                              suppressContentEditableWarning={true}
                              onBlur={(e) =>
                                props.onUpdateDevice(row?.ieee, {
                                  outage_email: e.target.outerText,
                                })
                              }
                              style={{ fontSize: "1.6rem" }}
                            >
                              {row?.outage_email}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Select
                              defaultValue={row?.sensor_type}
                              style={{ fontSize: "1.6rem" }}
                              onChange={(e) => {
                                let p = {};
                                if (e.target.value === "Outdoor") {
                                  p.indoor_zone = null;
                                  p.bay_name = null;
                                } else if (e.target.value === "Indoor") {
                                  p.bay_name = null;
                                } else if (e.target.value === "Bay") {
                                  p.indoor_zone = null;
                                }

                                props.onUpdateDevice(row?.ieee, {
                                  sensor_type: e.target.value,
                                  ...p,
                                });
                              }}
                            >
                              <option value="Outdoor">Outdoor</option>
                              <option value="Indoor">Indoor</option>
                              <option value="Bay">Bay</option>
                            </Select>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <>
                              {(row?.sensor_type === "Indoor" ||
                                row?.sensor_type === "Bay") && (
                                <Select
                                  style={{ fontSize: "1.6rem" }}
                                  defaultValue={row?.indoor_zone?.toString()}
                                  onChange={(e) => {
                                    props.onUpdateDevice(row?.ieee, {
                                      indoor_zone: parseInt(e.target.value),
                                    });
                                  }}
                                >
                                  <option value={null}>
                                    Select Indoor Zone
                                  </option>
                                  {props.zones?.map((item) => (
                                    <React.Fragment key={item?.id}>
                                      {item?.zone_type === 2 && (
                                        <option value={item?.id}>
                                          {item?.zone_name}
                                        </option>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Select>
                              )}
                            </>
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {row.sensor_type === "Bay" && (
                              <Text
                                style={{ fontSize: "1.6rem" }}
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                onBlur={(e) =>
                                  props.onUpdateDevice(row?.ieee, {
                                    bay_name: e.target.outerText,
                                  })
                                }
                              >
                                {row?.bay_name}
                              </Text>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableSkeleton>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ThemeProvider>
      {/* <TablePagination
            style={{ float: "right" }}
            // rowsPerPageOptions={[5, 10, 20, 30]}
            // component="div"
            count={total_records}
            rowsPerPage={per_page}
            // page={page}
            page={page ? page - 1 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="pagination-footer"
          /> */}

      {/* {props.data == null ? (
        <Skeleton width="100%" height="30rem" borderRadius="1rem" />
      ) : (
        <SimpleTable
          columns={columns}
          data={props.data}
          // onNext={props.onNext}
          // onBack={props.onBack}
          // onRowClick={handleRowClick}
        />
      )} */}
    </Box>
  );
};

export { SensorsTableContainer };
