import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Skeleton,
} from "@chakra-ui/react";

import { SimpleTable } from "components/Tables/SimpleTable";

const VehicleTestsModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalCloseButton />
        <ModalHeader>Vehicle Tests</ModalHeader>
        <ModalBody>
          {props.data?.data == null ? (
            <Skeleton width="100%" height="30rem" borderRadius="1rem" />
          ) : (
            <SimpleTable
              columns={props.columns}
              data={props.data?.data}
              hidePagination
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { VehicleTestsModal };
