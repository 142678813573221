import { geoCentroid } from "d3-geo";
import { Box, Text } from "@chakra-ui/react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import "./graph.scss";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
import RANGE_COEFFICIENT from "constants/RANGE_COEFFICIENT";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

export default function USMap({ vehicleData, tooltipDescription }) {
  const allStates = [
    { id: "200 mi", val: "02" },
    { id: "300 mi", val: "06" },
    { id: "250 mi", val: "08" },
    { id: "250 mi", val: "12" },
    { id: "300 mi", val: "15" },
    { id: "225 mi", val: "17" },
    { id: "200 mi", val: "23" },
    { id: "250 mi", val: "36" },
    { id: "300 mi", val: "48" },
    { id: "225 mi", val: "51" },
    { id: "250 mi", val: "53" },
  ];
  return (
    <Box>
      <div className="heatmap_heading">
        <h2 style={{ display: "flex", justifyContent: "center" }}>
          NORMAL RANGE (80% to 20%){" "}
          <Tooltip label={tooltipDescription}>
            <Icon
              icon={infoFill}
              style={{ color: "black", width: 16, height: 16 }}
            />
          </Tooltip>
        </h2>
      </div>

      <ComposableMap projection="geoAlbersUsa" style={{ padding: "0% 10%" }}>
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  stroke="#FFF"
                  geography={geo}
                  fill="#DDD"
                />
              ))}
              {geographies.map((geo) => {
                const centroid = geoCentroid(geo);
                const cur = allStates.find((s) => s.val === geo.id);
                return (
                  <g key={geo.rsmKey + "-name"}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text
                            y="0"
                            fontSize={24}
                            textAnchor="middle"
                            fill="red"
                            fontFamily={`"Montserrat", "sans-serif"`}
                          >
                            {(
                              parseFloat(vehicleData?.range_now) *
                              0.6 *
                              (1 -
                                (parseFloat(
                                  vehicleData?.winter_factor ?? 0.171597633
                                ) *
                                  RANGE_COEFFICIENT?.[cur.val]
                                    ?.coefficient_summer) /
                                  parseFloat(vehicleData?.soh))
                            )?.toFixed(0)}{" "}
                            mi
                          </text>
                          <text
                            y="24"
                            fontSize={24}
                            textAnchor="middle"
                            fill="blue"
                            fontFamily={`"Montserrat", "sans-serif"`}
                          >
                            {(
                              parseFloat(vehicleData?.range_now) *
                              0.6 *
                              (1 -
                                (parseFloat(
                                  vehicleData?.winter_factor ?? 0.171597633
                                ) *
                                  RANGE_COEFFICIENT?.[cur.val]
                                    ?.coefficient_winter) /
                                  parseFloat(vehicleData?.soh))
                            )?.toFixed(0)}{" "}
                            mi
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.id][0]}
                          dy={offsets[cur.id][1]}
                        >
                          <text
                            x={4}
                            fontSize={14}
                            alignmentBaseline="middle"
                            fontFamily={`"Montserrat", "sans-serif"`}
                          >
                            {cur.id}
                          </text>
                        </Annotation>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>

      <div style={{ display: "flex" }} className="pickup_colors">
        <div className="green_first">
          <div className="red_color"></div>
          <div className="good">
            <h3>Summer</h3>
          </div>
        </div>

        <div className="bad_last">
          <div className="blue_color"></div>
          <div className="bad">
            <h3>Winter</h3>
          </div>
        </div>
      </div>
    </Box>
  );
}
