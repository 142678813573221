import React from "react";
import { Flex, SimpleGrid, Text, Link, Button } from "@chakra-ui/react";
import { useUserContext } from "../../context/UserContext";

const Footer = () => {
  const { logout } = useUserContext();

  return (
    <Flex
      flex="1"
      justifyContent="space-between"
      alignItems="center"
      borderTop="1px solid #E2E8F0"
    >
      <SimpleGrid columns={2} my="2rem">
        <Text color="gray.400" disabled>
          FAQs
        </Text>
        <Link color="gray.600" href="mailto:hi@vsnew.com">
          Support
        </Link>
      </SimpleGrid>

      <Button variant="ghost" colorScheme="red" ml="-1.5rem" onClick={logout}>
        Sign Out
      </Button>
    </Flex>
  );
};

export { Footer };
