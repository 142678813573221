import React, { useState, useEffect, useCallback, useRef } from "react";
import { IconButton, Box, Text, Button, useMediaQuery } from "@chakra-ui/react";
import { Redirect, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode";
import moment from "moment";
import vsNewImg from "assets/new_report/vsNewImg.png";

import {
  downloadBase64,
  formatDate,
  getScore,
  getScreenshotOfDiv,
} from "utils";
import { S3 } from "config";

import { PlayIcon } from "assets/icons/PlayIcon";
import { PauseIcon } from "assets/icons/PauseIcon";
import { DownloadIcon } from "assets/icons/DownloadIcon";
import { PrinterIcon } from "assets/icons/PrinterIcon";
import { LoadingLayout } from "components/Layouts/LoadingLayout";
import { ReportCard } from "components/Cards/ReportCard";
import { ReportEVPrint } from "components/Prints/ReportPrint";

import BatteryDetails from "components/new_report_hybrid/BatteryDetails";
import BatteryDetailsPrint from "components/new_report_print/BatteryDetails_hybrid";
import HybridFooter from "components/new_report/HybridFooter";
import EvHeader from "components/new_report/Evheader";

import BatteryDetailsMobile from "components/new_report_hybrid_mobile/BatteryDetails";
import HybridFooterMobile from "components/new_report_mobile/HybridFooter";
import EvHeaderMobile from "components/new_report_mobile/Evheader";

import { Helmet } from "react-helmet";

import * as Styles from "./Report.styles";

export default function ReportEV() {
  const [isSmallScreen] = useMediaQuery("(max-width: 769px)");
  const [qrCode, setQrCode] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const [printMode, setPrintMode] = useState(false);

  const componentRef = useRef();
  const containerRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrintMode(true);
    },
    onAfterPrint: () => {
      setPrintMode(false);
    },
    onPrintError: (error) => {
      console.log(error);
    },
  });

  const getReport = useCallback((id, vin) => {
    return fetch(
      `${process.env.REACT_APP_AWS_PUBLIC_API_ENDPOINT}/getEV?id=${id}&vin=${vin}`
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });
  }, []);

  useEffect(async () => {
    const id = params.reportId?.split("-")?.[0]?.toUpperCase();
    const vin = params.reportId?.split("-")?.[1]?.toUpperCase();

    if (vin == null || id == null) {
      setLoading(false);
      return;
    }

    try {
      await getReport(id, vin);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (printMode) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(1000);
      handlePrint();
    }
  }, [printMode]);

  useEffect(() => {
    QRCode.toDataURL(window.location.href).then((qrCodeDataUrl) => {
      setQrCode(qrCodeDataUrl);
    });
  }, []);

  const vehicleData = data?.data;

  if (loading) {
    return <LoadingLayout />;
  }

  if (vehicleData == null) {
    return <Redirect to="/battery" />;
  }

  return (
    <Styles.Container maxWidth="100rem" padding="2rem">
      <Helmet
        title={`vsNew - ${vehicleData?.year} ${vehicleData?.make} ${vehicleData?.model} Battery Health Report`}
      />
      {isSmallScreen ? (
        <React.Fragment>
          <Box
            backgroundColor="white"
            padding="1rem 4rem 1rem 4rem"
            // margin="1.5rem 0rem 1.5rem 0rem"
            style={{ marginBottom: "20px" }}
          >
            <a href="https://www.vsnew.com/" style={{ display: "flex" }}>
              <img
                className="nav_logo"
                src={vsNewImg}
                alt=""
                style={{ alignSelf: "center", zIndex: 100 }}
              />
            </a>
          </Box>

          <Box
            backgroundColor="white"
            padding="1rem 1rem 0rem 1rem"
            // margin="1.5rem 0rem 1.5rem 0rem"
          >
            <EvHeaderMobile
              vehicleTypeTitle={"Hybrid"}
              vehicleData={vehicleData}
              tooltipDescription={
                "The vsNEW overall score is based on an analysis of hundreds of measurements, including cell voltages, cell temperatures, cell internal resistance, and overall usage (if available). An equivalent new battery would score 100%."
              }
            />
            <BatteryDetailsMobile vehicleData={vehicleData} />
            <HybridFooterMobile
              vehicleTypeTitle={"Hybrid"}
              qrCode={qrCode}
              vehicleData={vehicleData}
            />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            my="2rem"
          >
            <Button
              leftIcon={<PrinterIcon />}
              colorScheme="red"
              variant="solid"
              onClick={() => {
                setPrintMode(true);
              }}
            >
              Print
            </Button>
          </Box>
          <ComponentToPrint
            ref={componentRef}
            vehicleData={vehicleData}
            qrCode={qrCode}
            printMode={printMode}
          />
        </React.Fragment>
      )}
    </Styles.Container>
  );
}

export const ComponentToPrint = React.forwardRef(
  ({ vehicleData, qrCode, printMode }, ref) => {
    return (
      <Box
        id="report-print-container"
        ref={ref}
        backgroundColor="white"
        padding="0rem 1rem 0rem 1rem"
        // margin="1.5rem 0rem 1.5rem 0rem"
      >
        <EvHeader
          vehicleTypeTitle={"Hybrid"}
          vehicleData={vehicleData}
          tooltipDescription={
            "The vsNEW overall score is based on an analysis of hundreds of measurements, including cell voltages, cell temperatures, cell internal resistance, and overall usage (if available). An equivalent new battery would score 100%."
          }
        />
        {printMode ? (
          <BatteryDetailsPrint vehicleData={vehicleData} />
        ) : (
          <BatteryDetails vehicleData={vehicleData} />
        )}
        <HybridFooter
          vehicleTypeTitle={"Hybrid"}
          qrCode={qrCode}
          vehicleData={vehicleData}
        />
      </Box>
    );
  }
);
