import React, { useState } from "react";
import { Box, Text, Flex, IconButton, Skeleton } from "@chakra-ui/react";

import { SimpleTable } from "../components/Tables/SimpleTable";
import { CompletedVehiclesFilter } from "../components/Filters/CompletedVehiclesFilter";
import { VehicleTestsModalContainer } from "./VehicleTestsModalContainer";

import { FilterIcon } from "../assets/icons/FilterIcon";

const TableContainer = (props) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [vehicleTestVin, setVehicleTestVin] = useState(null);

  const handleOpenFilter = () => {
    setFilterVisible(true);
  };

  const handleCloseFilter = () => {
    setFilterVisible(false);
  };

  const handleSubmit = (args) => {
    props.onFilter(args);
    handleCloseFilter();
  };

  const handleRowClick = (row) => {
    if (row.vin == null) {
      return;
    }

    setVehicleTestVin(row.vin);
  };

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb="2rem">
        <Text fontSize="3xl" color="gray.900" fontWeight="600">
          {props.title}
        </Text>

        <IconButton
          variant="ghost"
          aria-label="Filter"
          icon={<FilterIcon />}
          onClick={handleOpenFilter}
        />
      </Flex>

      {props.data?.data == null ? (
        <Skeleton width="100%" height="30rem" borderRadius="1rem" />
      ) : (
        <SimpleTable
          columns={props.columns}
          data={props.data.data}
          onNext={props.onNext}
          onBack={props.onBack}
          onClick={handleRowClick}
        />
      )}

      <CompletedVehiclesFilter
        isOpen={filterVisible}
        onClose={handleCloseFilter}
        onSubmit={handleSubmit}
        defaultValues={props.defaultFilterValues}
      />

      {vehicleTestVin != null && (
        <VehicleTestsModalContainer
          isOpen={vehicleTestVin != null}
          vin={vehicleTestVin}
          onClose={() => setVehicleTestVin(null)}
        />
      )}
    </Box>
  );
};

TableContainer.defaultProps = {
  title: "",
  columns: [],
};

export { TableContainer };
