import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

const InputController = ({ control, name, label, ...props }) => {
  return (
    <FormControl isInvalid={Boolean(props.error)} {...props.FormControl}>
      {label != null && (
        <FormLabel fontSize="1.4rem" color="gray.600">
          {label}
        </FormLabel>
      )}

      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Input
            px="1.4rem"
            py="1.7rem"
            fontSize="1.4rem"
            borderRadius="0.4rem"
            backgroundColor="gray.50"
            borderColor="gray.50"
            {...props}
            {...field}
          />
        )}
      />
      <FormErrorMessage>{props.error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export { InputController };
