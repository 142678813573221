import React from "react";
import { SimpleGrid, Box, Text } from "@chakra-ui/react";

import { DashboardContextProvider, useDashboardContext } from "context";
import { CompleteVehiclesTableContainer } from "containers/CompleteVehiclesTableContainer";
import { PendingVehiclesTableContainer } from "containers/PendingVehiclesTableContainer";
import { DismissedVehiclesTableContainer } from "containers/DismissedVehiclesTableContainer";

import { PageLayout } from "components/Layouts/PageLayout";
import { StatisticsCard } from "components/Cards/StatisticsCard";
import { VehicleRatigsChart } from "components/Charts/VehicleRatigsChart";
import { AverageScoreVehiclesChart } from "components/Charts/AverageScoreVehiclesChart";

const EVHomeContent = () => {
  const {
    completedVehicles,
    pendingVehicles,
    dismissedVehicles,
    vehicleRatingsStatistics,
    ratingsTrendStatistics,
    handleOpenReport,
    handleMoveToCompleted,
    handleMoveToPending,
    handleMoveToDismissed,
  } = useDashboardContext();

  return (
    <PageLayout title="Battery">
      <Box>
        <SimpleGrid columns={[1, 1, 2]} spacing={["5rem", "5rem", "2.4rem"]}>
          <AverageScoreVehiclesChart data={ratingsTrendStatistics?.data} />
          <VehicleRatigsChart data={vehicleRatingsStatistics?.data} />
        </SimpleGrid>
      </Box>

      <Box mt="5rem">
        <CompleteVehiclesTableContainer
          ev={true}
          data={completedVehicles?.data}
          defaultFilterValues={completedVehicles.filters}
          onNext={completedVehicles.handleNext}
          onBack={completedVehicles.handleBack}
          onFilter={completedVehicles.handleFilter}
          onUpdateVehicle={completedVehicles.handleUpdateVehicle}
          onPrint={handleOpenReport}
        />
      </Box>

      {/* <Box mt="5rem">
        <PendingVehiclesTableContainer
          data={pendingVehicles?.data}
          defaultFilterValues={pendingVehicles.filters}
          onNext={pendingVehicles.handleNext}
          onBack={pendingVehicles.handleBack}
          onFilter={pendingVehicles.handleFilter}
          onMoveToCompleted={handleMoveToCompleted}
          onMoveToDismissed={handleMoveToDismissed}
        />
      </Box> */}
    </PageLayout>
  );
};

const EVHome = () => {
  return (
    <DashboardContextProvider
      endpoints={{
        updateVehicle: "/updateEV",
        getVehicles: "/getEVs",
        getVehicleRatingsStatistics: "/getEVStatistics",
        getRatingsTrendStatistics: "/getEVTrends",
      }}
    >
      <EVHomeContent />
    </DashboardContextProvider>
  );
};

export { EVHome };
