import moment from "moment-timezone";

export const getMonthName = (monthNumber) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (monthNumber === "auto" || monthNumber === 0) {
    return monthNames[0];
  }

  return monthNames[Number(monthNumber) - 1];
};

export const formatDate = (date, format) => {
  return moment(date)
    .tz("America/New_York")
    .format(format ?? "MM/DD/YYYY hh:mm A");
};
