import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useUserContext } from "context/UserContext";

import { PageLayout } from "components/Layouts/PageLayout";
import { ContentLayout } from "components/Layouts/ContentLayout";
import { AddPaymentMethodForm } from "components/Forms/AddPaymentMethodForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionForm from "./SubscriptionForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CHECKOUT_KEY);

export default function SignAgreement() {
  const { user, attachPaymentMethod } = useUserContext();

  return (
    <PageLayout title="Payment">
      <ContentLayout title="Payment">
        <Box bg="white" mt="3rem" p="3.2rem" borderRadius="1.6rem">
          <Box maxWidth={["100%", "50%", "40%"]}>
            {user?.partner_code?.includes("EVU") && (
              <Text>
                Your monthly fees will be due directly to EV Universe. We will
                only charge your credit card $125 if you to do not return the
                equipment upon the end of the agreement.
                <br />
                After clicking below, please wait up to 45 seconds to continue.
              </Text>
            )}
            {user?.partner_code?.includes("TS") && (
              <>
                <Text fontSize="2xl" as="b">
                  Unlimited Reports Monthly Subscription - $100
                </Text>
                <Text>
                  We will prorate the first month&apos;s payment and charge $100
                  per month thereafter. We will charge only after you receive
                  the equipment.
                </Text>
              </>
            )}
            {user?.partner_code?.includes("EVCHECKUP") && (
              <Text>
                Your monthly fees will be due directly to Risk Coverage LLC. We
                will only charge your credit card $125 if you to do not return
                the equipment upon the end of the agreement.
                <br />
                After clicking below, please wait up to 45 seconds to continue.
              </Text>
            )}
            {user?.partner_code?.includes("LECTRIUM") && (
              <Text>
                Your monthly fees will be due directly to Lectrium, Inc. We will
                only charge your credit card $125 if you to do not return the
                equipment upon the end of the agreement.
                <br />
                After clicking below, please wait up to 45 seconds to continue.
              </Text>
            )}
            {user?.partner_code?.includes("149SP") && (
              <>
                <Text fontSize="2xl" as="b">
                  Unlimited Reports Monthly Subscription - $149
                </Text>
                <Text>
                  We will prorate the first month&apos;s payment and charge $149
                  per month thereafter. We will charge only after you receive
                  the equipment.
                </Text>
              </>
            )}
            {user?.partner_code?.includes("12MONTH100") && (
              <>
                <Text fontSize="2xl" as="b">
                  Unlimited Reports Annual Subscription - $1200 & One Time
                  Equipment Charge - $125
                </Text>
                <Text>
                  We will charge $1200 per year and $125 for equipment
                  thereafter making total of $1325. We will charge only after
                  you receive the equipment.
                </Text>
              </>
            )}
            {user?.partner_code?.includes("TEST1") && (
              <>
                <Text fontSize="2xl" as="b">
                  Unlimited Reports Annual Subscription - $1 & One Time
                  Equipment Charge - $125
                </Text>
                <Text>
                  We will charge $1 per year and $125 for equipment thereafter
                  making total of $126. We will charge only after you receive
                  the equipment.
                </Text>
              </>
            )}
            {user?.partner_code?.includes("CASTROL") && (
              <Text>
                Your monthly fees will be due directly to CASTROL. We will only
                charge your credit card if you to do not return the equipment
                upon the end of the agreement
              </Text>
            )}
            {user?.partner_code?.includes("TRIAL") && (
              <>
                <Text fontSize="2xl" as="b">
                  Unlimited Reports Monthly Subscription - $299
                </Text>
                <Text>
                  Free for 45 days, $299 per month thereafter. We will not
                  charge you anything if you return the equipment within 45
                  days.
                </Text>
              </>
            )}
            {!user?.partner_code && (
              <>
                <Text fontSize="2xl" as="b">
                  Unlimited Reports Monthly Subscription - $299
                </Text>
                <Text>
                  We will prorate the first month&apos;s payment and charge $299
                  per month thereafter. We will charge only after you receive
                  the equipment.
                </Text>
              </>
            )}

            <br />
            <br />
            <br />

            {/* {!paymentMethods?.length && user?.stripe_payment_method && (
              
            )} */}

            <Elements stripe={stripePromise}>
              <SubscriptionForm
                attachPaymentMethod={attachPaymentMethod}
                partner_code={user?.partner_code}
              />
            </Elements>
          </Box>
        </Box>
      </ContentLayout>
    </PageLayout>
  );
}
