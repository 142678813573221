import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const LoadingLayout = () => {
  return (
    <Flex
      flexDirection="column"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor="#f3fbff"
    >
      <Spinner />
    </Flex>
  );
};

export { LoadingLayout };
