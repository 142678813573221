import * as React from "react";
import { Button, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { InputController } from "../../Controllers/InputController";

const schema = yup.object().shape({
  currentPassword: yup.string().min(6).required("This field is required"),
  newPassword: yup.string().min(6).required("This field is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], undefined)
    .required("This field is required"),
});

const ChangePasswordForm = (props) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <InputController
        name="currentPassword"
        type="password"
        placeholder="Your Current Password"
        error={errors?.currentPassword}
        control={control}
      />

      <Box mt="1.6rem">
        <InputController
          name="newPassword"
          type="password"
          placeholder="Your New Password"
          error={errors?.newPassword}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <InputController
          name="confirmPassword"
          type="password"
          placeholder="Your New Password Again"
          error={errors?.confirmPassword}
          control={control}
        />
      </Box>

      <Button
        type="submit"
        isFullWidth
        colorScheme="red"
        borderRadius="0.4rem"
        mt="3.2rem"
        fontSize="1.4rem"
        py="1.6rem"
        isLoading={isSubmitting}
      >
        Change Password
      </Button>
    </form>
  );
};

export { ChangePasswordForm };
