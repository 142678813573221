import React, { useMemo } from "react";
import { IconButton, Image, ButtonGroup, Text, Select } from "@chakra-ui/react";

import { add3Dots, formatDate, getScore } from "utils";

import { TableContainer } from "./TableContainer";

import { PrinterIcon } from "../assets/icons/PrinterIcon";
import { RefreshIcon } from "../assets/icons/RefreshIcon";
import { useUserContext } from "context";

const CompleteVehiclesTableContainer = (props) => {
  const { user } = useUserContext();

  const columns = useMemo(() => {
    const cells = [
      {
        Header: "Picture",
        accessor: "image_url",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return (
            <Image
              src={value}
              width="10rem"
              height="5rem"
              fallbackSrc="https://via.placeholder.com/100x50"
            />
          );
        },
      },
      {
        Header: "Account",
        accessor: "dealership_name",
      },
      {
        Header: "VIN",
        accessor: "vin",
      },
      {
        Header: "Make",
        accessor: "make",
      },
      {
        Header: "Model",
        accessor: (data) => {
          const model = `${data.model ?? ""} ${data.series ?? ""}`.trim();
          return add3Dots(model, 15);
        },
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Score",
        accessor: "score",
        Cell: ({ cell: { value } }) => {
          if (value == null) {
            return null;
          }

          return `${getScore(value)}%`;
        },
      },
      {
        Header: "Tested",
        accessor: "test_timestamp",
        Cell: ({ cell: { value } }) => {
          return formatDate(value);
        },
      },
    ];

    const isAdminEmail = ["support@vsnew.com"].includes(user.email);

    let extraCells = [];

    if (isAdminEmail) {
      let evCells = [
        {
          Header: "Battery New",
          accessor: "battery_new",
          Cell: ({ cell: { value, row } }) => {
            const vehicleId = row.original.id;

            return (
              <Text
                contentEditable="true"
                onBlur={(e) =>
                  props.onUpdateVehicle(vehicleId, {
                    battery_new: e.target.outerText,
                  })
                }
              >
                {Number(value).toFixed(2)}
              </Text>
            );
          },
        },
        {
          Header: "Range New",
          accessor: "range_new",
          Cell: ({ cell: { value, row } }) => {
            const vehicleId = row.original.id;

            return (
              <Text
                contentEditable="true"
                onBlur={(e) =>
                  props.onUpdateVehicle(vehicleId, {
                    range_new: e.target.outerText,
                  })
                }
              >
                {Number(value).toFixed(2)}
              </Text>
            );
          },
        },
      ];

      let vehicleCells = [
        {
          Header: "Acceleration",
          accessor: "accel_new",
          Cell: ({ cell: { value, row } }) => {
            const vehicleId = row.original.id;

            return (
              <Text
                contentEditable="true"
                onBlur={(e) =>
                  props.onUpdateVehicle(vehicleId, {
                    accel_new: e.target.outerText,
                  })
                }
              >
                {Number(value).toFixed(2)}
              </Text>
            );
          },
        },
        {
          Header: "Brakes",
          accessor: "brakes_new",
          Cell: ({ cell: { value, row } }) => {
            const vehicleId = row.original.id;

            return (
              <Text
                contentEditable="true"
                onBlur={(e) =>
                  props.onUpdateVehicle(vehicleId, {
                    brakes_new: e.target.outerText,
                  })
                }
              >
                {Number(value).toFixed(2)}
              </Text>
            );
          },
        },
        {
          Header: "MPG",
          accessor: "mpg_new",
          Cell: ({ cell: { value, row } }) => {
            const vehicleId = row.original.id;

            return (
              <Text
                contentEditable="true"
                onBlur={(e) =>
                  props.onUpdateVehicle(vehicleId, {
                    mpg_new: e.target.outerText,
                  })
                }
              >
                {Number(value).toFixed(2)}
              </Text>
            );
          },
        },
        {
          Header: "Turbo",
          accessor: "is_turbo",
          Cell: ({ cell: { value, row } }) => {
            const vehicleId = row.original.id;
            const defaultValue = value === true ? 1 : 0;

            return (
              <Select
                defaultValue={defaultValue.toString()}
                onChange={(e) =>
                  props.onUpdateVehicle(vehicleId, {
                    is_turbo: parseInt(e.target.value),
                  })
                }
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Select>
            );
          },
        },
      ];
      extraCells = props?.ev ? evCells : vehicleCells;
    }

    const actionsCell = {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ cell: { value } }) => {
        return (
          <ButtonGroup spacing="1rem" display="flex" justifyContent="center">
            <IconButton
              variant="ghost"
              aria-label="Print"
              icon={<PrinterIcon />}
              onClick={() => props.onPrint(value, props.ev ? "ev" : "report")}
            />
          </ButtonGroup>
        );
      },
    };

    return [...cells, ...extraCells, actionsCell];
  }, [user]);

  const vehiclesCount = props.data?.totalItems
    ? `- ${props.data.totalItems}`
    : "";

  return (
    <TableContainer
      title={`Full Reports ${vehiclesCount}`}
      columns={columns}
      data={props.data}
      defaultFilterValues={props.defaultFilterValues}
      onFilter={props.onFilter}
      onNext={props.onNext}
      onBack={props.onBack}
    />
  );
};

export { CompleteVehiclesTableContainer };
