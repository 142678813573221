import * as React from "react";
import { Button, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { InputController } from "../../Controllers/InputController";
import { ForgotPasswordLink } from "../../Links/ForgotPasswordLink";

const schema = yup.object().shape({
  email: yup.string().email().required("This field is required"),
  password: yup.string().min(8).required("This field is required"),
});

const LoginForm = (props) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <InputController
        name="email"
        type="email"
        fontSize="1.8rem"
        placeholder="Your Work Email Address"
        error={errors?.email}
        control={control}
      />

      <Box mt="1.6rem">
        <InputController
          name="password"
          type="password"
          fontSize="1.8rem"
          placeholder="Your Password"
          error={errors?.password}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <ForgotPasswordLink />
      </Box>

      <Button
        type="submit"
        isFullWidth
        colorScheme="red"
        borderRadius="0.4rem"
        mt="3.2rem"
        fontSize="1.8rem"
        py="1.6rem"
        isLoading={isSubmitting}
      >
        Log In
      </Button>
    </form>
  );
};

export { LoginForm };
