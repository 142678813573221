import React from "react";
import { Box, Text } from "@chakra-ui/react";

import { useUserContext } from "../../context";

import ForgotLottie from "assets/lotties/forgot.lottie.json";
import vsNEW_4_ForgotPassword_V2 from "assets/gifs/vsNEW_4_ForgotPassword_V2.gif";
import { AuthenticationLayout } from "../../components/Layouts/AuthenticationLayout";
import { ForgotPasswordForm } from "../../components/Forms/ForgotPasswordForm";

const ForgotPassword = () => {
  const { forgotPassword } = useUserContext();

  return (
    <AuthenticationLayout
      title="Forgot Password"
      animationData={ForgotLottie}
      gif={vsNEW_4_ForgotPassword_V2}
    >
      <Box textAlign="center" mt="1.8rem">
        <Text color="gray.900" fontWeight="bold" fontSize="2.2rem">
          Forgot Your Password?
        </Text>

        <Text color="gray.400" fontSize="1.4rem">
          Let’s get you back in your account
        </Text>
      </Box>

      <Box mt="4.2rem">
        <ForgotPasswordForm onSubmit={forgotPassword} />
      </Box>
    </AuthenticationLayout>
  );
};

export { ForgotPassword };
