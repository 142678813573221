const s3BaseUrl = process.env.REACT_APP_AWS_S3_ASSETS_BUCKET;

export const S3 = {
  getBadgeImage: (number) =>
    `${s3BaseUrl}/badges/${number}.png?nocache=${Date.now()}`,
  getCompanyImage: (company) =>
    `${s3BaseUrl}/companies/${company.toLowerCase()}.jpg?nocache=${Date.now()}`,
};

export const STATUSES = {
  COMPLETED: {
    status: 10,
  },
  PENDING: {
    status: 20,
  },
  DISMISSED: {
    status: 30,
  },
};
