import * as React from "react";
import { Button } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { InputController } from "../../Controllers/InputController";

const schema = yup.object().shape({
  email: yup.string().email().required("This field is required"),
});

const ForgotPasswordForm = (props) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <InputController
        name="email"
        type="email"
        fontSize="1.8rem"
        placeholder="Your Work Email Address"
        error={errors?.email}
        control={control}
      />

      <Button
        type="submit"
        isFullWidth
        colorScheme="red"
        borderRadius="0.4rem"
        mt="3.2rem"
        fontSize="1.4rem"
        py="1.6rem"
        isLoading={isSubmitting}
      >
        Reset Password
      </Button>
    </form>
  );
};

export { ForgotPasswordForm };
