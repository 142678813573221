import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useUserContext } from "context/UserContext";

import { PageLayout } from "components/Layouts/PageLayout";
import { ContentLayout } from "components/Layouts/ContentLayout";
import { ChangeAccountInfoForm } from "components/Forms/ChangeAccountInfoForm";
import { ChangePasswordForm } from "components/Forms/ChangePasswordForm";
import { AddPaymentMethodForm } from "components/Forms/AddPaymentMethodForm";
import { DeleteAccountForm } from "components/Forms/DeleteAccountForm";
import NormalAgreement from "./NormalAgreement";
import EVUAgreement from "./EVUAgreement";
import CastrolAgreement from "./CastrolAgreement";
import TrialAgreement from "./TrialAgreement";
import TSAgreement from "./TSAgreement";
import LECTRIUMAgreement from "./LECTRIUMAgreement";
import EVCHECKUPAgreement from "./EVCHECKUPAgreement";
import SP149Agreement from "./149SPAgreement";
import MONTH12100Agreement from "./12MONTH100Agreement";
import TEST1Agreement from "./TEST1Agreement";

export default function SignAgreement() {
  const { user, signAgreement } = useUserContext();

  const schema = yup.object().shape({
    name: yup.string().required("This field is required"),
    dealerName: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
    address2: yup.string(),
    city: yup.string().required("This field is required"),
    state: yup.string().required("This field is required"),
    zip: yup.string().required("This field is required"),
    signature: yup
      .string()
      .required()
      .when("name", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("name")], "Must match name exactly"),
      }),
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name ?? "",
      dealerName: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      signature: "",
    },
  });

  return (
    <PageLayout title="Sign Agreement">
      {user?.partner_code?.includes("EVU") && (
        <>
          <EVUAgreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("CASTROL") && (
        <>
          <CastrolAgreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("TRIAL") && (
        <>
          <TrialAgreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("TS") && (
        <>
          <TSAgreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("LECTRIUM") && (
        <>
          <LECTRIUMAgreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("EVCHECKUP") && (
        <>
          <EVCHECKUPAgreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("149SP") && (
        <>
          <SP149Agreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("12MONTH100") && (
        <>
          <MONTH12100Agreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
      {user?.partner_code?.includes("TEST1") && (
        <>
          <TEST1Agreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}

      {!user?.partner_code && (
        <>
          <NormalAgreement
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            signAgreement={signAgreement}
            setValue={setValue}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </PageLayout>
  );
}
