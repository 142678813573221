import React, { useState, useEffect } from "react";
import { SimpleGrid, Box, Select, Text, GridItem } from "@chakra-ui/react";

import { DashboardContextProvider, useDashboardContext } from "context";

import { PageLayout } from "components/Layouts/PageLayout";
import { InventoryTableContainer } from "./InventoryTableContainer";
import { InventoryFilters } from "./InventoryFilters";
import InventoryGoogleMaps from "./Maps/InventoryGoogleMaps";

const InventoryHomeContent = () => {
  const {
    inventoryIsLoading,
    lots = {},
    lot_id,
    setLotId,
    zonesStats = [],
    inventoryList = {},
    handleSearchInventory,
    zones,
    handleCreateZone,
    handleUpdateZone,
    handleDeleteZone,
    handleUpdateZoneOrder,
    devices = [],
    handleUpdateDevice,
    completedVehicles,
    pendingVehicles,
    dismissedVehicles,
    vehicleRatingsStatistics,
    ratingsTrendStatistics,
    handleOpenReport,
    handleMoveToCompleted,
    handleMoveToPending,
    handleMoveToDismissed,
  } = useDashboardContext();

  useEffect(() => {
    if (lots?.lots && lots?.lots?.length === 1) {
      setLotId(lots.lots[0]?.lot_id);
    }
  }, [lots]);

  const [filters, setFilters] = useState({});
  const handleFiltersInputChange = (e) => {
    const value = e.target.value;
    if (value) {
      setFilters({
        ...filters,
        [e.target.name]: value,
      });
    } else {
      let f = { ...filters };
      delete f[e.target.name];
      setFilters({ ...f });
    }
  };

  const handleSearch = () => {
    handleSearchInventory(lot_id, filters);
  };
  const handleClearSearch = () => {
    let f = {};
    setFilters(f);
    handleSearchInventory(lot_id, {});
  };
  const handleZoneClick = (args) => {
    setFilters(args);
    handleSearchInventory(lot_id, args);
  };

  return (
    <PageLayout title="Admin">
      <Box display={"flex"} justifyContent="flex-end">
        <Select
          size="lg"
          variant="filled"
          maxWidth={300}
          value={lot_id ?? ""}
          onChange={(e) => setLotId(e.target.value)}
        >
          <option value="">Select Lot</option>
          {lots?.lots?.map((item) => (
            <option value={item?.lot_id} key={item?.lot_id}>
              {item?.lot_name}
            </option>
          ))}
        </Select>
      </Box>

      {lot_id && (
        <>
          <Box mt="5rem">
            <Text fontWeight={"bold"} fontSize="2xl" mb={"2rem"}>
              Quick Search
            </Text>
            <SimpleGrid
              // templateColumns="repeat(5, 1fr)"
              // columns={{ sm: 1, md: 3, lg: 5 }}
              // columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
              minChildWidth="280px"
              gap={6}
            >
              {zonesStats?.data?.map((item, index) => (
                <GridItem w="100%" key={index}>
                  <Box
                    key={index}
                    borderRadius="1rem"
                    backgroundColor="white"
                    py="3rem"
                    px="2rem"
                    overflow="auto"
                  >
                    <Text fontWeight={"bold"}>
                      {index + 1} - {item?.zone_name}
                    </Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        handleZoneClick({ zone_id: item?.zone_id });
                      }}
                    >
                      Total: {item?.total}
                    </Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        handleZoneClick({
                          zone_id: item?.zone_id,
                          status: "Ready",
                        });
                      }}
                    >
                      Ready: {item?.ready}
                    </Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        handleZoneClick({ zone_id: item?.zone_id });
                      }}
                    >
                      Overdue: {item?.overdue}
                    </Text>
                  </Box>
                </GridItem>
              ))}
            </SimpleGrid>
          </Box>

          {zonesStats && (
            <Box mt="5rem">
              <SimpleGrid minChildWidth="280px" gap={6}>
                <GridItem w="100%" height={"100%"}>
                  <Box
                    borderRadius="1rem"
                    backgroundColor="white"
                    py="3rem"
                    px="2rem"
                    overflow="auto"
                    height={"100%"}
                  >
                    <Text fontWeight={"bold"}>Low Battery</Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        handleZoneClick({ low_battery_status: 2 });
                      }}
                    >
                      Total: {zonesStats?.totalCounts?.lowBattery?.count}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem w="100%" height={"100%"}>
                  <Box
                    borderRadius="1rem"
                    backgroundColor="white"
                    py="3rem"
                    px="2rem"
                    overflow="auto"
                    height={"100%"}
                  >
                    <Text fontWeight={"bold"}>MIL</Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        handleZoneClick({ mil: 0 });
                      }}
                    >
                      Total: {zonesStats?.totalCounts?.mil?.count}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem w="100%" height={"100%"}>
                  <Box
                    borderRadius="1rem"
                    backgroundColor="white"
                    py="3rem"
                    px="2rem"
                    overflow="auto"
                    height={"100%"}
                  >
                    <Text fontWeight={"bold"}>Total Duration</Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        handleZoneClick({});
                      }}
                    >
                      Total: {zonesStats?.totalCounts?.totalVehicles?.count}
                    </Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        handleZoneClick({ totalVehiclesOverdue: 0 });
                      }}
                    >
                      Overdue:{" "}
                      {zonesStats?.totalCounts?.totalVehiclesOverdue?.count}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem w="100%"></GridItem>
                <GridItem w="100%"></GridItem>
              </SimpleGrid>
            </Box>
          )}
        </>
      )}

      {lot_id && (
        <InventoryFilters
          zones={zonesStats?.data}
          filters={filters}
          handleFiltersInputChange={handleFiltersInputChange}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
          inventoryIsLoading={inventoryIsLoading}
        />
      )}

      {lot_id && (
        <InventoryGoogleMaps
          zones={zones?.zones}
          lot={lots?.lots?.find((item) => item.lot_id === lot_id)}
          inventoryList={inventoryList}
        />
      )}

      {lot_id && (
        <Box mt="5rem">
          <Text fontWeight={"bold"} fontSize="2xl" mb={"2rem"}>
            Search Results
          </Text>
          <Box
            borderRadius="1rem"
            backgroundColor="white"
            py="3rem"
            px="2rem"
            overflow="auto"
          >
            <InventoryTableContainer inventoryList={inventoryList} />
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};

const Inventory = () => {
  return (
    <DashboardContextProvider
      endpoints={{
        getUserLots: "/getUserLots",
        getZones: "/getZones",
        getDevices: "/getDevices",
        updateDevice: "/updateDevice",
        createZone: "/createZone",
        updateZone: "/updateZone",
        deleteZone: "/deleteZone",
        getZonesStats: "/getZonesStats",
        getInventoryList: "/getInventoryList",
      }}
    >
      <InventoryHomeContent />
    </DashboardContextProvider>
  );
};

export { Inventory };
