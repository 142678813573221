import React, { useEffect, useState } from "react";
import borderMain from "assets/new_report/border.png";
import Pie from "assets/new_report/pie.png";
import needle from "assets/new_report/needle.png";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
// import "./graph.scss";

function PieChart({
  risk,
  heading,
  width,
  tooltipDescription,
  vehicleData,
  styles = {},
}) {
  const [rotation, setRotation] = useState(-80);
  const [badBattery, setBadBattery] = useState(false);
  let { brick_status } = vehicleData;
  useEffect(() => {
    if (brick_status) {
      brick_status?.forEach((item) => {
        if (item >= 8 && item <= 100000) {
          setBadBattery(true);
        }
      });
    }
  }, [brick_status]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      let risk1 = risk;

      if (risk1 <= 0.1) {
        setRotation(-80);
      } else if (risk1 <= 0.2) {
        setRotation(-50);
      } else if (risk1 <= 0.33) {
        setRotation(-35);
      } else if (risk1 <= 0.4) {
        setRotation(-20);
      } else if (risk1 <= 0.5) {
        setRotation(0);
      } else if (risk1 <= 0.66) {
        setRotation(30);
      } else if (risk1 <= 0.7) {
        setRotation(40);
      } else if (risk1 <= 0.8) {
        setRotation(55);
      } else if (risk1 <= 0.9) {
        setRotation(70);
      } else {
        setRotation(90);
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [risk]);

  let getAnimationText = (risk) => {
    if (
      !badBattery &&
      vehicleData?.make === "Tesla" &&
      parseFloat(vehicleData?.test_soc) * 100 < 50
    ) {
      return "Unknown";
    }
    let animation = "Low";
    if (risk > 0.66 && risk <= 1) {
      animation = "High";
    } else if (risk >= 0.33 && risk <= 0.66) {
      animation = "Medium";
    }
    return animation;
  };

  return (
    <div className="pie_wrap" style={{ ...styles }}>
      <div className="heading">
        <div style={{ display: "flex" }}>
          <h3>
            {heading}

            <Tooltip label={tooltipDescription}>
              <Icon
                icon={infoFill}
                style={{ color: "black", width: 16, height: 16 }}
              />
            </Tooltip>
          </h3>
        </div>
        <img src={borderMain} className="borderImage" alt="" />
      </div>
      <div className="pie_holder">
        <div className="pie_container">
          <img className="pie_chart" style={{ width }} src={Pie} alt="" />
          <span className="upper_niddle_text">{getAnimationText(risk)}</span>
          <div className="needle_container">
            <img
              style={{ transform: `rotate(${rotation}deg)` }}
              className="pie_needle"
              src={needle}
              alt=""
            />
            <div className="needle_base"> </div>
          </div>
        </div>
      </div>
      <div
        className="pie_holder"
        style={{ justifyContent: "space-between", marginTop: -40 }}
      >
        <span className="range_text">LOW</span>
        <span className="range_text">HIGH</span>
      </div>
    </div>
  );
}

export default PieChart;
