import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import shadows, { customShadows } from "./shadows";
import getPalettes from "./palette";
import shape from "./shape";
import componentsOverride from "./overrides";

const createTheme = (name = "LIGHT", brand_theme) => {
  let { PRIMARY, SECONDARY, INFO, SUCCESS, WARNING, ERROR } = brand_theme ?? {};
  let themeConfig = variants.find((variant) => variant.name === name);

  let isLight = themeConfig.name === "DARK" ? false : true;

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  const theme = createMuiTheme(
    {
      shape,
      typography,
      breakpoints,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight
        ? { ...customShadows(brand_theme)?.light }
        : { ...customShadows(brand_theme)?.dark },
      palette: isLight
        ? {
            ...getPalettes(PRIMARY, SECONDARY, INFO, SUCCESS, WARNING, ERROR)
              .light,
            mode: "light",
          }
        : {
            ...getPalettes(PRIMARY, SECONDARY, INFO, SUCCESS, WARNING, ERROR)
              .dark,
            mode: "dark",
          },
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
  theme.components = componentsOverride(theme);
  return theme;
};

export default createTheme;
