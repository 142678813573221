import React from "react";
import { Box, Text } from "@chakra-ui/react";

const ContentLayout = (props) => {
  return (
    <Box>
      <Text
        fontSize="3xl"
        color="gray.900"
        fontWeight="600"
        mb={["2rem", "4rem"]}
      >
        {props.title}
      </Text>

      {props.children}
    </Box>
  );
};

export { ContentLayout };
