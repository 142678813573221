export default {
  Button: {
    baseStyle: {
      fontWeight: "normal",
    },
    sizes: {
      md: {
        fontSize: "1.4rem",
        px: "1.5rem",
        py: "1.5rem",
      },
    },
  },
};
