export const toSeoUrl = (url) => {
  return url
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "-")
    .replace(/&/g, "-and-")
    .replace(/-+/g, "-")
    .replace(/^-*/, "")
    .replace(/-*$/, "");
};
