import useSWR from "swr";

const useVehicleRatings = ({ endpoints }) => {
  const {
    data: vehicleRatingsStatistics,
    mutate: mutateVehicleRatingsStatistics,
  } = useSWR(endpoints.getVehicleRatingsStatistics);
  const { data: ratingsTrendStatistics, mutate: mutateRatingsTrendStatistics } =
    useSWR(endpoints.getRatingsTrendStatistics);

  return {
    vehicleRatingsStatistics,
    ratingsTrendStatistics,
    mutateVehicleRatingsStatistics,
    mutateRatingsTrendStatistics,
  };
};

export { useVehicleRatings };
