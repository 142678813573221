import React, { memo, useEffect, useState } from "react";
import { Grid, GridItem, VStack } from "@chakra-ui/react";
import StateOfHealth from "../new_report/mygraphs/StateOfHealth";
import PieChart from "../new_report/mygraphs/PieChart";
import CellTemperatureImbalance from "../new_report/mygraphs/CellTemperatureImbalance";
import CarHeatMap from "../new_report/CarHeatMap";
import CarHeatMapDetails from "components/new_report/CarHeatMapDetails";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
import "../new_report/mygraphs/graph.scss";

function BatteryDetailsMemorized({ vehicleData }) {
  const { brick_status } = vehicleData;
  const [badBattery, setBadBattery] = useState(false);

  useEffect(() => {
    if (brick_status) {
      brick_status?.map((item) => {
        if (item >= 8 && item <= 100000) {
          setBadBattery(true);
        }
      });
    }
  }, [brick_status]);
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
      <GridItem colSpan={1} w="100%">
        <VStack spacing={8} mt="30px">
          {badBattery && (
            <div
              style={{ display: "flex", padding: 8, border: "5px red solid" }}
            >
              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                Alert - Serious Battery Issue
              </p>
              <Tooltip
                label={
                  "This battery has a high likelihood of 1 or more failed cells, which may severely limit its charge limit and remaining battery life. It should be further evaluated by the manufacturer or a trained professional."
                }
              >
                <Icon
                  icon={infoFill}
                  style={{ color: "black", width: 16, height: 16 }}
                />
              </Tooltip>
            </div>
          )}
          {vehicleData?.soh && vehicleData?.soh != 1 && (
            <StateOfHealth
              width={260}
              value={(parseFloat(vehicleData?.soh) * 100).toFixed()}
              rangeText={"%"}
              heading={"STATE OF HEALTH"}
              tooltipDescription={
                "The State of Health shown here is reported directly from the manufacturer battery management system (BMS) or calculated based on the BMS report of the battery’s current capacity or similar measure."
              }
            />
          )}

          <PieChart
            vehicleData={vehicleData}
            risk={
              Math.max(-0.349 * parseFloat(vehicleData?.soh) + 0.2875, 0) /
              0.2875
            }
            width={180}
            heading={"MPG LOSS"}
            tooltipDescription={
              "As hybrid batteries decline, they become less effective at substituting battery power for engine power. According to a study by Department of Mechanical and Aerospace Engineering (DIMEAS) and others, hybrid vehicles with a low SOH (state of health) had a MPG decrease of up to 25%. We estimate the impact to your vehicle's MPG based on your vehicle's SOH."
            }
          />

          <PieChart
            vehicleData={vehicleData}
            risk={
              Math.max(-0.515 * parseFloat(vehicleData?.soh) + 0.4435, 0) /
              0.4435
            }
            width={180}
            heading={"ACCELERATION LOSS"}
            tooltipDescription={
              "As hybrid batteries decline, they become less effective at supporting vehicle acceleration. According to a study by Department of Mechanical and Aerospace Engineering (DIMEAS) and others, hybrid vehicles with a low SOH (state of health) had an acceleration decrease of up to 35.7%. We estimate the impact to your vehicle's acceleration based on your vehicle's SOH."
            }
          />

          <PieChart
            vehicleData={vehicleData}
            risk={parseFloat(vehicleData?.replacement_risk)}
            width={180}
            heading={"PREMATURE FAILURE RISK"}
            tooltipDescription={
              "vsNew's Premature Failure Risk predicts the likelihood of a premature battery failure in the next 12 months. We focus on 2 key risks - cell voltage imbalances and cell temperature imbalances. In these cases, the vehicle's Battery Management System (BMS) will severely limit the range of the vehicle, regardless of the overall battery condition. Overall battery degradation is not a part of this analysis."
            }
          />

          <CellTemperatureImbalance
            risk={parseInt(vehicleData?.temp_balance ?? 0)}
            width={260}
            heading={"CELL TEMPERATURE IMBALANCE"}
            tooltipDescription={
              "OEMs place temperature sensors throughout the battery pack to protect against battery damage. Large variations or imbalance in these measurements can act as an early warning of battery failure."
            }
          />
        </VStack>
      </GridItem>
      <GridItem colSpan={2} w="100%">
        <CarHeatMapDetails vehicleData={vehicleData} />
        <CarHeatMap
          vehicleData={vehicleData}
          tooltipDescription={
            "Hybrid vehicle batteries are typically made up of 16-96 modules, each containing a collection of individual cells. We evaluate each module of the hybrid battery and determine voltage balance and potential degradation. The test determines each module's condition as health, fair, or failing. While some imbalance is normal, especially after multiple shallow charges cycles or extended time without charging or usage, we are focused on individual modules that are statistically significantly lower than others."
          }
        />
      </GridItem>
    </Grid>
  );
}

const BatteryDetails = memo(BatteryDetailsMemorized);
export default BatteryDetails;
