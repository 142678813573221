import React, { useEffect } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";

import { useUserContext } from "../../context";

import ForgotLottie from "assets/lotties/forgot.lottie.json";
import vsNEW_4_ForgotPassword_V2 from "assets/gifs/vsNEW_4_ForgotPassword_V2.gif";
import { AuthenticationLayout } from "../../components/Layouts/AuthenticationLayout";
import { ForgotPasswordSubmitForm } from "../../components/Forms/ForgotPasswordSubmitForm";

const ForgotPasswordSubmit = () => {
  const { forgotPasswordSubmit } = useUserContext();
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    const { code, username } = getQueryParams();

    if (code == null || username == null) {
      history.push("/login");
      toast({ title: "The url is invalid", status: "error" });
    }
  }, [location]);

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const code = query.get("code");
    const username = query.get("username");

    return { code, username };
  };

  const handleSubmit = (credentials) => {
    return forgotPasswordSubmit({ ...credentials, ...getQueryParams() });
  };

  return (
    <AuthenticationLayout
      title="Reset Password"
      gif={vsNEW_4_ForgotPassword_V2}
      animationData={ForgotLottie}
    >
      <Box textAlign="center" mt="1.8rem">
        <Text color="gray.900" fontWeight="bold" fontSize="2.2rem">
          Reset Your Password
        </Text>

        <Text color="gray.400" fontSize="1.4rem">
          Enter a new password below
        </Text>
      </Box>

      <Box mt="1.6rem">
        <ForgotPasswordSubmitForm onSubmit={handleSubmit} />
      </Box>
    </AuthenticationLayout>
  );
};

export { ForgotPasswordSubmit };
