import React, { useMemo } from "react";
import { Text, Box } from "@chakra-ui/react";

import * as Styles from "./ReportCard.styles";
import SlowChargingPieChart from "components/_reports/slowChargingChart";
import ReplacementRiskPieChart from "components/_reports/replacementRiskChart";

const ReportCard = ({
  children,
  value,
  decimalPlaces = 0,
  comparedValue,
  hideCompared,
  unit,
  percentage,
  title,
  iconSrc,
  noText,
  evCharging,
  replacementRisk,
  ac_charging,
  dc_charging,
  max_voltage_delta,
  failure_threshold,
}) => {
  const percent = useMemo(() => {
    const min = Math.min(value, comparedValue);
    const max = Math.max(value, comparedValue);

    const v = Math.round((min * 100) / max);

    return isNaN(v) ? 0 : v;
  }, [percentage, value, comparedValue]);

  return (
    <Styles.Container>
      {iconSrc && <img src={iconSrc} alt={title} width="60" height="60" />}
      <Text fontWeight="bold" fontSize="xl" color="#4D4D4D">
        {title}
      </Text>

      <Text fontSize="xl" fontWeight="bold">
        <Text as="span" fontSize="4xl">
          {typeof value === "number" ? value?.toFixed(decimalPlaces) : value}
        </Text>{" "}
        {unit}{" "}
        {comparedValue == null || hideCompared
          ? ""
          : `/ ${
              typeof comparedValue === "number"
                ? comparedValue?.toFixed(decimalPlaces)
                : comparedValue
            } NEW`}
      </Text>

      {evCharging && (
        <>
          <SlowChargingPieChart
            ac_charging={ac_charging}
            dc_charging={dc_charging}
          />
          <Text fontSize="3xl" fontWeight="bold">
            {`${((ac_charging / (dc_charging + ac_charging)) * 100).toFixed(
              0
            )}% `}
            Slow Charging
          </Text>
        </>
      )}

      {replacementRisk && (
        <>
          <ReplacementRiskPieChart
            max_voltage_delta={max_voltage_delta}
            failure_threshold={failure_threshold}
          />
          <Text fontSize="3xl" fontWeight="bold">
            {`${((1 - max_voltage_delta / failure_threshold) * 100).toFixed(
              0
            )}% `}
            <Text as="span" color="red.500">
              vs
            </Text>
            New
          </Text>
        </>
      )}

      {!noText && (
        <Text fontSize="3xl" fontWeight="bold">
          {`${percent}% `}
          <Text as="span" color="red.500">
            vs
          </Text>
          New
        </Text>
      )}
      {children != null && <Box>{children}</Box>}
    </Styles.Container>
  );
};

export { ReportCard };
