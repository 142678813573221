import React from "react";
import { Box, Text } from "@chakra-ui/react";

import { useUserContext } from "context";
import LoginLottie from "assets/lotties/login.lottie.json";
import vsNEW_4_Login from "assets/gifs/vsNEW_4_Login.gif";

import { AuthenticationLayout } from "components/Layouts/AuthenticationLayout";
import { LoginForm } from "components/Forms/LoginForm";
import { HaveAnAccountLink } from "components/Links/HaveAnAccountLink";

const Login = () => {
  const { login } = useUserContext();

  return (
    <AuthenticationLayout
      title="Log In"
      animationData={LoginLottie}
      gif={vsNEW_4_Login}
    >
      <Box textAlign="center" mt="1.8rem">
        <Text color="gray.900" fontWeight="bold" fontSize="2.2rem">
          Log In
        </Text>

        <Text color="gray.400" fontSize="1.4rem">
          Welcome back!
        </Text>
      </Box>

      <Box mt="4.2rem">
        <LoginForm onSubmit={login} />
      </Box>

      <Box mt="1.65rem" textAlign="center">
        <HaveAnAccountLink
          text="Don’t have an account?"
          action="Sign up"
          href="/register"
        />
      </Box>
    </AuthenticationLayout>
  );
};

export { Login };
