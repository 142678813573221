import { useState, useRef, useEffect } from "react";
import useSWR from "swr";
import pickBy from "lodash/pickBy";
import { useToast } from "@chakra-ui/react";

import { ApiService } from "services";

const LIMIT = 10;

const getVehiclesQueryBuilder = (status, filters, page, args) => {
  const { endpoints } = args;
  let queryString = `?status=${status}&page=${page}&limit=${LIMIT}`;

  if (!endpoints.getVehicles) return null;

  if (filters != null) {
    const searchParams = new URLSearchParams(filters);
    const queryStringParams = new URLSearchParams(searchParams).toString();
    queryString += queryStringParams.length > 0 ? `&${queryStringParams}` : "";
  }

  return `${endpoints.getVehicles}${queryString}`;
};

const useVehicles = (status, endpoints = {}) => {
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(1);
  const { data, mutate } = useSWR(() =>
    getVehiclesQueryBuilder(status, filters, page, { endpoints })
  );
  const toast = useToast();

  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const handleNext = () => {
    setPage((prevPage) => {
      const totalItems = Number(data.totalItems);
      const totalPages = Math.ceil(totalItems / LIMIT);
      const nextPage = prevPage + 1;

      if (nextPage > totalPages) {
        return prevPage;
      }

      return nextPage;
    });
  };

  const handleBack = () => {
    setPage((prevPage) => {
      const nextPage = prevPage - 1;

      if (nextPage < 1) {
        return prevPage;
      }

      return nextPage;
    });
  };

  const handleFilter = async (args) => {
    if (args == null) {
      setFilters(null);
      return;
    }

    const notEmptyFilters = pickBy(args, (x) => x != null && x !== "");
    setFilters(notEmptyFilters);
  };

  const handleUpdateVehicle = async (id, payload) => {
    if (id == null) {
      return;
    }

    ApiService.put(endpoints.updateVehicle, {
      id,
      ...payload,
    })
      .then((response) => {
        toast({
          title: "You have successfuly updated the vehicle",
          status: "success",
        });

        const updatedVehicles = data?.data?.map((x) => {
          if (x.id === id) {
            return {
              ...x,
              ...(response?.data ?? {}),
            };
          }

          return x;
        });

        mutate(updatedVehicles);
      })
      .catch(() => {
        toast({
          title: "Something went wrong wile updating the vehicle",
          status: "error",
        });
      });
  };

  return {
    data,
    dataRef,
    mutate,
    handleNext,
    handleBack,
    handleFilter,
    handleUpdateVehicle,
    filters,
  };
};

export { useVehicles };
