import React from "react";
import { Button, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { InputController } from "components/Controllers/InputController";
import { SelectController } from "components/Controllers/SelectController";
import { CAStates, UKStates, USStates } from "constants/Countries";

// Regular expression for validating an email address
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Custom validation method to test each email in the comma-separated list
const commaSeparatedEmails = yup
  .string()
  .nullable()
  .test(
    "comma-separated-emails",
    "One or more email addresses are invalid",
    (value) => {
      // Split the string into an array of potential emails
      const emails = value?.split(",") ?? [];
      // Check each email in the array
      for (const email of emails) {
        if (!emailRegex.test(email.trim())) {
          // If any email does not match the regex, return false
          return false;
        }
      }
      // If all emails pass the regex test, return true
      return true;
    }
  );

const schema = yup.object().shape({
  name: yup.string().required("This field is required"),
  address: yup.string().required("This field is required"),
  dealerName: yup.string().required("This field is required"),
  dealerWebsite: yup.string().required("This field is required"),
  address2: yup.string(),
  city: yup.string().required("This field is required"),
  state: yup.string().required("This field is required"),
  zip: yup.string().required("This field is required"),
  report_emails: commaSeparatedEmails,
});

const ChangeAccountInfoForm = (props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      address: "",
      address2: "",
      dealerName: "",
      dealerWebsite: "",
      report_emails: "",
      ...props.user,
    },
  });

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <InputController
        name="name"
        placeholder="Your Name"
        label="Your Name"
        error={errors?.name}
        control={control}
      />

      <Box mt="2.6rem">
        <InputController
          name="dealerName"
          placeholder="Business Name"
          label="Business Name"
          error={errors?.dealerName}
          control={control}
        />
      </Box>

      <Box mt="2.6rem">
        <InputController
          name="dealerWebsite"
          placeholder="Business Website"
          label="Business Website"
          error={errors?.dealerWebsite}
          control={control}
        />
      </Box>

      <Box mt="2.6rem">
        <InputController
          name="address"
          label="Address 1"
          placeholder="Address 1"
          error={errors?.address}
          control={control}
        />
      </Box>

      <Box mt="2.6rem">
        <InputController
          name="address"
          label="Address 2 (optional)"
          placeholder="Address 2 (optional)"
          error={errors?.address}
          control={control}
        />
      </Box>

      <Box mt="2.6rem">
        <InputController
          name="city"
          label="City"
          placeholder="City"
          error={errors?.address}
          control={control}
        />
      </Box>

      <Box mt="2.6rem">
        <SelectController
          control={control}
          name={"state"}
          label={
            props?.user?.country == "USA"
              ? "State"
              : props?.user?.country == "CA"
              ? "Province"
              : "Locality"
          }
          // fontSize="1.8rem"
          options={
            <>
              <option disabled value={""} placeholder="Select">
                Select
              </option>
              {(props?.user?.country == "USA" ||
                props?.user?.country == "" ||
                props?.user?.country == null) && (
                <>
                  {USStates?.map((item, index) => (
                    <option key={index} value={item?.abbreviation}>
                      {item?.abbreviation} - {item.name}
                    </option>
                  ))}
                </>
              )}
              {props?.user?.country == "CA" && (
                <>
                  {CAStates?.map((item, index) => (
                    <option key={index} value={item?.abbreviation}>
                      {item?.abbreviation} - {item.name}
                    </option>
                  ))}
                </>
              )}
              {props?.user?.country == "UK" && (
                <>
                  {UKStates?.map((item, index) => (
                    <option key={index} value={item?.abbreviation}>
                      {item?.abbreviation} - {item.name}
                    </option>
                  ))}
                </>
              )}
            </>
          }
          onChange={(value) => setValue("country", value)}
          error={errors?.state}
        />
      </Box>

      <Box mt="2.6rem">
        <InputController
          name="country"
          label="Country"
          placeholder="Country"
          error={errors?.country}
          control={control}
          disabled={true}
        />
      </Box>

      <Box mt="2.6rem">
        <InputController
          name="zip"
          label={
            props?.user?.country == "USA"
              ? "Zipcode"
              : props?.user?.country == "CA"
              ? "Postal Code"
              : "Post Code"
          }
          placeholder={
            props?.user?.country == "USA"
              ? "Zipcode"
              : props?.user?.country == "CA"
              ? "Postal Code"
              : "Post Code"
          }
          error={errors?.country}
          control={control}
        />
      </Box>

      <Box mt="2.6rem">
        <InputController
          name="report_emails"
          label="Additional Email Addresses to Receive Reports "
          placeholder="Add comma separated email addresses"
          error={errors?.report_emails}
          control={control}
        />
      </Box>

      <Button
        type="submit"
        isFullWidth
        colorScheme="red"
        borderRadius="0.4rem"
        mt="3.2rem"
        fontSize="1.4rem"
        py="1.6rem"
        isLoading={isSubmitting}
      >
        Change Information
      </Button>
    </form>
  );
};

ChangeAccountInfoForm.defaultValues = {
  defaultValues: {},
};

export { ChangeAccountInfoForm };
