import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
} from "@chakra-ui/react";

import { MenuIcon } from "../../assets/icons/MenuIcon";
import { useUserContext } from "context";

const NavbarMobile = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const { user } = useUserContext();

  const LINKS = useMemo(() => {
    const {
      ice_access,
      ev_access,
      inventory_access,
      admin_access,
      billing_access,
    } = user;

    const navLinks = [];

    if (ice_access > 0) {
      navLinks.push({
        url: "/",
        name: "ICE",
      });
    }

    if (ev_access > 0) {
      navLinks.push({
        url: "/battery",
        name: "Battery",
      });
    }

    if (inventory_access > 0) {
      navLinks.push({
        url: "/inventory",
        name: "Inventory",
        isDisabled: false,
      });
    }

    if (admin_access > 0) {
      navLinks.push({
        url: "/admin",
        name: "Admin",
        isDisabled: false,
      });
    }

    if (billing_access > 0) {
      navLinks.push({
        url: "/billing",
        name: "Billing",
        isDisabled: true,
      });
    }

    navLinks.push({
      url: "/settings",
      name: "Settings",
    });

    return navLinks;
  }, [user]);

  return (
    <div>
      <IconButton icon={<MenuIcon />} variant="ghost" onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent margin="0" padding="0" borderRadius="0">
          <ModalCloseButton />
          <ModalBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            lineHeight="2rem"
          >
            {LINKS.map((link) => (
              <Link key={link.url} to={link.url}>
                <Button
                  size="lg"
                  colorScheme="black"
                  variant="button"
                  isActive={link.url === pathname}
                  isDisabled={link.isDisabled}
                  _active={{
                    fontWeight: "bold",
                  }}
                >
                  {link.name}
                </Button>
              </Link>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export { NavbarMobile };
