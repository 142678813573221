import React from "react";
import { Box, Button, SimpleGrid } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { InputController } from "../../Controllers/InputController";

import * as Styles from "./CompletedVehiclesFilterForm.styles";
import { SelectController } from "components/Controllers/SelectController";
import { useUserContext } from "context";

const INITIAL_STATE = {
  vin: undefined,
  make: undefined,
  model: undefined,
  year: undefined,
  score: undefined,
};

const CompletedVehiclesFilterForm = (props) => {
  const { user, associatedDealers } = useUserContext();

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      ...(props.defaultValues ?? {}),
    },
  });

  const handleReset = (e) => {
    reset(INITIAL_STATE);
    handleSubmit(props.onSubmit)(e);
  };

  return (
    <Styles.Form onSubmit={handleSubmit(props.onSubmit)}>
      <InputController
        name="vin"
        placeholder="VIN"
        error={errors?.vin}
        control={control}
      />

      <Box mt="1.6rem">
        <InputController
          name="make"
          placeholder="Make"
          error={errors?.make}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <InputController
          name="model"
          placeholder="Model"
          error={errors?.model}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <InputController
          name="year"
          placeholder="Year"
          error={errors?.year}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <InputController
          name="score"
          placeholder="Score"
          error={errors?.score}
          control={control}
        />
      </Box>

      <Box mt="1.6rem">
        <SelectController
          control={control}
          name={"tested_duration"}
          placeholder={"Date Range"}
          // fontSize="1.8rem"
          options={
            <>
              <option value={""} placeholder="All">
                All
              </option>
              <option value={30} placeholder="Last 30 Days">
                Last 30 Days
              </option>
              <option value={90} placeholder="Last 90 Days">
                Last 90 Days
              </option>
            </>
          }
          onChange={(value) => {
            setValue("tested_duration", value);
          }}
          error={errors?.tested}
        />
      </Box>

      {["account_admin", "platform_admin"].includes(user?.role) && (
        <>
          <Box mt="1.6rem">
            <SelectController
              control={control}
              name={"selected_dealer"}
              placeholder={"Accounts"}
              onChange={(value) => {
                setValue("selected_dealer", value);
              }}
              error={errors?.tested}
              options={
                <>
                  <option value={""} placeholder="All">
                    All
                  </option>
                  {associatedDealers?.map((dealer) => (
                    <option
                      key={dealer?.id}
                      value={dealer?.id}
                      placeholder={dealer?.dealership_name}
                    >
                      {dealer?.dealership_name} - {dealer?.name}
                    </option>
                  ))}
                </>
              }
            />
          </Box>
        </>
      )}

      <SimpleGrid mt="auto" columns={2} spacing="2rem">
        <Button
          isFullWidth
          borderRadius="0.4rem"
          fontSize="1.4rem"
          py="1.6rem"
          isLoading={isSubmitting}
          onClick={handleReset}
        >
          Reset
        </Button>

        <Button
          type="submit"
          isFullWidth
          colorScheme="red"
          borderRadius="0.4rem"
          fontSize="1.4rem"
          py="1.6rem"
          isLoading={isSubmitting}
        >
          Filter
        </Button>
      </SimpleGrid>
    </Styles.Form>
  );
};

export { CompletedVehiclesFilterForm };
