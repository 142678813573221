import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";

import { ApiService } from "../services";

import { LoadingLayout } from "../components/Layouts/LoadingLayout";

const UserContextProvider = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [associatedDealers, setAssociatedDealers] = useState([]);
  const toast = useToast();
  const history = useHistory();

  const init = useCallback(async () => {
    try {
      const user = await getCurrentUser();
      setUser(user);
      const payments = await getPaymentMethods();
      setPaymentMethods(payments);

      if (["account_admin", "platform_admin"].includes(user?.role)) {
        const dealers = await getAssociatedDealers();
        setAssociatedDealers(dealers);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const getCurrentUser = async () => {
    const user = await ApiService.get("/getUser");

    if (user.data == null) {
      return null;
    }

    const {
      id,
      email,
      name,
      address,
      city,
      state,
      country,
      zip,
      report_emails,
      dealership_name,
      dealership_website,
      admin_access,
      billing_access,
      ev_access,
      ice_access,
      inventory_access,
      price,
      price_hybrid,
      payment_type,
      stripe_customer_id,
      stripe_payment_method,
      signature,
      signature_timestamp,
      subscription_id,
      partner_code,
      subscription_status,
      temp_unit,
      distance_unit,
      currency,
      role,
    } = user.data;

    return {
      id,
      email,
      name,
      address,
      city,
      state,
      country,
      zip,
      report_emails: report_emails,
      dealerName: dealership_name,
      dealerWebsite: dealership_website,
      admin_access,
      billing_access,
      ev_access,
      ice_access,
      inventory_access,
      price,
      price_hybrid,
      payment_type,
      stripe_customer_id,
      stripe_payment_method,
      signature,
      signature_timestamp,
      subscription_id,
      partner_code,
      subscription_status,
      temp_unit,
      distance_unit,
      currency,
      role,
    };
  };

  const getPaymentMethods = async () => {
    const payments = await ApiService.get("/payment/getPaymentMethods");

    if (!payments?.paymentMethods?.data?.length) {
      return null;
    }

    // console.log("paymentMethods?.data: ", payments?.paymentMethods?.data);
    return payments?.paymentMethods?.data;
  };

  const register = async (credentials) => {
    try {
      await Auth.signUp({
        username: credentials.email,
        password: credentials.password,
        attributes: {
          email: credentials.email,
          name: credentials.name,
          "custom:dealerWebsite": credentials.dealerWebsite,
          "custom:partner_code": credentials.partner_code?.toUpperCase(),
          "custom:country": credentials.country,
        },
      });

      toast({
        title:
          "Your account has been successfuly created! Please check your email for confirmation.",
        status: "success",
      });

      history.push("/login");
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const login = async (credentials) => {
    try {
      await Auth.signIn(credentials.email, credentials.password);
      const user = await getCurrentUser();
      setUser(user);

      const payments = await getPaymentMethods();
      setPaymentMethods(payments);

      if (["account_admin", "platform_admin"].includes(user?.role)) {
        const dealers = await getAssociatedDealers();
        setAssociatedDealers(dealers);
      }
    } catch (error) {
      console.log({ error });

      if (error.code === "UserNotConfirmedException") {
        // Here, you can update the message or handle it as you need
        toast({
          title: "Please check your email and confirm your account",
          status: "error",
        });
      } else {
        // Handle other errors
        toast({ title: error.message, status: "error" });
      }
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setUser(null);

      history.push("/login");
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const deleteUser = async () => {
    try {
      await Promise.all([
        Auth.deleteUser(),
        ApiService.del("/deleteUserAccount"),
      ]);

      setUser(null);

      history.push("/login");
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const forgotPassword = async (credentials) => {
    try {
      await Auth.forgotPassword(credentials.email);

      history.push("/login");

      toast({
        title: "Check your email for the reset password link",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const forgotPasswordSubmit = async (credentials) => {
    try {
      await Auth.forgotPasswordSubmit(
        credentials.username,
        credentials.code,
        credentials.password
      );

      history.push("/login");

      toast({
        title: "Password resetted successfuly! Please log in.",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const verifyAccount = async (args) => {
    try {
      await Auth.confirmSignUp(args.username, args.code);

      history.push("/login");

      toast({
        title:
          "Your account has been successfuly confirmed. You can now login.",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const changePassword = async (credentials) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      if (cognitoUser == null) {
        throw new Error("Invalid user");
      }

      await Auth.changePassword(
        cognitoUser,
        credentials.currentPassword,
        credentials.newPassword
      );

      toast({
        title: "Your password was successfuly changed",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const changeAccountInformation = async (credentials) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      if (cognitoUser == null) {
        throw new Error("Invalid user");
      }

      await Promise.all([
        Auth.updateUserAttributes(cognitoUser, {
          name: credentials.name,
          address: credentials.address,
          "custom:dealerWebsite": credentials.dealerWebsite,
        }),
        ApiService.put("/updateUser", credentials),
      ]);

      // update the user state
      init();

      toast({
        title: "Your information was successfuly changed",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const updateUserPaymentType = async (event) => {
    let isChecked = event?.target.checked;
    let body = {
      payment_type: isChecked ? "paid" : "",
    };
    try {
      await Promise.all([ApiService.put("/user/updateUserPaymentType", body)]);

      // update the user state
      init();

      toast({
        title: "Your payment preference was successfuly changed",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const signAgreement = async (body) => {
    try {
      await Promise.all([ApiService.put("/user/signAgreement", body)]);

      // update the user state
      init();

      toast({
        title: "Agreement was successfuly signed",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const createSubscription = async (body) => {
    try {
      let res = await ApiService.post("/payment/createSubscription", body);
      return res;
    } catch (error) {
      throw new Error(error);
    }
  };

  const removePaymentMethod = async (value) => {
    let body = {
      paymentMethodId: value,
    };
    try {
      await Promise.all([
        ApiService.post("/payment/removePaymentMethod", body),
      ]);

      // update the user state
      init();

      toast({
        title: "Your payment preference was successfuly changed",
        status: "success",
      });
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const attachPaymentMethod = async (value) => {
    let body = {
      paymentMethodId: value,
    };
    try {
      await Promise.all([
        ApiService.post("/payment/attachPaymentMethod", body),
      ]);

      // update the user state
      init();
    } catch (error) {
      console.log({ error });

      toast({ title: error.message, status: "error" });
    }
  };

  const getAssociatedDealers = async () => {
    try {
      const response = await ApiService.get("/user/getAssociatedDealers");

      console.log("response: ", response);
      if (!response?.dealers) {
        return [];
      }

      return response?.dealers;
    } catch (error) {
      console.log({ error });
    }
  };

  if (user == null && isLoading) {
    return <LoadingLayout />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        paymentMethods,
        associatedDealers,
        isLoading,
        setUser,
        init,
        login,
        register,
        logout,
        deleteUser,
        forgotPassword,
        forgotPasswordSubmit,
        verifyAccount,
        changePassword,
        changeAccountInformation,
        updateUserPaymentType,
        removePaymentMethod,
        attachPaymentMethod,
        signAgreement,
        createSubscription,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

const UserContext = createContext({});
const useUserContext = () => useContext(UserContext);

export { UserContextProvider, UserContext, useUserContext };
