import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
//

// ----------------------------------------------------------------------

export default function ChartBar({
  max_voltage_delta = 1,
  failure_threshold = 1,
}) {
  console.log("max_voltage_delta: ", max_voltage_delta);
  console.log("failure_threshold: ", failure_threshold);
  // const CHART_DATA = [
  //   { name: "Max Voltage Delta", data: [fastCharge] },
  //   { name: "Slow (AC)", data: [slowCharge] },
  // ];
  const CHART_DATA = [max_voltage_delta, failure_threshold];

  const chartOptions = {
    labels: ["Max Voltage Delta", "Failure Threshold"],
    legend: {
      show: false,
      position: "bottom",
      // offsetX: -20,
      // offsetY: 64,
      // itemMargin: { vertical: 8 },
    },
    colors: ["#ff0000", "#008000"],
    stroke: { show: false },
    dataLabels: { enabled: false, dropShadow: { enabled: false } },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  };

  return (
    <ReactApexChart
      type="pie"
      series={CHART_DATA}
      options={chartOptions}
      width={350}
      height={188}
    />
  );
}
