import styled from "@emotion/styled";
import { Container as ContainerComponent } from "@chakra-ui/react";

export const Container = styled(ContainerComponent)`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Divider = styled.div`
  width: ${(props) => props.width ?? "100%"};
  border-width: 0.3rem;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.red[500]};
  margin: ${(props) => props.margin ?? `1rem 0rem`};

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: baseline;

  img {
    max-width: 25vw;
    margin-right: 1rem;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 1rem;
  margin-top: 2rem;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const DetailsContentContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  gap: 0.5rem;
  height: fit-content;
`;

export const QrContainer = styled.div`
  position: relative;
`;

export const ReportsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 5rem;
  margin-top: 2rem;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const BadgeContainer = styled.div`
  margin-top: -50%;

  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }
`;

export const EngineIcon = styled.img`
  margin-top: 2rem;
`;
