import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
//

// ----------------------------------------------------------------------

export default function SlowChargingPieChart({
  dc_charging = 1,
  ac_charging = 1,
}) {
  const fastCharge = dc_charging / (dc_charging + ac_charging) ?? 0;
  const slowCharge = ac_charging / (dc_charging + ac_charging) ?? 0;

  const CHART_DATA = [fastCharge, slowCharge];

  const chartOptions = {
    labels: ["Fast (DC)", "Slow (AC)"],
    legend: {
      show: false,
      position: "bottom",
    },
    colors: ["#ff0000", "#008000"],
    stroke: { show: false },
    dataLabels: { enabled: false, dropShadow: { enabled: false } },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  };

  return (
    <ReactApexChart
      type="pie"
      series={CHART_DATA}
      options={chartOptions}
      width={350}
      height={188}
    />
  );
}
