import React, { memo } from "react";
import "./mygraphs/graph.scss";
import moment from "moment";

function EvFooterMemorized({ vehicleTypeTitle, qrCode, vehicleData }) {

  return (
    <>
      <div className="gradient_line"></div>
      <div className="footer_container">
        {/* <div className='logo_footer'>
                    <img src={vsNewImg} alt="" />
                </div> */}
        <div className="info-footer_mobile">
          <div className="test_details">
          <p>
            {/* {vehicleData?.test_timestamp && 
              
                `Tested on ${moment(vehicleData?.test_timestamp).format("MM/DD/YYYY h:mm A")} in ${vehicleData?.city},
                ${vehicleData?.state} at ${parseInt(vehicleData?.test_temp_f)?.toFixed(0)}F with a
                ${addZeroes(vehicleData?.test_soc * 100)}% state of charge.`

            }
            <br></br> */}
              *This report, and any reliance upon it, is subject to our terms of
              service <a href="https://www.vsnew.com/terms" style={{color: "blue"}} rel="noreferrer" target={"_blank"}>(vsnew.com/terms)</a>. Warranty may be extended in select states, including AZ, CA, CT, ME, MD, MA, NJ, NY, OR, RI, VT. Copyright <a href="https://www.vsnew.com/" style={{color: "blue"}} rel="noreferrer" target={"_blank"}>vsNew, Inc.</a> Patents pending.
              {vehicleData?.device && vehicleData?.firmware_version && vehicleData?.app_version &&<><br/><br/>
              {vehicleData?.device} {vehicleData?.firmware_version} {vehicleData?.app_version} {vehicleData?.ev_battery_test_id}</>}
            </p>
          </div>
         
            
            <div style={{ display: "flex", justifyContent: 'space-between' }}>  
              <p style={{paddingRight: "10px"}}>Scan here for more details on your {vehicleTypeTitle} battery health.
              <br></br>
              {process.env.REACT_APP_URL}/{vehicleTypeTitle?.toLowerCase()}/{vehicleData?.vin}-{vehicleData?.year}-{vehicleData?.make}-{vehicleData?.model}</p>
              <img width={64} src={qrCode} alt="" />
            </div>

           
        </div>
      </div>
    </>
  );
}

function addZeroes(num) {
  return num.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 1})
}

const EvFooter = memo(EvFooterMemorized);
export default EvFooter;
