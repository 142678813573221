import { Tooltip, useMediaQuery } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
import ReactApexChart from "react-apexcharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import HC_more from "highcharts/highcharts-more";

HC_more(Highcharts);
highchartsDumbbell(Highcharts);

export default function CellVoltage({ vehicleData, tooltipDescription = "" }) {
  const [isMobile] = useMediaQuery("(max-width: 450px)");
  const { brick_voltage, brick_status, vehicle_type } = vehicleData;

  if (!vehicle_type || !brick_voltage) {
    if (!brick_status && brick_voltage?.length > 4) return null;
  }

  const new_brick_voltage = brick_voltage?.map((item) =>
    parseFloat(parseFloat(item)?.toFixed(3))
  );
  const expected_brick_voltage = new Array(brick_voltage?.length)
    .fill(calculateNewAverage(brick_voltage))
    ?.map((item) => parseFloat(item));

  console.log("brick_voltage: ", new_brick_voltage);
  console.log("expected_brick_voltage: ", expected_brick_voltage);
  // console.log("brick_status: ", brick_status);
  // console.log("expected_brick_voltage: ", expected_brick_voltage);

  let colors = [];
  brick_status?.map((value) => {
    if (value >= -100000 && value <= -1) {
      colors.push("#08724e");
    } else if (value >= 0 && value <= 3) {
      colors.push("#51a986");
    } else if (value >= 4 && value <= 7) {
      colors.push("#feca00");
    } else if (value >= 8 && value <= 100000) {
      colors.push("#ff0000");
    }
  });

  let chartOptions = {};
  let chartOptions_hybrid = {};
  let dumbbellChartOptions = {};

  if (brick_voltage && brick_voltage.length == 4) {
    dumbbellChartOptions = {
      chart: {
        height: 450,
        width: isMobile ? 320 : null,
        type: "dumbbell",
        inverted: true,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          style: {
            fontSize: "16px", // Adjust the font size for x-axis labels
          },
        },
      },
      yAxis: {
        title: {
          text: "Voltages",
          style: {
            fontSize: "16px", // Adjust the font size for x-axis labels
          },
        },
        labels: {
          style: {
            fontSize: "16px", // Adjust the font size for x-axis labels
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "18px", // Adjust the font size for tooltips
        },
        formatter: function () {
          return (
            "Minimum Cell Voltage: " +
            "<b>" +
            this.point.low +
            "V" +
            "</b>" +
            "<br/>Maximum Cell Voltage: " +
            "<b>" +
            this.point.high +
            "V" +
            "</b>"
          );
        },
      },
    };

    dumbbellChartOptions.series = [
      {
        name: "",
        data: [
          {
            name: "Healthy Minimum and Maximum Cell Voltage",
            low: parseFloat(brick_voltage[2]),
            high: parseFloat(brick_voltage[3]),
          },
        ],
        marker: {
          fillColor: "#51A986",
          radius: 8,
        },
        lowColor: "#51A986",
        connectorWidth: 4,
        connectorColor: "#51A986",
      },
      {
        name: "",
        data: [
          {
            name: "Current Minimum and Maximum Voltage",
            low: parseFloat(brick_voltage[0]),
            high: parseFloat(brick_voltage[1]),
          },
        ],
        marker: {
          fillColor: "#000000",
          radius: 8,
        },
        lowColor: "#000000",
        connectorWidth: 4,
        connectorColor: "#000000",
      },
    ];
  }

  if (brick_voltage?.length > 4) {
    chartOptions = {
      chart: {
        height: 450,
        width: isMobile ? 320 : null,
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        column: {
          // pointWidth: 15,
        },
        spline: {
          lineWidth: 4,
        },
      },
      legend: {
        itemStyle: {
          fontSize: "1.5em",
        },
      },
      xAxis: {
        labels: {
          style: {
            fontSize: "1.5em",
          },
        },
      },
      yAxis: [
        {
          // Primary yAxis

          title: {
            text: "Voltage in V",
          },
          labels: {
            style: {
              fontSize: "1.5em",
            },
            formatter: function () {
              return this.value?.toFixed(3);
            },
          },
          min: getMin(brick_voltage),
          max: getMax(brick_voltage),
        },
        {
          // Secondary yAxis
          title: {
            text: "V",
            enabled: false,
          },
          min: getMin(brick_voltage),
          max: getMax(brick_voltage),
          visible: false,
        },
      ],
      tooltip: {
        shared: true,
        // formatter: function () {
        //   return this.points.value?.toFixed(2);
        // },
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return (
              s + "<br/>" + point.series.name + ": " + point.y?.toFixed(3) + "V"
            );
          }, "<b>" + this.x + "</b>");
        },
        style: {
          fontSize: "1.5em",
        },
      },
      series: [
        {
          name: "Module Voltage",
          type: "column",
          yAxis: 0,
          data: new_brick_voltage,
          colorByPoint: true,
          colors: colors,
          tooltip: {
            valueSuffix: " V",
          },
        },
        {
          name: "Expected Module Voltage",
          type: "spline",
          yAxis: 1,
          data: expected_brick_voltage,
          color: "#000000",
          tooltip: {
            valueSuffix: " V",
          },
        },
      ],
    };

    chartOptions_hybrid = {
      chart: {
        height: 450,
        width: isMobile ? 320 : null,
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        column: {
          // pointWidth: 15,
        },
        spline: {
          lineWidth: 4,
        },
      },
      legend: {
        itemStyle: {
          fontSize: "1.5em",
        },
      },
      xAxis: {
        labels: {
          style: {
            fontSize: "1.5em",
          },
        },
      },
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: "Voltage in V",
          },
          min: getMin(brick_voltage),
          max: getMax(brick_voltage),
          labels: {
            style: {
              fontSize: "1.5em",
            },
            formatter: function () {
              return this.value?.toFixed(3);
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        style: {
          fontSize: "1.5em",
        },
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return (
              s + "<br/>" + point.series.name + ": " + point.y?.toFixed(3) + "V"
            );
          }, "<b>" + this.x + "</b>");
        },
      },
      series: [
        {
          name: "Module Voltage",
          type: "column",
          data: new_brick_voltage,
          colorByPoint: true,
          colors: colors,
          tooltip: {
            valueSuffix: " V",
          },
        },
      ],
    };
  }

  return (
    <div className="car_heatmap">
      <div className="heatmap_heading">
        <h2 style={{ display: "flex", justifyContent: "center" }}>
          CELL VOLTAGE VALUES{" "}
          <Tooltip label={tooltipDescription}>
            <Icon
              icon={infoFill}
              style={{ color: "black", width: 16, height: 16 }}
            />
          </Tooltip>
        </h2>
      </div>
      {brick_voltage && brick_voltage?.length > 4 && (
        <div className="heatmap_container">
          {vehicle_type == "hybrid" ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions_hybrid}
            />
          ) : (
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          )}
        </div>
      )}

      {brick_voltage && brick_voltage?.length <= 4 && (
        <div className="heatmap_container">
          <HighchartsReact
            highcharts={Highcharts}
            options={dumbbellChartOptions}
          />
        </div>
      )}
    </div>
  );
}

function calculateNewAverage(arr) {
  if (arr.length === 0) {
    return 0; // Handle the case of an empty array
  }

  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += parseFloat(arr[i]);
  }

  return parseFloat(sum / arr.length)?.toFixed(2);
}

function calculateAverage(numbers) {
  var sum = 0;
  var count = 0;

  for (var i = 0; i < numbers.length; i++) {
    var number = parseFloat(numbers[i]);

    if (!isNaN(number)) {
      sum += number;
      count++;
    }
  }

  if (count === 0) {
    return 0; // To avoid division by zero
  }

  return sum / count;
}

const getMin = (brick_voltage) => {
  let avg = calculateAverage(brick_voltage);
  let min = Math.min(...brick_voltage?.map((item) => item));

  if (avg - min > 0.4) {
    return parseFloat((min - 0.1)?.toFixed(2));
  } else {
    return parseFloat((avg - 0.4)?.toFixed(2));
  }
};

const getMax = (brick_voltage) => {
  let avg = calculateAverage(brick_voltage);
  let max = Math.max(...brick_voltage?.map((item) => item));

  if (max - avg > 0.2) {
    return parseFloat((max + 0.1)?.toFixed(2));
  } else {
    return parseFloat((avg + 0.2)?.toFixed(2));
  }
};
