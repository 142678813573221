import React, { useEffect } from "react";
import { Box, Select } from "@chakra-ui/react";

import { DashboardContextProvider, useDashboardContext } from "context";
import { ZonesTableContainer } from "containers/ZonesTableContainer";
// import { SensorsTableContainer } from "containers/SensorsTableContainer";
import { SensorsTableContainer } from "containers/SensorsMuiTableContainer";

import { PageLayout } from "components/Layouts/PageLayout";
import Lots from "./Lots";

const AdminHomeContent = () => {
  const {
    inventoryIsLoading,
    lots = {},
    lot_id,
    setLotId,
    zones,
    handleCreateZone,
    handleUpdateZone,
    handleDeleteZone,
    handleUpdateZoneOrder,
    devices = [],
    handleUpdateDevice,
    completedVehicles,
    pendingVehicles,
    dismissedVehicles,
    vehicleRatingsStatistics,
    ratingsTrendStatistics,
    handleOpenReport,
    handleMoveToCompleted,
    handleMoveToPending,
    handleMoveToDismissed,
  } = useDashboardContext();

  useEffect(() => {
    if (lots?.lots && lots?.lots?.length === 1) {
      setLotId(lots.lots[0]?.lot_id);
    }
  }, [lots]);

  return (
    <PageLayout title="Admin">
      <Box display={"flex"} justifyContent="flex-end">
        <Select
          size="lg"
          variant="filled"
          maxWidth={300}
          value={lot_id ?? ""}
          onChange={(e) => setLotId(e.target.value)}
        >
          <option value="">Select Lot</option>
          {lots?.lots?.map((item) => (
            <option value={item?.lot_id} key={item?.lot_id}>
              {item?.lot_name}
            </option>
          ))}
        </Select>
      </Box>

      <Box mt="3rem">
        {lot_id && (
          <Lots
            lot={lots?.lots?.find((item) => item.lot_id === lot_id)}
            zones={zones?.zones}
          />
        )}
      </Box>

      <Box mt="5rem">
        {lot_id && (
          <ZonesTableContainer
            inventoryIsLoading={inventoryIsLoading}
            lot={lots?.lots?.find((item) => item.lot_id === lot_id)}
            zones={zones?.zones}
            onCreateZone={handleCreateZone}
            onUpdateZone={handleUpdateZone}
            onDeleteZone={handleDeleteZone}
            onUpdateZoneOrder={handleUpdateZoneOrder}
          />
        )}
      </Box>

      <Box mt="5rem">
        {lot_id && (
          <SensorsTableContainer
            zones={zones?.zones}
            data={devices?.devices}
            onUpdateDevice={handleUpdateDevice}
          />
        )}

        {/* // <SensorsTableContainer
          //   zones={zones?.zones}
          //   data={devices?.devices}
          //   onUpdateDevice={handleUpdateDevice}
          // /> */}
      </Box>
    </PageLayout>
  );
};

const Admin = () => {
  return (
    <DashboardContextProvider
      endpoints={{
        getUserLots: "/getUserLots",
        getZones: "/getZones",
        getDevices: "/getDevices",
        updateDevice: "/updateDevice",
        createZone: "/createZone",
        updateZone: "/updateZone",
        deleteZone: "/deleteZone",
      }}
    >
      <AdminHomeContent />
    </DashboardContextProvider>
  );
};

export { Admin };
