import { Auth } from "aws-amplify";

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_CLIENT_ID,
  REACT_APP_AWS_API_NAME,
  REACT_APP_AWS_API_ENDPOINT,
} = process.env;

const amplifyConfig = {
  Auth: {
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    endpoints: [
      {
        name: REACT_APP_AWS_API_NAME,
        endpoint: REACT_APP_AWS_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export { amplifyConfig };
