import React from "react";
import moment from "moment";
import { Box } from "@chakra-ui/react";

function CarHeatMapDetailsScreenshot({ vehicleData }) {
  let values = [
    {
      key: "",
      value: `${vehicleData?.year} ${vehicleData?.make} ${
        vehicleData?.model || ""
      }`,
      unit: "",
    },
    {
      key: "VIN",
      value: vehicleData?.vin,
      unit: "",
    },
    {
      key: "TEST TIME",
      value: vehicleData?.test_timestamp
        ? moment(vehicleData?.test_timestamp).format("MM/DD/YYYY h:mm A")
        : null,
      unit: "",
    },
    {
      key: "TEST LOCATION",
      value: `${vehicleData?.city ? vehicleData?.city + ", " : ""}${
        vehicleData?.state ? vehicleData?.state : ""
      }`,
      unit: "",
    },
    {
      key: "TEST OUTDOOR TEMPERATURE",
      value: vehicleData?.test_temp,
      unit: vehicleData?.temp_unit,
    },
    {
      key: "TEST STATE OF CHARGE",
      value: vehicleData?.test_soc
        ? (parseFloat(vehicleData?.test_soc) * 100)?.toFixed(1) + "%"
        : "N/A",
      unit: "",
    },
    {
      key: "Odometer",
      value: vehicleData?.odometer
        ? numberWithCommas(parseFloat(vehicleData?.odometer ?? 0)?.toFixed(0)) +
          " " +
          vehicleData?.distance_unit
        : "N/A",
      unit: "",
    },
    {
      key: "Battery Manufacturing Date",
      value:
        vehicleData?.vehicle_type == "hybrid"
          ? ""
          : vehicleData?.battery_manufacturing_age
          ? moment(vehicleData?.battery_manufacturing_age).format("MMMM YYYY")
          : "",
      unit: "",
    },
    {
      key: "Battery Manufacturing Status",
      value:
        vehicleData?.vehicle_type == "hybrid"
          ? ""
          : vehicleData?.battery_manufacturing_status ?? "",
      unit: "",
    },
    {
      key: "OEM Battery Warranty*",
      value: vehicleData?.warranty_years
        ? `${vehicleData?.warranty_years} years / ${
            numberWithCommas(
              vehicleData?.distance_unit == "km"
                ? vehicleData?.warranty_km + " " + vehicleData?.distance_unit
                : vehicleData?.warranty_miles + " " + vehicleData?.distance_unit
            ) ?? ""
          }`
        : "N/A",
      unit: "",
    },
    {
      key: "In Service",
      value: vehicleData?.birthday
        ? moment?.unix(vehicleData?.birthday)?.format("MMMM DD, YYYY")
        : "",
    },
  ];

  return (
    <Box className="heatmap_car_details">
      {values
        ?.filter((item) => item?.value ?? false)
        ?.map((item, index) => (
          <React.Fragment key={item?.key}>
            {index == 0 && <h2 className="grayscale">{item?.value}</h2>}

            {index != 0 && (
              <p className={index % 2 == 0 ? "grayscale" : ""}>
                {" "}
                <span> {item?.key} - </span>
                {item?.value} {item?.unit}
              </p>
            )}
          </React.Fragment>
        ))}
    </Box>
  );
}

function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function addZeroes(num) {
  return num.toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: 1,
  });
}

export default CarHeatMapDetailsScreenshot;
