import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  Grid,
  GridItem,
  Select,
  Switch,
  FormControl,
  FormLabel,
  Skeleton,
  Button,
} from "@chakra-ui/react";

import mapIcon from "../../assets/icons/map.png";

import { SimpleTable } from "../../components/Tables/SimpleTable";

import { useUserContext } from "context";
import _ from "lodash";
import moment from "moment-timezone";

import VehicleMakes from "constants/vehicleMakes";

const InventoryFilters = (props) => {
  const filters1 = [
    {
      label: "VIN",
      field: "vin",
    },
    {
      label: "Year",
      field: "year",
    },
    {
      label: "Make",
      field: "make",
    },
    {
      label: "Model",
      field: "model",
    },
  ];

  const filters2 = [
    {
      label: "Zone",
      field: "zone_id",
    },
    {
      label: "Bay",
      field: "bay_name",
    },
  ];

  const filters3 = [
    {
      label: "IEEE",
      field: "ieee",
    },
  ];

  let [years, setYears] = useState([]);
  useEffect(() => {
    let date = moment().startOf("year");
    let dates = [];
    for (let i = 0; date.format("YYYY") >= "2009"; i++) {
      dates.push(date.format());
      date.subtract(1, "year");
    }
    setYears(dates);
  }, []);

  return (
    <Box mt={"5rem"}>
      <Text fontWeight={"bold"} fontSize="2xl" mb={"2rem"}>
        Vehicle Search
      </Text>

      <Box
        borderRadius="1rem"
        backgroundColor="white"
        py="3rem"
        px="2rem"
        overflow="auto"
      >
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          {filters1?.map((item, index) => (
            <GridItem w="100%" key={index}>
              <Box display={"flex"}>
                <Text fontWeight={"bold"} marginRight="3" width={"30%"}>
                  {item?.label}
                </Text>
                {item?.field === "year" ? (
                  <Select
                    bgColor={"white"}
                    // placeholder="Select Year"
                    name={item.field}
                    value={props.filters?.[item?.field] ?? ""}
                    onChange={props.handleFiltersInputChange}
                  >
                    <option value="">Select Year</option>
                    {years.map((i, index) => (
                      <option key={index} value={moment(i).format("YYYY")}>
                        {moment(i).format("YYYY")}
                      </option>
                    ))}
                  </Select>
                ) : item.field === "make" ? (
                  <Select
                    bgColor={"white"}
                    // placeholder="Select Make"
                    name={item.field}
                    value={props.filters?.[item?.field] ?? ""}
                    onChange={props.handleFiltersInputChange}
                  >
                    <option value="">Select Make</option>
                    {VehicleMakes?.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    value={props?.filters?.[item?.field] ?? ""}
                    bgColor={"white"}
                    name={item.field}
                    placeholder={item?.label}
                    onChange={props.handleFiltersInputChange}
                  />
                )}
              </Box>
            </GridItem>
          ))}
        </Grid>
        <Grid templateColumns="repeat(4, 1fr)" gap={6} mt="3">
          {filters2?.map((item, index) => (
            <GridItem w="100%" key={index}>
              <Box display={"flex"}>
                <Text fontWeight={"bold"} marginRight="3" width={"30%"}>
                  {item?.label}
                </Text>
                {item.field === "zone_id" ? (
                  <Select
                    bgColor={"white"}
                    name={item.field}
                    value={props.filters?.[item?.field] ?? ""}
                    onChange={props.handleFiltersInputChange}
                  >
                    <option value="">Select Zone</option>
                    {props?.zones?.map((item, index) => (
                      <option value={item?.zone_id} key={index}>
                        {item?.zone_name}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    value={props?.filters?.[item?.field] ?? ""}
                    bgColor={"white"}
                    name={item.field}
                    placeholder={item?.label}
                    onChange={props.handleFiltersInputChange}
                  />
                )}
              </Box>
            </GridItem>
          ))}
        </Grid>
        <Grid templateColumns="repeat(4, 1fr)" gap={6} mt="3">
          {filters3?.map((item, index) => (
            <GridItem w="100%" key={index}>
              <Box display={"flex"}>
                <Text fontWeight={"bold"} marginRight="3" width={"30%"}>
                  {item?.label}
                </Text>
                <Input
                  value={props?.filters?.[item?.field] ?? ""}
                  bgColor={"white"}
                  name={item.field}
                  placeholder={item?.label}
                  onChange={props.handleFiltersInputChange}
                />
              </Box>
            </GridItem>
          ))}
        </Grid>
        <Box display={"flex"} justifyContent="flex-end">
          <Button
            colorScheme="blue"
            mt={"2rem"}
            mr={"2rem"}
            isLoading={props.inventoryIsLoading}
            onClick={props.handleSearch}
          >
            Search
          </Button>
          <Button
            colorScheme="blue"
            mt={"2rem"}
            disabled={props.inventoryIsLoading}
            onClick={props.handleClearSearch}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { InventoryFilters };
