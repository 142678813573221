import React, { memo, useEffect, useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import vsNewImg from "assets/new_report/vsNewImg.png";
import navRedSvg from "assets/new_report/navbar-right-red.svg";
import navGreenSvg from "assets/new_report/navbar-right-green.svg";
import "./mygraphs/graph.scss";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";

function EvheaderMemorized({
  vehicleTypeTitle,
  vehicleData,
  tooltipDescription,
}) {
  const [bgImage, setBgImage] = useState(navGreenSvg);
  const [badBattery, setBadBattery] = useState(false);
  let { brick_status } = vehicleData;
  useEffect(() => {
    if (brick_status) {
      brick_status?.forEach((item) => {
        if (item >= 8 && item <= 100000) {
          setBgImage(navRedSvg);
          setBadBattery(true);
        }
      });
    }
  }, [brick_status]);

  return (
    <>
      <Grid templateColumns="repeat(6, 1fr)" gap={6}>
        <GridItem colSpan={2} w="100%" style={{ display: "flex" }}>
          {/* <div className="details"> */}
          <a href="https://www.vsnew.com/" style={{ display: "flex" }}>
            <img
              className="nav_logo"
              src={vsNewImg}
              alt=""
              style={{ alignSelf: "center" }}
            />
          </a>
          {/* </div> */}
        </GridItem>
        <GridItem colSpan={3} w="100%" style={{ display: "flex" }}>
          <div className="heading" style={{ alignSelf: "center" }}>
            <h1>
              {vehicleTypeTitle} Battery <br /> <span>Health Report</span>
            </h1>
          </div>
        </GridItem>

        
          <GridItem colSpan={1} w="100%" style={{ display: "flex" }}>
            <div className="nav_info" style={{ alignSelf: "center" }}>
              <img src={bgImage} alt="" />

              <div className="nav_cred">
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "-10px",
                  }}
                >
                  OVERALL{" "}
                  <Tooltip label={tooltipDescription}>
                    <Icon
                      icon={infoFill}
                      style={{ color: "black", width: 16, height: 16 }}
                    />
                  </Tooltip>
                </h2>
                <h2>
                  <span>{!badBattery && vehicleData?.make === "Tesla" && parseFloat(vehicleData?.test_soc) * 100 < 50 ? "--" : Math.round(vehicleData?.score * 100)}%</span>vs. New
                </h2>
              </div>
            </div>
          </GridItem>
         
      </Grid>

      <div className="gradient_line"></div>
    </>
  );
}

const Evheader = memo(EvheaderMemorized);
export default Evheader;
