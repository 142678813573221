export const PLANS = {
  development: {
    STANDARD: {
      MONTHLY: { priceId: "price_1PUXi5An3tDsQyxNmuZcEnam", price: 299 },
    },
    SP149: {
      MONTHLY: { priceId: "price_1PUXsZAn3tDsQyxNLXxDjex7", price: 149 },
    },
    TS: {
      MONTHLY: {
        priceId: "price_1PVdTiAn3tDsQyxNjvZGFrsD",
        price: 100,
      },
    },
    "12MONTH100": {
      ANNUALLY: {
        priceId: "price_1PbBDmAn3tDsQyxNlHpoA8IB",
        price: 1200,
      },
    },
    TEST1: {
      ANNUALLY: {
        priceId: "price_1PeIpkAn3tDsQyxNvMx5EHEQ",
        price: 1,
      },
    },
  },
  production: {
    STANDARD: {
      MONTHLY: {
        priceId: "price_1PUXkyAn3tDsQyxNAIscPAiR",
        price: 299,
      },
    },
    SP149: {
      MONTHLY: { priceId: "price_1PUXtdAn3tDsQyxNBSSrIa09", price: 149 },
    },
    TS: {
      MONTHLY: {
        priceId: "price_1PVdUKAn3tDsQyxNjB3SrKpI",
        price: 100,
      },
    },
    "12MONTH100": {
      ANNUALLY: {
        priceId: "price_1PbCo0An3tDsQyxNdnVMV9xw",
        price: 1200,
      },
    },
    TEST1: {
      ANNUALLY: {
        priceId: "price_1PeJ7wAn3tDsQyxNQ41UHgwT",
        price: 1,
      },
    },
  },
};
