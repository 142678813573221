import React, { useMemo } from "react";
import { IconButton, Image, ButtonGroup } from "@chakra-ui/react";

import { add3Dots, formatDate, getScore } from "utils";

import { TableContainer } from "./TableContainer";

import { ChevronsUpIcon } from "assets/icons/ChevronsUpIcon";

const DismissedVehiclesTableContainer = (props) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Picture",
        accessor: "image_url",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return (
            <Image
              src={value}
              width="10rem"
              height="5rem"
              fallbackSrc="https://via.placeholder.com/100x50"
            />
          );
        },
      },
      {
        Header: "VIN",
        accessor: "vin",
      },
      {
        Header: "Make",
        accessor: "make",
      },
      {
        Header: "Model",
        accessor: (data) => {
          const model = `${data.model ?? ""} ${data.series ?? ""}`.trim();
          return add3Dots(model, 15);
        },
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Sore",
        accessor: "score",
        Cell: ({ cell: { value } }) => {
          if (value == null) {
            return null;
          }

          return `${getScore(value)}%`;
        },
      },
      {
        Header: "Tested",
        accessor: "test_timestamp",
        Cell: ({ cell: { value } }) => {
          return formatDate(value);
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return (
            <ButtonGroup spacing="3" display="flex" justifyContent="center">
              <IconButton
                variant="ghost"
                aria-label="Move to pending"
                colorScheme="green"
                icon={<ChevronsUpIcon />}
                onClick={() => props.onMoveToPending(value)}
              />
            </ButtonGroup>
          );
        },
      },
    ];
  }, []);

  const vehiclesCount = props.data?.totalItems
    ? `- ${props.data.totalItems}`
    : "";

  return (
    <TableContainer
      title={`Dismissed Vehicles ${vehiclesCount}`}
      columns={columns}
      data={props.data}
      defaultFilterValues={props.defaultFilterValues}
      onFilter={props.onFilter}
      onNext={props.onNext}
      onBack={props.onBack}
    />
  );
};

export { DismissedVehiclesTableContainer };
