import React from "react";
import { Text, Box } from "@chakra-ui/react";

import { getMonthName } from "utils";

const VehicleRatingTooltip = ({ active, payload, label, isMonthly }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  return (
    <Box backgroundColor="white" padding="1rem">
      <Text>
        {isMonthly ? (
          <span>
            Month: <b>{getMonthName(label)}</b>
          </span>
        ) : (
          <span>
            Vehicles: <b>{payload[0].value}</b>
          </span>
        )}
      </Text>
      <Text>
        {isMonthly ? (
          <span>
            Rating: <b>{Math.floor(Math.round(Number(payload[0].value)))}</b>
          </span>
        ) : (
          <span>
            Rating: <b>{label}</b>
          </span>
        )}
      </Text>
    </Box>
  );
};

export { VehicleRatingTooltip };
