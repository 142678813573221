import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import graphImage from "assets/new_report/imageGraph.png";
import borderMain from "assets/new_report/border.png";
import shadow from "assets/new_report/shadow.png";
import { Tooltip, Text, Box } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
// import "./graph.scss";

function GaugeChartMain({
  width,
  value,
  rangeText,
  rangeNow,
  range,
  heading,
  tooltipDescription,
  range_now_city,
  range_now_highway,
}) {
  let options = {
    chart: {
      type: "radialBar",
    },
    colors: ["#52a986"],
    strokeWidth: 40,
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "80%",
          background: "#fff",
        },
        track: {
          background: "#fdca02",
          startAngle: -135,
          endAngle: 135,
        },
        startAngle: -135,
        endAngle: 135,

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: "30px",
            show: false,
            fontWeight: "700",
            top: 20,
          },
        },
      },
    },

    fill: {
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "butt",
    },
    labels: ["Progress"],
  };

  const options2 = {
    chart: {
      type: "radialBar",
    },
    colors: ["#e6e7e8"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "75%",
          background: "#fff",
        },
        track: {
          background: "#e6e7e8",
          strokeWidth: 40,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        gradientToColors: ["#ffffff"],
        stops: [0, 90],
      },
    },
    stroke: {
      lineCap: "butt",
    },
    labels: ["Progress"],
  };

  useEffect(() => {
    console.log("range_now_city: ", range_now_city);
    console.log("range_now_highway: ", range_now_highway);
  }, []);

  return (
    <div className="rangeGraph" style={{ width: width }}>
      <div style={{ display: "flex" }}>
        <h3>{heading} </h3>
        <Tooltip label={tooltipDescription}>
          <Icon
            icon={infoFill}
            style={{ color: "black", width: 16, height: 16 }}
          />
        </Tooltip>
      </div>
      <img className="borderImage" src={borderMain} alt="border" />

      <div className="gauge" style={{ width: width }}>
        <div className="chartAbsolute">
          <img
            className="graph_img"
            style={{ width: width / 2.1, height: width / 2.5 }}
            src={shadow}
          />
        </div>
        <div
          className="chartMain"
          style={{
            width: width,
          }}
        >
          <Chart
            options={options}
            series={[value]}
            type="radialBar"
            width={width}
          />
        </div>
        <div
          className="textOut"
          style={{
            top: width / 3 - 20,
            width: width / 2 - 50,
          }}
        >
          <h2>
            {rangeNow} {rangeText}
          </h2>
          <img alt="graph" src={graphImage} />
        </div>
      </div>
      {parseInt(range_now_city) && parseInt(range_now_highway) ? (
        <div
          style={{
            position: "relative",
            top: -30,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingLeft: 10,
          }}
        >
          <Text fontSize={"xl"} as="b">
            {parseInt(range_now_city)} City
          </Text>
          <Text fontSize={"xl"} as="b">
            {parseInt(range_now_highway)} Highway
          </Text>
        </div>
      ) : (
        <></>
      )}
      <div className="battery-range">
        <div className="btry-first">
          <h3 className="only-96">{value}%</h3>
        </div>
        <div className="btry-center">
          <h3>vs</h3>
        </div>
        <div className="btry-right">
          <h3>
            <span className="only-96">
              {range} {rangeText}
            </span>
            &nbsp; New
          </h3>
        </div>
      </div>
    </div>
  );
}

export default GaugeChartMain;
