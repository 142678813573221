import { TableRow, TableCell, Skeleton } from "@mui/material";
import NotDataFound from "./NoData";

export default function TableSkeleton({
  children,
  rows = 0,
  cells = 5,
  loading,
  per_page = 5,
}) {
  return (
    <>
      {loading ? (
        <>
          {[...Array(per_page)].map((item, index) => (
            <TableRow key={index}>
              {[...Array(cells)].map((item, index) => (
                <TableCell key={index}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </>
      ) : (
        <>
          {!loading && rows <= 0 ? (
            <TableRow>
              <TableCell align="center" colSpan={cells} sx={{ py: 3 }}>
                <NotDataFound />
              </TableCell>
            </TableRow>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
}
