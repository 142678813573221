import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";

import { Box, Text, Select, Button } from "@chakra-ui/react";
import {
  LoadScript,
  useLoadScript,
  GoogleMap,
  Polygon,
  Marker,
  InfoBox,
} from "@react-google-maps/api";

import "./styles.css";

// This example presents a way to handle editing a Polygon
// The objective is to get the new path on every editing event :
// - on dragging the whole Polygon
// - on moving one of the existing points (vertex)
// - on adding a new point by dragging an edge point (midway between two vertices)

// We achieve it by defining refs for the google maps API Polygon instances and listeners with `useRef`
// Then we bind those refs to the currents instances with the help of `onLoad`
// Then we get the new path value with the `onEdit` `useCallback` and pass it to `setPath`
// Finally we clean up the refs with `onUnmount`

const polygonOptions = {
  fillColor: "lightblue",
  fillOpacity: 1,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
  zIndex: 1,
};

export default function InventoryGoogleMaps({ zones, lot, inventoryList }) {
  let getCoordinates = (value) => {
    let { type, coordinates } = value ?? {};
    if (type === "Point") {
      return { lat: coordinates?.[1], lng: coordinates?.[0] };
    } else if (type === "Polygon") {
      let coords = [];
      coordinates?.[0].map((item) => {
        coords.push({ lat: item[1], lng: item[0] });
      });
      return coords;
    }
  };

  let getBoundCoordinates = (value) => {
    let { type, coordinates } = value ?? {};
    let bounds = new window.google.maps.LatLngBounds();
    let i;

    let polygonCoords = [];

    coordinates?.[0]?.map((item) => {
      polygonCoords.push(new window.google.maps.LatLng(item[1], item[0]));
    });

    for (i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }

    return { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() };
  };

  // useEffect(() => {
  //   console.log("lot ===>  ", lot);
  // }, []);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCr92YMktZNi9HoYmZuFHRsBBx-b-roB4E",
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <Box
      borderRadius="1rem"
      backgroundColor="white"
      mt={"5rem"}
      py="3rem"
      px="2rem"
      overflow="auto"
    >
      {isLoaded ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
          mt="1rem"
        >
          <div className="map-div">
            <GoogleMap
              mapContainerClassName="App-map"
              center={getCoordinates(lot?.gps)}
              zoom={17}
              mapTypeId={"satellite"}
            >
              {zones?.map((zone, index) => (
                <React.Fragment key={index}>
                  {zone?.zone_type === 1 && (
                    <>
                      <Polygon
                        path={getCoordinates(zone?.outdoor_polygon)}
                        // options={
                        //   selectedZone?.id === zone?.id ? polygonOptions : null
                        // }
                      />
                      <InfoBox
                        options={{
                          closeBoxURL: "",
                          enableEventPropagation: true,
                        }}
                        position={getBoundCoordinates(zone?.outdoor_polygon)}
                      >
                        <div
                          style={{
                            backgroundColor: "black",
                            opacity: 0.75,
                            padding: 5,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontColor: `#fff`,
                              color: "#fff",
                            }}
                          >
                            {zone.zone_name}
                          </div>
                        </div>
                      </InfoBox>
                    </>
                  )}
                </React.Fragment>
              ))}

              {[
                ...new Set(
                  inventoryList?.data
                    ?.filter((item) => item.gps == null)
                    .map((item) => item.zone_id)
                ),
              ].map((item, index) => {
                let veh = [];
                let title = "";
                inventoryList?.data?.map((itm) => {
                  if (itm.zone_id === item) {
                    title += `${itm?.vin} - ${itm?.year} ${itm?.make} ${
                      itm?.model
                    } - SOC: ${itm?.soc}% ${
                      itm?.charging_status == 1 ? "- Charging" : ""
                    }\n`;
                  }
                });

                return (
                  <React.Fragment key={index}>
                    <>
                      <Marker
                        key={index}
                        position={getCoordinates(
                          zones.find((z) => z.id === item)?.gps
                        )}
                        title={title}
                        icon={{
                          path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                          fillColor: "blue",
                          fillOpacity: 0.9,
                          scale: 2,
                          strokeColor: "white",
                          strokeWeight: 2,
                          anchor: new window.google.maps.Point(12.5, 23),
                        }}
                      />
                    </>
                  </React.Fragment>
                );
              })}

              {inventoryList?.data?.map((item, index) => (
                <React.Fragment key={index}>
                  {item?.gps && (
                    <Marker
                      key={index}
                      position={getCoordinates(item?.gps)}
                      title={`${item?.vin} - ${item?.year} ${item?.make} ${
                        item?.model
                      } - SOC: ${item?.soc ?? "0"}% ${
                        item?.charging_status == 1 ? "- Charging" : ""
                      }`}
                      icon={{
                        path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                        fillColor: "green",
                        fillOpacity: 0.9,
                        scale: 2,
                        strokeColor: "white",
                        strokeWeight: 2,
                        anchor: new window.google.maps.Point(12.5, 23),
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </GoogleMap>
          </div>
        </Box>
      ) : (
        <h6>Loading...</h6>
      )}
    </Box>
  );
}
