import { useState } from "react";
import { Button } from "@chakra-ui/react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useToast } from "@chakra-ui/toast";
import { PLANS } from "constants/SubscriptionPlans";
import { useUserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";

export default function SubscriptionForm({
  attachPaymentMethod,
  partner_code,
}) {
  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { user, init, createSubscription } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const isSubscriptionFreeClient = (partner_code) => {
    if (
      partner_code?.includes("EVU") ||
      partner_code?.includes("CASTROL") ||
      partner_code?.includes("EVCHECKUP") ||
      partner_code?.includes("LECTRIUM")
    )
      return true;
    else false;
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: user?.email,
      },
    });

    if (error || !paymentMethod) {
      console.log("[error]", error);
      toast({ title: error?.message ?? "Error Occurred", status: "error" });
      setIsLoading(false);
      return;
    }

    let data = {};
    if (partner_code?.includes("149SP")) {
      data = {
        priceId:
          PLANS?.[process?.env?.REACT_APP_ENV]?.["SP149"]?.["MONTHLY"]?.priceId,
        paymentMethod: paymentMethod?.id,
      };
    } else if (partner_code?.includes("12MONTH100")) {
      data = {
        priceId:
          PLANS?.[process?.env?.REACT_APP_ENV]?.["12MONTH100"]?.["ANNUALLY"]
            ?.priceId,
        paymentMethod: paymentMethod?.id,
      };
    } else if (partner_code?.includes("TEST1")) {
      data = {
        priceId:
          PLANS?.[process?.env?.REACT_APP_ENV]?.["TEST1"]?.["ANNUALLY"]
            ?.priceId,
        paymentMethod: paymentMethod?.id,
      };
    } else if (partner_code?.includes("TS")) {
      data = {
        priceId:
          PLANS?.[process?.env?.REACT_APP_ENV]?.["TS"]?.["MONTHLY"]?.priceId,
        paymentMethod: paymentMethod?.id,
      };
    } else {
      data = {
        priceId:
          PLANS?.[process?.env?.REACT_APP_ENV]?.["STANDARD"]?.["MONTHLY"]
            ?.priceId,
        paymentMethod: paymentMethod?.id,
      };
    }

    if (isSubscriptionFreeClient(partner_code)) {
      await attachPaymentMethod(paymentMethod?.id);
      toast({
        title: "Your payment method was successfuly added",
        status: "success",
      });
      init();
      history.push("/");
      setIsLoading(false);
      return;
    } else if (partner_code?.includes("TRIAL")) {
      data.isTrial = true;
      data.partner_code = partner_code;
    } else {
      data.isTrial = true;
    }

    let res;
    try {
      res = await createSubscription(data);
    } catch (error) {
      toast({ title: error?.message ?? "Error Occurred", status: "error" });
      setIsLoading(false);
      return;
    }

    if (partner_code?.includes("TRIAL") && !res?.client_secret) {
      toast({ title: "Subscription Succeeded", status: "success" });
      init();
      history.push("/");
    } else {
      if (res?.client_secret) {
        stripe
          .confirmCardPayment(res?.client_secret, {
            payment_method: paymentMethod?.id,
            setup_future_usage: "off_session",
          })
          .then(async (result) => {
            if (result.error) {
              toast({
                title: result?.error?.message ?? "Error Occurred",
                status: "error",
              });
            } else {
              // Successful subscription payment
              toast({ title: "Subscription Succeeded", status: "success" });
              init();
              history.push("/");
              //   await dispatch(retrieveSubscription(organization?._id));
              //   navigate(PATH_DASHBOARD.general.billing);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            toast({
              title: error?.message ?? "Error Occurred",
              status: "error",
            });
            setIsLoading(false);
          });
      } else {
        toast({ title: "Subscription Succeeded", status: "success" });
        init();
        history.push("/");
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement />
        {/* <button disabled={!stripe}>Submit</button> */}
        <Button
          type="submit"
          disabled={!stripe}
          isFullWidth
          colorScheme="red"
          borderRadius="0.4rem"
          mt="3.2rem"
          fontSize="1.4rem"
          py="1.6rem"
          isLoading={isLoading}
        >
          {isSubscriptionFreeClient(partner_code)
            ? "Attach Payment Method"
            : "Charge Subscription"}
        </Button>
        {/* Show error message to your customers */}
        {errorMessage && (
          <div style={{ textAlign: "center", color: "red" }}>
            {errorMessage}
          </div>
        )}
      </form>
    </>
  );
}
