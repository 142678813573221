import { Tooltip, useMediaQuery } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import infoFill from "@iconify/icons-eva/info-fill";
import ReactApexChart from "react-apexcharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsBellCurve from "highcharts/modules/histogram-bellcurve";
import HC_more from "highcharts/highcharts-more";

HC_more(Highcharts);
highchartsBellCurve(Highcharts);

export default function CellVoltageHistogram({
  vehicleData,
  tooltipDescription = "",
}) {
  const [isMobile] = useMediaQuery("(max-width: 450px)");
  const { brick_voltage, brick_status, vehicle_type } = vehicleData;

  if (!brick_voltage || brick_voltage?.length <= 4) return null;

  // if (!vehicle_type || !brick_voltage) {
  //   if (!brick_status && brick_voltage?.length > 4) return null;
  // }

  const new_brick_voltage = brick_voltage?.map((item) =>
    parseFloat(parseFloat(item)?.toFixed(2))
  );

  // console.log("brick_voltage: ", new_brick_voltage);

  let chartOptions = {};

  if (brick_voltage?.length) {
    chartOptions = {
      chart: {
        height: 450,
        width: isMobile ? 320 : null,
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        histogram: {
          accessibility: {
            point: {
              valueDescriptionFormat:
                "{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.",
            },
          },
        },
        // Width of the bars
        series: {
          pointWidth: 50,
        },
      },
      legend: {
        enabled: false,
        itemStyle: {
          fontSize: "1.5em",
        },
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "1.5em",
        },
      },
      xAxis: [
        {
          title: { text: "Data", enabled: false },
          alignTicks: false,
          visible: false,
        },
        {
          title: { text: "Histogram", enabled: false },
          alignTicks: false,
          // opposite: true,
        },
      ],
      yAxis: [
        {
          title: { text: "Data", enabled: false },
          visible: false,
        },
        {
          title: { text: "Histogram", enabled: false },
          // opposite: true,
        },
      ],
      series: [
        {
          name: "",
          type: "histogram",
          xAxis: 1,
          yAxis: 1,
          baseSeries: "s1",
          zIndex: -1,
          color: "#51a986",
        },
        {
          name: "",
          type: "scatter",
          data: new_brick_voltage,
          id: "s1",
          marker: {
            radius: 1.5,
          },
          visible: false,
        },
      ],
    };

    return (
      <div className="car_heatmap">
        <div className="heatmap_heading">
          <h2 style={{ display: "flex", justifyContent: "center" }}>
            CELL VOLTAGE HISTOGRAM{" "}
            <Tooltip label={tooltipDescription}>
              <Icon
                icon={infoFill}
                style={{ color: "black", width: 16, height: 16 }}
              />
            </Tooltip>
          </h2>
        </div>
        {brick_voltage && brick_voltage?.length > 4 && (
          <div className="heatmap_container">
            {/* <ReactApexChart
            options={chartOptions}
            series={[{ name: "Brick Voltage", data: brick_voltage }]}
            type="bar"
            height={550}
          /> */}
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        )}
      </div>
    );
  }
}
